import { BusinessEntityAzureAdAccessViewObject, CustomerAddressViewObject, BusinessEntityStorageViewObject, ResourceCategoryViewObject, ResourceViewObject,
    DeliveryTaskViewObject, DeliveryTaskEntityLockViewObject, CustomerLockViewObject, ScheduleEntryViewObject, InvoiceViewObject, CustomerViewObject,
    BusinessEntityMailLockViewObject, DeliveryTaskStepOrder, BusinessEntityTaskGridColumnViewObject, BusinessEntityTaskGridColumnLayoutViewObject, 
    ResourceEntityLockViewObject, ResourceCategoryEntityLockViewObject, DeliveryTaskMarkStepNotified, BusinessEntityCsvExportConfigurationViewObject, 
    InvoiceLockViewObject, ScheduleLockViewObject, BusinessEntityDeliveryCapacityThresholdViewObject, BusinessEntityAzureMailViewObject, ScheduledTaskLockViewObject, 
    ScheduledTaskViewObject, DashboardViewViewObject, DashboardViewLockViewObject, UserInformationMessageLogViewObject, DashboardViewEntryMessageViewObject, 
    DashboardViewEntryLockViewObject, DashboardViewEntryViewObject, DashboardViewEntryTaskGridDividerViewObject, GroupChatMessageViewObject, GroupChatViewObject, 
    DashboardViewEntryCheckboxCheckedEntryViewObject, 
    DeliveryDeviationTypeViewObject,
    CustomerShipmentTypeCategoryViewObject,
    CustomerShipmentTypeViewObject,
    BusinessEntityDeliveryTaskImportConfigurationViewObject,
    BusinessEntityUserRole,
    CustomerDeliveryFeeViewObject,
    BusinessEntityUserDriverLogPreStartCheckViewObject,
    NotificationViewObject,
    BusinessEntityEconomicAccessViewObject,
    ProjectViewObject,
    ProjectEntityLockViewObject} from '@/api';
import bus from '@/core/bus';
import { defaultReconnectPolicy, SignalRClient } from '@/core/signalr/signalr.client';
import { isDevMode } from '@/infrastructure/environment';
import { LogLevel } from '@microsoft/signalr';

const SIGNALR_URL = import.meta.env.VITE_API_BASE + '/hub';
const SIGNALR_LOGLEVEL = isDevMode() ? LogLevel.Information : LogLevel.Error;

export class ServerHub extends SignalRClient {
    constructor() {
        super(SIGNALR_URL, 'Web', SIGNALR_LOGLEVEL, defaultReconnectPolicy, false);

        // TASKS
        this.addEvent('TaskUpdated', (task: DeliveryTaskViewObject) => {
            bus.emit('TaskUpdated', task);
        });

        this.addEvent('TasksUpdated', (tasks: DeliveryTaskViewObject[]) => {
            bus.emit('TasksUpdated', tasks);
        });

        this.addEvent('TaskCreated', (task: DeliveryTaskViewObject) => {
            bus.emit('TaskCreated', task);
        });

        this.addEvent('TaskRemoved', (taskId: string) => {
            bus.emit('TaskRemoved', taskId);
        });

        this.addEvent('TasksRemoved', (taskIds: string[]) => {
            bus.emit('TasksRemoved', taskIds);
        });

        this.addEvent('LockTask', (lock: DeliveryTaskEntityLockViewObject) => {
            if (lock.connectionId === this.connectionId.value) return;
            bus.emit('LockTask', lock);
        });

        this.addEvent('UnlockTask', (lock: DeliveryTaskEntityLockViewObject) => {
            bus.emit('UnlockTask', lock);
        });

        this.addEvent('TasksOrderUpdated', (data: DeliveryTaskStepOrder[]) => {
            bus.emit('TasksOrderUpdated', data);
        });

        this.addEvent('MarkTaskStepAsNotified', (data: DeliveryTaskMarkStepNotified) => {
            bus.emit('MarkTaskStepsAsNotified', [data]);
        });

        this.addEvent('MarkTaskStepsAsNotified', (data: DeliveryTaskMarkStepNotified[]) => {
            bus.emit('MarkTaskStepsAsNotified', data);
        });

        // RESOURCES 
        this.addEvent('ResourceCreated', (resource: ResourceViewObject) => {
            bus.emit('ResourceCreated', resource);
        });

        this.addEvent('ResourceUpdated', (resource: ResourceViewObject) => {
            bus.emit('ResourceUpdated', resource);
        });

        this.addEvent('ResourceRemoved', (resource: ResourceViewObject) => {
            bus.emit('ResourceRemoved', resource);
        });

        this.addEvent('LockResource', (lock: ResourceEntityLockViewObject) => {
            if (lock.connectionId === this.connectionId.value) return;
            bus.emit('LockResource', lock);
        });

        this.addEvent('UnlockResource', (lock: ResourceEntityLockViewObject) => {
            bus.emit('UnlockResource', lock);
        });

        // RESOURCE CATEGORIES
        this.addEvent('ResourceCategoryCreated', (category: ResourceCategoryViewObject) => {
            bus.emit('ResourceCategoryCreated', category);
        });

        this.addEvent('ResourceCategoryUpdated', (category: ResourceCategoryViewObject) => {
            bus.emit('ResourceCategoryUpdated', category);
        });

        this.addEvent('ResourceCategoryRemoved', (category: ResourceCategoryViewObject) => {
            bus.emit('ResourceCategoryRemoved', category);
        });

        this.addEvent('LockResourceCategory', (lock: ResourceCategoryEntityLockViewObject) => {
            if (lock.connectionId === this.connectionId.value) return;
            bus.emit('LockResourceCategory', lock);
        });

        this.addEvent('UnlockResourceCategory', (lock: ResourceCategoryEntityLockViewObject) => {
            bus.emit('UnlockResourceCategory', lock);
        });

        // SCHEDULES
        this.addEvent('ScheduleUpdated', (entry: ScheduleEntryViewObject) => {
            bus.emit('ScheduleUpdated', entry);
        });

        this.addEvent('ScheduleCreated', (entry: ScheduleEntryViewObject) => {
            bus.emit('ScheduleCreated', entry);
        });

        this.addEvent('SchedulesCreated', (entries: ScheduleEntryViewObject[]) => {
            bus.emit('SchedulesCreated', entries);
        });

        this.addEvent('ScheduleRemoved', (id: string) => {
            bus.emit('ScheduleRemoved', id);
        });

        this.addEvent('SchedulesRemoved', (ids: string[]) => {
            bus.emit('SchedulesRemoved', ids);
        });

        this.addEvent('LockSchedule', (lock: ScheduleLockViewObject) => {
            if (lock.connectionId === this.connectionId.value) return;
            bus.emit('LockSchedule', lock);
        });

        this.addEvent('UnlockSchedule', (lock: ScheduleLockViewObject) => {
            bus.emit('UnlockSchedule', lock);
        });

        // INVOICES
        this.addEvent('InvoiceCreated', (entry: InvoiceViewObject) => {
            bus.emit('InvoiceCreated', entry);
        });

        this.addEvent('InvoiceUpdated', (entry: InvoiceViewObject) => {
            bus.emit('InvoiceUpdated', entry);
        });

        this.addEvent('InvoicesUpdated', (entries: InvoiceViewObject[]) => {
            bus.emit('InvoicesUpdated', entries);
        });

        this.addEvent('InvoiceRemoved', (entry: InvoiceViewObject) => {
            bus.emit('InvoiceRemoved', entry);
        });

        this.addEvent('LockInvoice', (lock: InvoiceLockViewObject) => {
            if (lock.connectionId === this.connectionId.value) return;
            bus.emit('LockInvoice', lock);
        });

        this.addEvent('UnlockInvoice', (lock: InvoiceLockViewObject) => {
            bus.emit('UnlockInvoice', lock);
        });

        // CUSTOMERS
        this.addEvent('CustomerCreated', (customer: CustomerViewObject) => {
            bus.emit('CustomerCreated', customer);
        });

        this.addEvent('CustomerUpdated', (customer: CustomerViewObject) => {
            bus.emit('CustomerUpdated', customer);
        });

        this.addEvent('CustomersUpdated', (customers: CustomerViewObject[]) => {
            bus.emit('CustomersUpdated', customers);
        });

        this.addEvent('CustomerRemoved', (customer: CustomerViewObject) => {
            bus.emit('CustomerRemoved', customer);
        });

        this.addEvent('LockCustomer', (lock: CustomerLockViewObject) => {
            if (lock.connectionId === this.connectionId.value) return;
            bus.emit('LockCustomer', lock);
        });

        this.addEvent('CustomerAddressUpdated', (address: CustomerAddressViewObject) => {
            bus.emit('CustomerAddressUpdated', address);
        });

        this.addEvent('CustomerAddressDeleted', (customerId: string, addressId: number) => {
            bus.emit('CustomerAddressDeleted', customerId, addressId);
        });

        this.addEvent('UnlockCustomer', (lock: CustomerLockViewObject) => {
            bus.emit('UnlockCustomer', lock);
        });

        this.addEvent('CustomerDeliveryFeesUpdated', (customerId: string, deliveryFees: CustomerDeliveryFeeViewObject[]) => {
            bus.emit('CustomerDeliveryFeesUpdated', customerId, deliveryFees);
        });

        // AZURE AD ACCESS
        this.addEvent('AzureAdAccessCreated', (azureAdAccess: BusinessEntityAzureAdAccessViewObject) => {
            bus.emit('AzureAdAccessCreated', azureAdAccess);
        });
        
        this.addEvent('AzureAdAccessUpdated', (azureAdAccess: BusinessEntityAzureAdAccessViewObject) => {
            bus.emit('AzureAdAccessUpdated', azureAdAccess);
        });
        
        this.addEvent('AzureAdAccessRemoved', (azureAdAccess: BusinessEntityAzureAdAccessViewObject) => {
            bus.emit('AzureAdAccessRemoved', azureAdAccess);
        });

        this.addEvent('AzureMailUpdated', (mail: BusinessEntityAzureMailViewObject) => {
            bus.emit('AzureMailUpdated', mail);
        });

        this.addEvent('AzureMailsUpdated', (mails: BusinessEntityAzureMailViewObject[]) => {
            bus.emit('AzureMailsUpdated', mails);
        });

        this.addEvent('AzureMailsRemoved', (mailIds: string[]) => {
            bus.emit('AzureMailsRemoved', mailIds);
        });

        this.addEvent('LockAzureMail', (lock: BusinessEntityMailLockViewObject) => {
            if (lock.connectionId === this.connectionId.value) return;
            bus.emit('LockAzureMail', lock);
        });

        this.addEvent('UnlockAzureMail', (lock: BusinessEntityMailLockViewObject) => {
            bus.emit('UnlockAzureMail', lock);
        });

        // STORAGES
        this.addEvent('StorageAdded', (storage: BusinessEntityStorageViewObject) => {
            bus.emit('StorageAdded', storage);
        });
        
        this.addEvent('StorageUpdated', (storage: BusinessEntityStorageViewObject) => {
            bus.emit('StorageUpdated', storage);
        });
        
        this.addEvent('StorageRemoved', (storage: BusinessEntityStorageViewObject) => {
            bus.emit('StorageRemoved', storage);
        });

        // TASK GRID COLUMNS
        this.addEvent('TaskGridColumnCreated', (column: BusinessEntityTaskGridColumnViewObject) => {
            bus.emit('TaskGridColumnCreated', column);
        });

        this.addEvent('TaskGridColumnUpdated', (column: BusinessEntityTaskGridColumnViewObject) => {
            bus.emit('TaskGridColumnUpdated', column);
        });

        this.addEvent('TaskGridColumnsUpdated', (columns: BusinessEntityTaskGridColumnViewObject[]) => {
            bus.emit('TaskGridColumnsUpdated', columns);
        });

        this.addEvent('TaskGridColumnRemoved', (column: BusinessEntityTaskGridColumnViewObject) => {
            bus.emit('TaskGridColumnRemoved', column);
        });

        this.addEvent('TaskGridColumnLayoutCreated', (column: BusinessEntityTaskGridColumnLayoutViewObject) => {
            bus.emit('TaskGridColumnLayoutCreated', column);
        });
        
        this.addEvent('TaskGridColumnLayoutUpdated', (column: BusinessEntityTaskGridColumnLayoutViewObject) => {
            bus.emit('TaskGridColumnLayoutUpdated', column);
        });
        
        this.addEvent('TaskGridColumnLayoutRemoved', (column: BusinessEntityTaskGridColumnLayoutViewObject) => {
            bus.emit('TaskGridColumnLayoutRemoved', column);
        });

        // TASK CSV CONFIGURATIONS
        this.addEvent('TaskCsvExportConfigurationUpdated', (configuration: BusinessEntityCsvExportConfigurationViewObject) => {
            bus.emit('TaskCsvExportConfigurationUpdated', configuration);
        });
        
        this.addEvent('TaskCsvExportConfigurationRemoved', (configuration: BusinessEntityCsvExportConfigurationViewObject) => {
            bus.emit('TaskCsvExportConfigurationRemoved', configuration);
        });

        // TASK IMPORT CONFIGURATIONS
        this.addEvent('DeliveryTaskImportConfigurationUpdated', (configuration: BusinessEntityDeliveryTaskImportConfigurationViewObject) => {
            bus.emit('DeliveryTaskImportConfigurationUpdated', configuration);
        });
        
        this.addEvent('DeliveryTaskImportConfigurationRemoved', (configuration: BusinessEntityDeliveryTaskImportConfigurationViewObject) => {
            bus.emit('DeliveryTaskImportConfigurationRemoved', configuration);
        });
        
        // CAPACITY THRESHOLDS
        this.addEvent('DeliveryCapacityCreated', (capacityThreshold: BusinessEntityDeliveryCapacityThresholdViewObject) => {
            bus.emit('DeliveryCapacityCreated', capacityThreshold);
        });
        
        this.addEvent('DeliveryCapacityUpdated', (capacityThreshold: BusinessEntityDeliveryCapacityThresholdViewObject) => {
            bus.emit('DeliveryCapacityUpdated', capacityThreshold);
        });
        
        this.addEvent('DeliveryCapacityRemoved', (capacityThreshold: BusinessEntityDeliveryCapacityThresholdViewObject) => {
            bus.emit('DeliveryCapacityRemoved', capacityThreshold);
        });

        // DELIVERY DEVIATION TYPES
        this.addEvent('DeliveryDeviationTypeCreated', (deviationType: DeliveryDeviationTypeViewObject) => {
            bus.emit('DeliveryDeviationTypeCreated', deviationType);
        });
        
        this.addEvent('DeliveryDeviationTypeUpdated', (deviationType: DeliveryDeviationTypeViewObject) => {
            bus.emit('DeliveryDeviationTypeUpdated', deviationType);
        });
        
        this.addEvent('DeliveryDeviationTypeRemoved', (deviationType: DeliveryDeviationTypeViewObject) => {
            bus.emit('DeliveryDeviationTypeRemoved', deviationType);
        });

        // USER INFORMATION MESSAGE LOGS
        this.addEvent('UserInformationMessageLogCreated', (entry: UserInformationMessageLogViewObject) => {
            bus.emit('UserInformationMessageLogCreated', entry);
        });
        
        this.addEvent('UserInformationMessageLogUpdated', (entry: UserInformationMessageLogViewObject) => {
            bus.emit('UserInformationMessageLogUpdated', entry);
        });
        
        this.addEvent('UserInformationMessageLogRemoved', (id: number) => {
            bus.emit('UserInformationMessageLogRemoved', id);
        });

        // SCHEDULED TASKS
        this.addEvent('ScheduledTaskUpdated', (entry: ScheduledTaskViewObject) => {
            bus.emit('ScheduledTaskUpdated', entry);
        });
        
        this.addEvent('ScheduledTaskCreated', (entry: ScheduledTaskViewObject) => {
            bus.emit('ScheduledTaskCreated', entry);
        });
        
        this.addEvent('ScheduledTaskRemoved', (id: string) => {
            bus.emit('ScheduledTaskRemoved', id);
        });
        
        this.addEvent('LockScheduledTask', (lock: ScheduledTaskLockViewObject) => {
            if (lock.connectionId === this.connectionId.value) return;
            bus.emit('LockScheduledTask', lock);
        });
        
        this.addEvent('UnlockScheduledTask', (lock: ScheduledTaskLockViewObject) => {
            bus.emit('UnlockScheduledTask', lock);
        });

        // DASHBOARD VIEW
        this.addEvent('DashboardViewUpdated', (entry: DashboardViewViewObject) => {
            bus.emit('DashboardViewUpdated', entry);
        });
                
        this.addEvent('DashboardViewCreated', (entry: DashboardViewViewObject) => {
            bus.emit('DashboardViewCreated', entry);
        });
                
        this.addEvent('DashboardViewRemoved', (id: string) => {
            bus.emit('DashboardViewRemoved', id);
        });
                
        this.addEvent('LockDashboardView', (lock: DashboardViewLockViewObject) => {
            if (lock.connectionId === this.connectionId.value) return;
            bus.emit('LockDashboardView', lock);
        });
                
        this.addEvent('UnlockDashboardView', (lock: DashboardViewLockViewObject) => {
            bus.emit('UnlockDashboardView', lock);
        });

        this.addEvent('DashboardViewEntryMessagesUpdated', (messages: DashboardViewEntryMessageViewObject[]) => {
            bus.emit('DashboardViewEntryMessagesUpdated', messages);
        });

        this.addEvent('DashboardViewEntryMessageRemoved', (id: number) => {
            bus.emit('DashboardViewEntryMessageRemoved', id);
        });

        this.addEvent('LockDashboardViewEntry', (lock: DashboardViewEntryLockViewObject) => {
            if (lock.connectionId === this.connectionId.value) return;
            bus.emit('LockDashboardViewEntry', lock);
        });
                
        this.addEvent('UnlockDashboardViewEntry', (lock: DashboardViewEntryLockViewObject) => {
            bus.emit('UnlockDashboardViewEntry', lock);
        });

        this.addEvent('DashboardViewEntryTaskGridDividerUpdated', (entry: DashboardViewEntryTaskGridDividerViewObject) => {
            bus.emit('DashboardViewEntryTaskGridDividerUpdated', entry);
        });

        this.addEvent('DashboardViewEntryTaskGridDividerRemoved', (id: number) => {
            bus.emit('DashboardViewEntryTaskGridDividerRemoved', id);
        });

        this.addEvent('ToggleDashboardViewEntryCheckbox', (entry: DashboardViewEntryCheckboxCheckedEntryViewObject) => {
            bus.emit('ToggleDashboardViewEntryCheckbox', entry);
        });

        // CHAT
        this.addEvent('ChatMessageReceived', (message: GroupChatMessageViewObject) => {
            bus.emit('ChatMessageReceived', message);
        });

        this.addEvent('GroupChatCreatedOrUpdated', (chat: GroupChatViewObject) => {
            bus.emit('GroupChatCreatedOrUpdated', chat);
        });

        this.addEvent('GroupChatRemoved', (chatId: string) => {
            bus.emit('GroupChatRemoved', chatId);
        });

        // SHIPMENT TYPES
        this.addEvent('CustomerShipmentTypeUpdated', (shipmentType: CustomerShipmentTypeViewObject) => {
            bus.emit('CustomerShipmentTypeUpdated', shipmentType);
        });

        this.addEvent('CustomerShipmentTypeCategoryUpdated', (shipmentTypeCategory: CustomerShipmentTypeCategoryViewObject) => {
            bus.emit('CustomerShipmentTypeCategoryUpdated', shipmentTypeCategory);
        });

        // ENDPOINT ACCESS
        this.addEvent('EndpointAccessUpdated', (controller: string, action: string, role: BusinessEntityUserRole) => {
            bus.emit('EndpointAccessUpdated', controller, action, role);
        });

        // PRESTART CHECKS
        this.addEvent('DriverLogPreStartCheckUpdated', (preStartCheck: BusinessEntityUserDriverLogPreStartCheckViewObject) => {
            bus.emit('DriverLogPreStartCheckUpdated', preStartCheck);
        });

        this.addEvent('DriverLogPreStartCheckCreated', (preStartCheck: BusinessEntityUserDriverLogPreStartCheckViewObject) => {
            bus.emit('DriverLogPreStartCheckCreated', preStartCheck);
        });

        this.addEvent('DriverLogPreStartChecksUpdated', (preStartChecks: BusinessEntityUserDriverLogPreStartCheckViewObject[]) => {
            bus.emit('DriverLogPreStartChecksUpdated', preStartChecks);
        });

        this.addEvent('DriverLogPreStartCheckRemoved', (preStartCheck: BusinessEntityUserDriverLogPreStartCheckViewObject) => {
            bus.emit('DriverLogPreStartCheckRemoved', preStartCheck);
        });

        // NOTIFICATIONS
        this.addEvent('NotificationCreated', (notification: NotificationViewObject) => {
            bus.emit('NotificationCreated', notification);
        });
        
        this.addEvent('NotificationUpdated', (notification: NotificationViewObject) => {
            bus.emit('NotificationUpdated', notification);
        });

        this.addEvent('NotificationsUpdated', (notifications: NotificationViewObject[]) => {
            bus.emit('NotificationsUpdated', notifications);
        });
                        
        this.addEvent('NotificationDeleted', (notificationId: string) => {
            bus.emit('NotificationDeleted', notificationId);
        });

        // E-CONOMIC
        this.addEvent('EconomicAccessCreated', (azureAdAccess: BusinessEntityEconomicAccessViewObject) => {
            bus.emit('EconomicAccessCreated', azureAdAccess);
        });
        
        this.addEvent('EconomicAccessUpdated', (azureAdAccess: BusinessEntityEconomicAccessViewObject) => {
            bus.emit('EconomicAccessUpdated', azureAdAccess);
        });
        
        this.addEvent('EconomicAccessRemoved', (accessId: string) => {
            bus.emit('EconomicAccessRemoved', accessId);
        });

        // PROJECTS
        this.addEvent('ProjectCreated', (resource: ProjectViewObject) => {
            bus.emit('ProjectCreated', resource);
        });

        this.addEvent('ProjectUpdated', (resource: ProjectViewObject) => {
            bus.emit('ProjectUpdated', resource);
        });

        this.addEvent('ProjectRemoved', (resource: ProjectViewObject) => {
            bus.emit('ProjectRemoved', resource);
        });

        this.addEvent('LockProject', (lock: ProjectEntityLockViewObject) => {
            if (lock.connectionId === this.connectionId.value) return;
            bus.emit('LockProject', lock);
        });

        this.addEvent('UnlockProject', (lock: ProjectEntityLockViewObject) => {
            bus.emit('UnlockProject', lock);
        });
    }

    public async lockTask(taskId: string, lockReason: string): Promise<void> {
        await this.connection.invoke('LockTask', taskId, lockReason);
    }

    public async unlockTask(taskId: string): Promise<void> {
        await this.connection.send('UnlockTask', taskId);
    }

    public async lockResource(resource: ResourceViewObject, lockReason: string): Promise<void> {
        await this.connection.invoke('LockResource', resource.id, lockReason);
    }

    public async unlockResource(resource: ResourceViewObject): Promise<void> {
        await this.connection.send('UnlockResource', resource.id);
    }

    public async lockResourceCategory(resource: ResourceCategoryViewObject, lockReason: string): Promise<void> {
        await this.connection.invoke('LockResourceCategory', resource.id, lockReason);
    }

    public async unlockResourceCategory(resource: ResourceCategoryViewObject): Promise<void> {
        await this.connection.send('UnlockResourceCategory', resource.id);
    }

    public async lockProject(project: ProjectViewObject, lockReason: string): Promise<void> {
        await this.connection.invoke('LockProject', project.id, lockReason);
    }

    public async unlockProject(project: ProjectViewObject): Promise<void> {
        await this.connection.send('UnlockProject', project.id);
    }

    public async lockStorage(storage: BusinessEntityStorageViewObject, lockReason: string): Promise<void> {
        await this.connection.invoke('LockStorage', storage.id, lockReason);
    }

    public async unlockStorage(storage: BusinessEntityStorageViewObject): Promise<void> {
        await this.connection.send('UnlockStorage', storage.id);
    }

    public async lockSchedule(schedule: ScheduleEntryViewObject, lockReason: string): Promise<void> {
        await this.connection.invoke('LockSchedule', schedule.id, lockReason);
    }

    public async unlockSchedule(schedule: ScheduleEntryViewObject): Promise<void> {
        await this.connection.send('UnlockSchedule', schedule.id);
    }

    public async lockScheduledTask(scheduleTaskId: string, lockReason: string): Promise<void> {
        await this.connection.invoke('LockScheduledTask', scheduleTaskId, lockReason);
    }

    public async unlockScheduledTask(scheduleTaskId: string): Promise<void> {
        await this.connection.send('UnlockScheduledTask', scheduleTaskId);
    }

    public async lockDashboardView(view: DashboardViewViewObject, lockReason: string): Promise<void> {
        await this.connection.invoke('LockDashboardView', view.id, lockReason);
    }

    public async unlockDashboardView(view: DashboardViewViewObject): Promise<void> {
        await this.connection.send('UnlockDashboardView', view.id);
    }

    public async lockDashboardViewEntry(entry: DashboardViewEntryViewObject, date: Date, lockReason: string): Promise<void> {
        await this.connection.invoke('LockDashboardViewEntry', entry.id, date.toISOString(), lockReason);
    }

    public async unlockDashboardViewEntry(entry: DashboardViewEntryViewObject, date: Date): Promise<void> {
        await this.connection.send('UnlockDashboardViewEntry', entry.id, date.toISOString());
    }

    public async lockCustomer(customerId: string, lockReason: string): Promise<void> {
        await this.connection.invoke('LockCustomer', customerId, lockReason);
    }

    public async unlockCustomer(customerId: string): Promise<void> {
        await this.connection.send('UnlockCustomer', customerId);
    }

    public async lockAzureMail(mailId: string, lockReason: string): Promise<void> {
        await this.connection.invoke('LockAzureMail', mailId, lockReason);
    }

    public async unlockAzureMail(mailId: string): Promise<void> {
        await this.connection.send('UnlockAzureMail', mailId);
    }

    public async lockInvoice(invoiceId: string, lockReason: string): Promise<void> {
        await this.connection.invoke('LockInvoice', invoiceId, lockReason);
    }

    public async unlockInvoice(invoiceId: string): Promise<void> {
        await this.connection.send('UnlockInvoice', invoiceId);
    }

    public async activityUpdate(activity: string): Promise<void> {
        await this.connection.send('ActivityUpdate', activity);
    }
    
    public async dashboardActivityUpdate(date: Date | null): Promise<void> {
        await this.connection.send('DashboardActivityUpdate', date?.toISOString());
    }

    public async getUnassignedTaskCount(): Promise<number> {
        return await this.connection.invoke<number>('GetUnassignedTaskCount');
    }

    public async getTasksInStorageCount(): Promise<number> {
        return await this.connection.invoke<number>('GetTasksInStorageCount');
    }

    public async getUnhandledMailCount(): Promise<number> {
        return await this.connection.invoke<number>('GetUnhandledMailCount');
    }

    public async toggleIsTyping(chatId: string | null | undefined, members: string[]): Promise<void> {
        await this.connection.send('ToggleIsTyping', chatId, members);
    }
}

export default new ServerHub();
