/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

type UtilRequiredKeys<T, K extends keyof T> = Omit<T, K> & Required<Pick<T, K>>;

export interface ActionLogViewObject {
  TypeDiscriminator: string;
  /** @format uuid */
  id: string;
  /** @format uuid */
  entityId: string;
  created: string;
  /** @format uuid */
  createdBy: string;
  description: string;
  logType: EntityActionLogType;
}

export type ActionLogViewObject1 = ActionLogViewObject & {
  log?: TLog | null;
};

export interface ActiveDriversOverviewViewModel {
  users: BusinessEntityDeliveryHubUserViewObject[];
}

export interface ActiveDriversOverviewViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: ActiveDriversOverviewViewModel;
}

export interface ActiveScheduledTasksRequest {
  userIds: string[];
}

export interface ActiveTaskEntry {
  task: DeliveryTaskViewObject;
  type: DeliveryStepType;
}

export interface ActiveTaskUserEntry {
  /** @format uuid */
  userId: string;
  current: ActiveTaskEntry;
  next?: ActiveTaskEntry | null;
}

export interface ActiveTaskViewModel {
  task: DeliveryTaskViewObject;
  type: DeliveryStepType;
}

export interface ActiveTaskViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: ActiveTaskViewModel;
}

export interface ActiveTasksViewModel {
  entries: ActiveTaskUserEntry[];
}

export interface ActiveTasksViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: ActiveTasksViewModel;
}

export interface AddOrUpdateAllowableIpsRequest {
  /** @format uuid */
  userId: string;
  allowableIps: string[];
}

export interface AddOrUpdateAzureMailRuleRequest {
  /** @format uuid */
  id: string;
  /**
   * @minLength 1
   * @maxLength 500
   */
  name: string;
  /** @maxLength 2000 */
  description?: string | null;
  action: BusinessEntityAzureMailRuleAction;
  /** @uniqueItems true */
  emailOrDomains: string[];
  enabled: boolean;
  runNow: boolean;
}

export interface AddOrUpdateCsvExportConfigurationRequest {
  /** @format uuid */
  id?: string | null;
  /** @minLength 1 */
  name: string;
  splitLinesByDeliveryItems: boolean;
  columns: ExportConfigurationColumn[];
  customColumns: ExportConfigurationCustomColumn[];
}

export interface AddOrUpdateCustomerDeliveryInformationTemplateRequest {
  /**
   * @minLength 1
   * @maxLength 250
   */
  title: string;
  /** @format uuid */
  id?: string | null;
  /** @format uuid */
  customerId: string;
  description?: string | null;
  shipmentPayer: ShipmentPayer;
  deliveryType: DeliveryType;
  senderAddress: AddressViewObject | ShipmentRequestDeliveryInformation;
  receiverAddress: AddressViewObject | ShipmentRequestDeliveryInformation;
  billingAddress?: AddressViewObject | ShipmentRequestDeliveryInformation | null;
  pickupTime?: TimeOfDay | null;
  deliveryTime?: TimeOfDay | null;
  deliveryItems: CustomerDeliveryItemTemplateViewObject[];
}

export interface AddOrUpdateCustomerDeliveryPriceConfigurationRequest {
  /** @format uuid */
  deliveryPriceConfigurationId?: string | null;
  /** @format uuid */
  customerId: string;
  /**
   * @minLength 1
   * @maxLength 255
   */
  title: string;
  /** @format date-time */
  activeFrom: string;
  /** @format date-time */
  activeTo?: string | null;
  /** @format double */
  discountPercentage: number;
  shipmentTypeWeightPriceRanges: CustomerShipmentTypeWeightPriceRange[];
  shipmentWeightPriceRanges: CustomerShipmentWeightPriceRange[];
  postalCodePriceRanges: CustomerPostalCodePriceRange[];
  hourPriceRanges: CustomerHourPriceRange[];
}

export interface AddOrUpdateCustomerNoteRequest {
  /** @format int32 */
  noteId?: number | null;
  /** @format uuid */
  customerId: string;
  note: string;
  /** @format date-time */
  date: string;
}

export interface AddOrUpdateDashboardViewEntryMessageRequest {
  /** @format uuid */
  dashboardViewId: string;
  /** @format uuid */
  dashboardViewEntryId: string;
  /** @format int32 */
  messageId?: number | null;
  message: string;
  /** @format int32 */
  order: number;
  /** @format date-time */
  date: string;
}

export interface AddOrUpdateDashboardViewEntryTaskGridDividerRequest {
  /** @format uuid */
  dashboardViewId: string;
  /** @format uuid */
  dashboardEntryId: string;
  /** @format int32 */
  id?: number | null;
  /** @format uuid */
  scheduleEntryId?: string | null;
  description: string;
  /** @format int32 */
  columnIndex: number;
  /** @format int32 */
  buttonSize: number;
  /** @format int32 */
  buttonRadius: number;
  /** @format int32 */
  lineHeight: number;
  alwaysShowText: boolean;
  /** @minLength 1 */
  color: string;
  /** @format date-time */
  date: string;
}

export interface AddOrUpdateDashboardViewRequest {
  /** @format uuid */
  id: string;
  /**
   * @minLength 1
   * @maxLength 500
   */
  name: string;
  /** @maxLength 1000 */
  description?: string | null;
  /** @format date-time */
  activeFrom?: string | null;
  /** @format date-time */
  activeTo?: string | null;
  /** @format int32 */
  columns: number;
  entries: DashboardViewEntry[];
}

export interface AddOrUpdateDeliveryCapacityThresholdRequest {
  /** @format int32 */
  capacityId?: number | null;
  /** @format double */
  percentage: number;
  /** @minLength 1 */
  colorCode: string;
}

export interface AddOrUpdateDeliveryDeviationTypeRequest {
  /** @format uuid */
  deliveryDeviationId?: string | null;
  /** @minLength 1 */
  name: string;
  description: DeviationFieldType;
  images: DeviationFieldType;
  amount: DeviationFieldType;
  /** @format int32 */
  taskGridColumnId?: number | null;
  enabled: boolean;
}

export interface AddOrUpdateDeliveryFeesRequest {
  /** @format uuid */
  customerId: string;
  deliveryFees: (CustomerDeliveryFee | BatchUpdateDeliveryFeeRequestEntry)[];
}

export interface AddOrUpdateDeliveryLocationRequest {
  /** @format int32 */
  id: number;
  /** @format uuid */
  customerId: string;
  street: string;
  /** @format int32 */
  postalCode: number;
  /** @minLength 1 */
  city: string;
  phone?: string | null;
  email?: string | null;
  cvr?: string | null;
  /** @minLength 1 */
  country: string;
  /** @minLength 1 */
  companyName: string;
  /** @maxLength 500 */
  description?: string | null;
  /** @maxLength 100 */
  att?: string | null;
  isCustomerAddress: boolean;
}

export interface AddOrUpdateDeliveryTaskImportConfigurationRequest {
  /** @format uuid */
  id?: string | null;
  /** @minLength 1 */
  name: string;
  configuration: TaskImportConfiguration;
}

export interface AddOrUpdateDictionaryLanguageRequest {
  /** @minLength 1 */
  languageCode: string;
  /** @minLength 1 */
  dictionaryKey: string;
  isFallback: boolean;
}

export type AddOrUpdateDriverTaskOverviewPdfBuilderRequest = AddOrUpdatePdfBuilderRequest & {
  /** @format double */
  paddingBetweenSteps: number;
};

export interface AddOrUpdateInvoiceRequest {
  /** @format uuid */
  customerId: string;
  /** @format uuid */
  invoiceId?: string | null;
  /** @format date-time */
  dueDate: string;
  /** @minLength 1 */
  currency: string;
  reference?: string | null;
  taskIds: string[];
  force: boolean;
}

export interface AddOrUpdateMailReaderConfigurationRequest {
  /** @format uuid */
  configurationId: string;
  /**
   * @minLength 1
   * @maxLength 255
   */
  configurationName: string;
  fallbackSettings: FallbackSettings;
  customerIdExtractor: CustomerIdExtractorMailReaderDataExtractor;
  descriptionExtractor: DescriptionExtractorMailReaderDataExtractor;
  senderMailExtractor: AddressExtractorMailReaderDataExtractor;
  receiverMailExtractor: AddressExtractorMailReaderDataExtractor;
  billingMailExtractor?: AddressExtractorMailReaderDataExtractor | null;
  deliveryTypeExtractor: DeliveryTypeExtractorMailReaderDataExtractor;
  shipmentPayerExtractor: ShipmentPayerExtractorMailReaderDataExtractor;
  deliveryDateExtractor?: DeliveryDateExtractorMailReaderDataExtractor | null;
  pickupDateExtractor?: PickupDateExtractorMailReaderDataExtractor | null;
  deliveryItemsExtractor: DeliveryItemsExtractorMailReaderDataExtractor;
  enabled: boolean;
}

export interface AddOrUpdateNotificationRequest {
  /** @format uuid */
  id?: string | null;
  /**
   * @minLength 1
   * @maxLength 1000
   */
  title: string;
  /** @maxLength 2000 */
  message?: string | null;
  /** @format date-time */
  activeFrom?: string | null;
  /** @format date-time */
  activeTo?: string | null;
  target: NotificationTarget;
  type: NotificationType;
  alignment: NotificationAlignment;
  position: NotificationPosition;
  fullWidth: boolean;
  enabled: boolean;
}

export interface AddOrUpdatePdfBuilderRequest {
  /** @format uuid */
  id?: string | null;
  /** @minLength 1 */
  title: string;
  /** @minLength 1 */
  description: string;
  pageSize: PdfBuilderPageSize;
  /** @format double */
  padding: number;
  /** @format date-time */
  activeFrom: string;
  /** @format date-time */
  activeTo?: string | null;
  isDefault: boolean;
  components: (
    | ShipmentPdfBusinessEntityLogoComponent
    | ShipmentPdfDateComponent
    | ShipmentPdfDeliveryDataComponent
    | ShipmentPdfDeliveryItemsComponent
    | ShipmentPdfImageComponent
    | ShipmentPdfLineComponent
    | ShipmentPdfRectangleComponent
    | ShipmentPdfShipmentIdComponent
    | ShipmentPdfStorageNameComponent
    | ShipmentPdfTextComponent
  )[];
}

export interface AddOrUpdateProjectEntryItemRequest {
  /** @format uuid */
  shipmentTypeId: string;
  type: ProjectEntryItemType;
  unit: ProjectEntryItemUnit;
  /** @format int32 */
  requiredAmount?: number | null;
}

export interface AddOrUpdateProjectEntryRequest {
  /** @format uuid */
  id: string;
  /** @minLength 1 */
  name: string;
  description: string;
  items: AddOrUpdateProjectEntryItemRequest[];
}

export interface AddOrUpdateProjectRequest {
  /** @format uuid */
  projectId?: string | null;
  /** @format uuid */
  customerId: string;
  /** @minLength 1 */
  name: string;
  description: string;
  /** @format date-time */
  startDate: string;
  status: ProjectStatus;
  /** @format date-time */
  endDate?: string | null;
  address: AddressViewObject | ShipmentRequestDeliveryInformation;
  entries?: AddOrUpdateProjectEntryRequest[] | null;
}

export interface AddOrUpdateScheduleDashboardViewEntryRequest {
  /** @format uuid */
  scheduleEntryId: string;
  dashboardViewEntryLocations: ScheduleRequestDashboardViewEntryLocation[];
}

export type AddOrUpdateScheduleEntryRequest = ScheduleRequest & {
  /** @format uuid */
  scheduleEntryId?: string | null;
};

export interface AddOrUpdateScheduleInformationRequest {
  /** @format uuid */
  scheduleEntryId: string;
  /** @maxLength 2000 */
  information?: string | null;
}

export interface AddOrUpdateScheduleInformationsRequest {
  /** @format uuid */
  scheduleEntryId: string;
  /** @maxLength 2000 */
  userInformation?: string | null;
  /** @maxLength 2000 */
  scheduleInformation?: string | null;
  /** @maxLength 2000 */
  resourceInformation?: string | null;
}

export interface AddOrUpdateScheduleResourceInformationRequest {
  /** @format uuid */
  scheduleEntryId: string;
  /** @maxLength 2000 */
  information?: string | null;
}

export interface AddOrUpdateScheduleUserInformationRequest {
  /** @format uuid */
  scheduleEntryId: string;
  /** @maxLength 2000 */
  information?: string | null;
}

export interface AddOrUpdateScheduledTaskRequest {
  /** @format uuid */
  id?: string | null;
  /** @format uuid */
  customerId: string;
  /**
   * @minLength 1
   * @maxLength 500
   */
  title: string;
  /** @maxLength 2000 */
  description?: string | null;
  /** @format date-time */
  start: string;
  /** @format date-time */
  end?: string | null;
  /** @format int32 */
  intervalMinutes: number;
  allowBookingWeekends: boolean;
  mergeTaskIntoSinglePickupDelivery: boolean;
  /** @format int32 */
  intervalCount: number;
  onCreationAction: OnCreationAction;
  /** @format uuid */
  dashboardViewId?: string | null;
  /** @format uuid */
  dashboardViewEntryId?: string | null;
  enabled: boolean;
  reschedule: boolean;
  deliveryInformation: ScheduledTaskDeliveryInformationViewObject;
}

export type AddOrUpdateShipmentPdfBuilderRequest = AddOrUpdatePdfBuilderRequest & {
  customerIds: string[];
};

export interface AddOrUpdateTaskGridColumnLayoutRequest {
  /** @format int32 */
  id?: number | null;
  /** @minLength 1 */
  title: string;
  description?: string | null;
  columnIds: number[];
}

export interface AddOrUpdateTaskGridColumnRequest {
  /** @format int32 */
  id?: number | null;
  /** @minLength 1 */
  title: string;
  description?: string | null;
  /** @minLength 1 */
  propertySelectorFunc: string;
  styleCustomizer: string;
  editor?: BusinessEntityTaskGridColumnEditor | null;
}

export interface AddOrUpdateTaskGridColumnsRequest {
  columns: AddOrUpdateTaskGridColumnRequest[];
}

export interface AddOrUpdateUserDriverLogPreStartCheckRequest {
  /** @format uuid */
  checkId?: string | null;
  /**
   * @minLength 1
   * @maxLength 500
   */
  description: string;
  enabled: boolean;
  allowImage: boolean;
  requireImage: boolean;
  resourceIds: string[];
  /** @format date-time */
  validFrom?: string | null;
  /** @format date-time */
  validTo?: string | null;
}

export interface AddOrUpdateUserInformationMessageLogRequest {
  /** @format int32 */
  logId?: number | null;
  /** @maxLength 1000 */
  message: string;
  /** @format date-time */
  date: string;
  /** @format uuid */
  userId: string;
}

export interface AddTasksToInvoiceRequest {
  tasksAndInvoiceIds: TasksAndInvoiceId[];
  force?: boolean | null;
}

export interface AddUsersToChatRequest {
  /** @format uuid */
  chatId: string;
  userIds: string[];
}

export type AddressExtractorMailReaderDataExtractor = MailReaderDataExtractor & {
  parser:
    | ContainsTextFieldContentParser
    | LineTextFieldContentParser
    | HtmlKeyValueTableFieldContentParser
    | HtmlTableContentParser;
  /** @uniqueItems true */
  companyName?: string[] | null;
  /** @uniqueItems true */
  street?: string[] | null;
  /** @uniqueItems true */
  city?: string[] | null;
  /** @uniqueItems true */
  phone?: string[] | null;
  /** @uniqueItems true */
  email?: string[] | null;
  /** @uniqueItems true */
  cvr?: string[] | null;
  /** @uniqueItems true */
  att?: string[] | null;
  /** @uniqueItems true */
  postalCode?: string[] | null;
  /** @uniqueItems true */
  description?: string[] | null;
  /** @uniqueItems true */
  country: string[];
};

export type AddressFieldWithError = AddressViewObjectFieldWithError;

export interface AddressLog {
  changes?: PropertyChangeEvent[] | null;
}

export interface AddressViewObject {
  companyName: string;
  /** @minLength 1 */
  street: string;
  /** @format int32 */
  postalCode: number;
  /** @minLength 1 */
  city: string;
  /** @minLength 1 */
  country: string;
  phone?: string | null;
  /** @maxLength 500 */
  description?: string | null;
  /** @maxLength 100 */
  att?: string | null;
  email?: string | null;
  cvr?: string | null;
}

export interface AddressViewObjectFieldWithError {
  data?: AddressViewObject | ShipmentRequestDeliveryInformation | null;
  errors?: string[] | null;
}

export type AggregatedFeeConfigurationEntry = UtilRequiredKeys<ExportConfigurationEntry, "TypeDiscriminator"> & {
  feeName: string;
  format: boolean;
  TypeDiscriminator: string;
};

export interface AssignDeliveryStepRequest {
  /** @format uuid */
  taskId: string;
  /** @format uuid */
  scheduleId?: string | null;
  /** @format uuid */
  storageId?: string | null;
  storageLocation?: StorageLocation | null;
  /** @format uuid */
  dashboardViewId?: string | null;
  /** @format uuid */
  dashboardEntryId?: string | null;
  /** @format date-time */
  date?: string | null;
  /** @format int32 */
  order: number;
  description?: string | null;
  /** @format uuid */
  projectId?: string | null;
  /** @format uuid */
  projectEntryId?: string | null;
}

export interface AssignDeliveryTaskRequest {
  /** @format uuid */
  taskId: string;
  pickup: AssignDeliveryStepRequest;
  delivery: AssignDeliveryStepRequest;
}

export interface AzureAdMailIdsRequest {
  mailIds: string[];
}

export interface BatchUpdateDeliveryFeeRequest {
  deliveryFees: BatchUpdateDeliveryFeeRequestEntry[];
}

export type BatchUpdateDeliveryFeeRequestEntry = CustomerDeliveryFee & {
  /** @format uuid */
  customerId: string;
};

export interface BooleanArrayClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: boolean[];
}

export interface BooleanClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: boolean;
}

export interface BusinessEntityActionLogViewModel {
  logs: BusinessEntityActionLogViewObject[];
  /** @format int32 */
  count: number;
}

export interface BusinessEntityActionLogViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: BusinessEntityActionLogViewModel;
}

export type BusinessEntityActionLogViewObject = BusinessEntityLogActionLogViewObject;

export interface BusinessEntityAzureAdAccessViewModel {
  azureAdAccess: BusinessEntityAzureAdAccessViewObject[];
}

export interface BusinessEntityAzureAdAccessViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: BusinessEntityAzureAdAccessViewModel;
}

export interface BusinessEntityAzureAdAccessViewObject {
  /** @format uuid */
  businessEntityId: string;
  azureAdUserId: string;
  azureAdUserName: string;
  created: string;
  lastUsage: string;
  lastMailSync?: string | null;
  expireAt: string;
  isExpired: boolean;
  enabled: boolean;
  mailboxNotAvailable: boolean;
  azureError?: string | null;
}

export interface BusinessEntityAzureAdAccessViewObjectClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: BusinessEntityAzureAdAccessViewObject;
}

export enum BusinessEntityAzureMailRuleAction {
  Delete = "Delete",
}

export interface BusinessEntityAzureMailRuleViewModel {
  rules: BusinessEntityAzureMailRuleViewObject[];
}

export interface BusinessEntityAzureMailRuleViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: BusinessEntityAzureMailRuleViewModel;
}

export interface BusinessEntityAzureMailRuleViewObject {
  /** @format uuid */
  id: string;
  created: string;
  /** @format uuid */
  createdBy?: string | null;
  modified: string;
  /** @format uuid */
  modifiedBy?: string | null;
  name: string;
  description?: string | null;
  emailOrDomains: string[];
  action: BusinessEntityAzureMailRuleAction;
  enabled: boolean;
  /** @format int32 */
  mailsHandled: number;
}

export interface BusinessEntityAzureMailRuleViewObjectClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: BusinessEntityAzureMailRuleViewObject;
}

export interface BusinessEntityAzureMailViewModel {
  mails: BusinessEntityAzureMailViewObject[];
  /** @format int32 */
  total: number;
}

export interface BusinessEntityAzureMailViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: BusinessEntityAzureMailViewModel;
}

export interface BusinessEntityAzureMailViewObject {
  id: string;
  receivedDate: string;
  subject: string;
  contentHtml: string;
  fromName: string;
  fromEmail: string;
  /** @format uuid */
  batchId: string;
  createdDate: string;
  failedToParse: boolean;
  failedToParseReason?: string | null;
  handleDate?: string | null;
  /** @format uuid */
  handledByUserId?: string | null;
  tasksCreatedFromThis: string[];
  parsedDeliveryTaskDataFromMail?: ParsedDeliveryTaskDataFromMailViewObject | null;
  lock?: BusinessEntityMailLockViewObject | null;

  marked: boolean;
}

export interface BusinessEntityAzureMailViewObjectClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: BusinessEntityAzureMailViewObject;
}

export interface BusinessEntityAzureMailsViewModel {
  mails: BusinessEntityAzureMailViewObject[];
}

export interface BusinessEntityAzureMailsViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: BusinessEntityAzureMailsViewModel;
}

export interface BusinessEntityCsvExportConfigurationViewObject {
  /** @format uuid */
  id: string;
  name: string;
  splitLinesByDeliveryItems: boolean;
  columns: ExportConfigurationColumn[];
  customColumns: ExportConfigurationCustomColumn[];
  created: string;
  modified?: string | null;
  /** @format uuid */
  modifiedByUserId?: string | null;
}

export interface BusinessEntityCsvExportConfigurationViewObjectClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: BusinessEntityCsvExportConfigurationViewObject;
}

export interface BusinessEntityCsvExportConfigurationsViewModel {
  configurations: BusinessEntityCsvExportConfigurationViewObject[];
}

export interface BusinessEntityCsvExportConfigurationsViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: BusinessEntityCsvExportConfigurationsViewModel;
}

export interface BusinessEntityCustomerDeliveryFee {
  /** @format uuid */
  id?: string | null;
  /** @minLength 1 */
  name: string;
  /** @minLength 1 */
  description: string;
  validations: (
    | CustomerAddressEquals
    | DeliveryDateTimeOfDay
    | DeliveryItemsCount
    | DeliveryItemsTypeAndCount
    | DeliveryTypeEquals
    | DeliveryValidationAndCondition
    | DeliveryValidationDeliveryStatus
    | DeliveryValidationDeliveryStepStatus
    | DeliveryValidationDeliveryStepType
    | DeliveryValidationHasDeliveryDate
    | DeliveryValidationHasDescription
    | DeliveryValidationHasPickupDate
    | DeliveryValidationOrCondition
    | DeliveryValidationStepHasDescription
    | DeliveryValidationStorageLocation
    | ShipmentPayerEquals
  )[];
  value: FeePercentageValue | PostponeDeliveryDateDeliveryFeeValue | ValueDeliveryFeeValue;
  /** @format int32 */
  order: number;
  active: boolean;
  /** @format date-time */
  activeFrom?: string | null;
  /** @format date-time */
  activeTo?: string | null;
}

export type BusinessEntityCustomerDeliveryFeeViewObject = DeliveryFeeViewObject;

export interface BusinessEntityDeliveryCapacityThresholdViewModel {
  capacityThresholds: BusinessEntityDeliveryCapacityThresholdViewObject[];
}

export interface BusinessEntityDeliveryCapacityThresholdViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: BusinessEntityDeliveryCapacityThresholdViewModel;
}

export interface BusinessEntityDeliveryCapacityThresholdViewObject {
  /** @format int32 */
  id: number;
  /** @format double */
  percentage: number;
  colorCode: string;
}

export interface BusinessEntityDeliveryCapacityThresholdViewObjectClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: BusinessEntityDeliveryCapacityThresholdViewObject;
}

export type BusinessEntityDeliveryHubConnectionViewObject = HubConnectionViewObject;

export interface BusinessEntityDeliveryHubUserBusinessEntityDeliveryHubConnectionHubUserViewObject {
  /** @format uuid */
  userId: string;
  location?: GeoLocation | null;
  locationTrackingIsAvailable: boolean;
  lastLocationPing?: string | null;
  activity?: string | null;
  lastActivity: string;
  lastChannel?: string | null;
  lastAppVersionId?: string | null;
}

export type BusinessEntityDeliveryHubUserViewObject =
  BusinessEntityDeliveryHubUserBusinessEntityDeliveryHubConnectionHubUserViewObject & {
    connections: BusinessEntityDeliveryHubConnectionViewObject[];
    /** @format uuid */
    driverLogId?: string | null;
  };

export interface BusinessEntityDeliveryHubUserViewObjectNullableClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model?: BusinessEntityDeliveryHubUserViewObject | null;
}

export type BusinessEntityDeliveryTaskImportConfigurationViewObject = EntityViewObject & {
  name: string;
  configuration: TaskImportConfiguration;
};

export interface BusinessEntityDeliveryTaskImportConfigurationViewObjectClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: BusinessEntityDeliveryTaskImportConfigurationViewObject;
}

export interface BusinessEntityDeliveryTaskImportConfigurationsViewModel {
  configurations: BusinessEntityDeliveryTaskImportConfigurationViewObject[];
}

export interface BusinessEntityDeliveryTaskImportConfigurationsViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: BusinessEntityDeliveryTaskImportConfigurationsViewModel;
}

export interface BusinessEntityDriverLogsViewModel {
  /** @format int32 */
  total: number;
  logs: BusinessEntityUserDriverLogViewObject[];
}

export interface BusinessEntityDriverLogsViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: BusinessEntityDriverLogsViewModel;
}

export interface BusinessEntityEconomicAccessDetailsViewObject {
  companyName: string;
  phoneNumber?: string | null;
  att?: string | null;
  email?: string | null;
  signupDate: string;
}

export interface BusinessEntityEconomicAccessDetailsViewObjectClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: BusinessEntityEconomicAccessDetailsViewObject;
}

export interface BusinessEntityEconomicAccessViewModel {
  accesses: BusinessEntityEconomicAccessViewObject[];
}

export interface BusinessEntityEconomicAccessViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: BusinessEntityEconomicAccessViewModel;
}

export interface BusinessEntityEconomicAccessViewObject {
  /** @format uuid */
  id: string;
  /** @format uuid */
  businessEntityId: string;
  created: string;
  lastUsage: string;
  enabled: boolean;
}

export interface BusinessEntityEconomicAccessViewObjectClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: BusinessEntityEconomicAccessViewObject;
}

export type BusinessEntityHubConnectionViewObject = HubConnectionViewObject & {
  viewingDashboardForDate?: string | null;
};

export interface BusinessEntityLog {
  changes?: PropertyChangeEvent[] | null;
  addressLog?: AddressLog | null;
  openingHoursLog?: BusinessEntityOpeningHoursLog | null;
  settingsLog?: BusinessEntitySettingsLog | null;
}

export interface BusinessEntityLogActionLogViewObject {
  TypeDiscriminator: string;
  /** @format uuid */
  id: string;
  /** @format uuid */
  entityId: string;
  created: string;
  /** @format uuid */
  createdBy: string;
  description: string;
  logType: EntityActionLogType;
  log?: BusinessEntityLog | null;
}

export type BusinessEntityMailLockViewObject = StringEntityLockViewObject;

export interface BusinessEntityMailReaderConfigurationViewModel {
  configurations: BusinessEntityMailReaderConfigurationViewObject[];
}

export interface BusinessEntityMailReaderConfigurationViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: BusinessEntityMailReaderConfigurationViewModel;
}

export interface BusinessEntityMailReaderConfigurationViewObject {
  /** @format uuid */
  configurationId: string;
  configurationName: string;
  fallback: FallbackSettings;
  customerIdExtractor: CustomerIdExtractorMailReaderDataExtractor;
  descriptionExtractor: DescriptionExtractorMailReaderDataExtractor;
  senderAddressExtractor: AddressExtractorMailReaderDataExtractor;
  receiverAddressExtractor: AddressExtractorMailReaderDataExtractor;
  billingAddressExtractor?: AddressExtractorMailReaderDataExtractor | null;
  deliveryTypeExtractor: DeliveryTypeExtractorMailReaderDataExtractor;
  shipmentPayerExtractor: ShipmentPayerExtractorMailReaderDataExtractor;
  deliveryDateExtractor?:
    | DeliveryDateExtractorMailReaderDataExtractor
    | PickupDateExtractorMailReaderDataExtractor
    | null;
  pickupDateExtractor?:
    | DeliveryDateExtractorMailReaderDataExtractor
    | PickupDateExtractorMailReaderDataExtractor
    | null;
  deliveryItemsExtractor: DeliveryItemsExtractorMailReaderDataExtractor;
  enabled: boolean;
  created: string;
  /** @format uuid */
  createdBy?: string | null;
  modified?: string | null;
  /** @format uuid */
  modifiedBy?: string | null;
}

export interface BusinessEntityMailReaderConfigurationViewObjectClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: BusinessEntityMailReaderConfigurationViewObject;
}

export interface BusinessEntityOpeningHoursLog {
  changes?: PropertyChangeEvent[] | null;
}

export interface BusinessEntityResourceDriverLogsViewModel {
  /** @format int32 */
  total: number;
  logs: BusinessEntityUserDriverLogViewObject[];
}

export interface BusinessEntityResourceDriverLogsViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: BusinessEntityResourceDriverLogsViewModel;
}

export interface BusinessEntitySettingLog {
  changes?: PropertyChangeEvent[] | null;
  key: string;
}

export interface BusinessEntitySettingsLog {
  changes?: PropertyChangeEvent[] | null;
  settingLogs?: BusinessEntitySettingLog[] | null;
}

export interface BusinessEntitySettingsViewObject {
  dashboardColorSettings: DashboardColorSettings;
  shippingColorSettings: ShippingColorSettings;
  deliverySettings: DeliverySettings;
  shippingSettings: ShippingSettings;
  rentalSettings: RentalSettings;
  businessSettings: BusinessSettings;
  /** @format int32 */
  datePickerMinuteInterval: number;
  /** @format int32 */
  shipmentPdfPageCount: number;
}

export interface BusinessEntityShipmentPdfBuilderViewModel {
  builder: BusinessEntityShipmentPdfBuilderViewObject;
}

export interface BusinessEntityShipmentPdfBuilderViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: BusinessEntityShipmentPdfBuilderViewModel;
}

export type BusinessEntityShipmentPdfBuilderViewObject = PdfBuilderViewObject & {
  customerIds: string[];
};

export interface BusinessEntityShipmentPdfBuildersViewModel {
  builders: BusinessEntityShipmentPdfBuilderViewObject[];
}

export interface BusinessEntityShipmentPdfBuildersViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: BusinessEntityShipmentPdfBuildersViewModel;
}

export interface BusinessEntitySiteDefinitionViewObject {
  domain: string;
}

export type BusinessEntityStorageLockViewObject = GuidEntityLockViewObject;

export interface BusinessEntityStorageViewModel {
  storage: BusinessEntityStorageViewObject;
}

export interface BusinessEntityStorageViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: BusinessEntityStorageViewModel;
}

export type BusinessEntityStorageViewObject = EntityViewObject & {
  /** @format uuid */
  businessEntityId: string;
  name: string;
  description?: string | null;
  isDefault: boolean;
  address: AddressViewObject | ShipmentRequestDeliveryInformation;
  lock?: BusinessEntityStorageLockViewObject | null;
} & {
  marked: boolean;
  dragging: boolean;
  sort: number;
};

export interface BusinessEntityStoragesViewModel {
  storages: BusinessEntityStorageViewObject[];
}

export interface BusinessEntityStoragesViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: BusinessEntityStoragesViewModel;
}

export enum BusinessEntityTaskGridColumnEditor {
  TaskTitle = "Task_Title",
  TaskDescription = "Task_Description",
  TaskLoadType = "Task_LoadType",
  TaskInformation = "Task_Information",
}

export interface BusinessEntityTaskGridColumnLayoutViewModel {
  layout: BusinessEntityTaskGridColumnLayoutViewObject;
}

export interface BusinessEntityTaskGridColumnLayoutViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: BusinessEntityTaskGridColumnLayoutViewModel;
}

export interface BusinessEntityTaskGridColumnLayoutViewObject {
  /** @format int32 */
  id: number;
  title: string;
  description?: string | null;
  columnIds: number[];
}

export interface BusinessEntityTaskGridColumnLayoutsViewModel {
  layouts: BusinessEntityTaskGridColumnLayoutViewObject[];
}

export interface BusinessEntityTaskGridColumnLayoutsViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: BusinessEntityTaskGridColumnLayoutsViewModel;
}

export interface BusinessEntityTaskGridColumnViewModel {
  column: BusinessEntityTaskGridColumnViewObject;
}

export interface BusinessEntityTaskGridColumnViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: BusinessEntityTaskGridColumnViewModel;
}

export interface BusinessEntityTaskGridColumnViewObject {
  /** @format int32 */
  id: number;
  /** @format uuid */
  businessEntityId: string;
  title: string;
  description?: string | null;
  propertySelectorFunc: string;
  styleCustomizer: string;
  editor?: BusinessEntityTaskGridColumnEditor | null;
  created: string;
  /** @format uuid */
  createdBy: string;
  modified?: string | null;
  /** @format uuid */
  modifiedBy?: string | null;
}

export interface BusinessEntityTaskGridColumnsViewModel {
  columns: BusinessEntityTaskGridColumnViewObject[];
}

export interface BusinessEntityTaskGridColumnsViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: BusinessEntityTaskGridColumnsViewModel;
}

export interface BusinessEntityUserActionLogViewModel {
  logs: BusinessEntityUserActionLogViewObject[];
  /** @format int32 */
  count: number;
}

export interface BusinessEntityUserActionLogViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: BusinessEntityUserActionLogViewModel;
}

export type BusinessEntityUserActionLogViewObject = UserLogActionLogViewObject;

export interface BusinessEntityUserDriverLogBreakViewObject {
  /** @format int32 */
  id: number;
  duration: DurationViewObject;
  startLocation?: GeoLocation | null;
  endLocation?: GeoLocation | null;
  description?: string | null;
  inProgress: boolean;
  finished: boolean;
}

export interface BusinessEntityUserDriverLogPreStartCheckEntryViewObject {
  /** @format uuid */
  id: string;
  /** @format uuid */
  userId: string;
  /** @format uuid */
  businessEntityId: string;
  /** @format uuid */
  driverLogId: string;
  /** @format uuid */
  preStartCheckId?: string | null;
  description: string;
  imageUrl?: string | null;
}

export interface BusinessEntityUserDriverLogPreStartCheckViewModel {
  preStartChecks: BusinessEntityUserDriverLogPreStartCheckViewObject[];
}

export interface BusinessEntityUserDriverLogPreStartCheckViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: BusinessEntityUserDriverLogPreStartCheckViewModel;
}

export type BusinessEntityUserDriverLogPreStartCheckViewObject = EntityViewObject & {
  description: string;
  /** @format int32 */
  order: number;
  enabled: boolean;
  allowImage: boolean;
  requireImage: boolean;
  resourceIds: string[];
  validFrom?: string | null;
  validTo?: string | null;
};

export interface BusinessEntityUserDriverLogPreStartCheckViewObjectClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: BusinessEntityUserDriverLogPreStartCheckViewObject;
}

export interface BusinessEntityUserDriverLogTrackingStatisticsViewObject {
  /** @format double */
  totalKM: number;
  /** @format double */
  totalMinutes: number;
}

export interface BusinessEntityUserDriverLogViewObject {
  /** @format uuid */
  id: string;
  /** @format uuid */
  userId: string;
  /** @format uuid */
  scheduleEntryId: string;
  /** @format uuid */
  resourceId: string;
  duration: DurationViewObject;
  trackingStatistics: BusinessEntityUserDriverLogTrackingStatisticsViewObject;
  startLocation?: GeoLocation | null;
  endLocation?: GeoLocation | null;
  currentLocation?: GeoLocation | null;
  inProgress: boolean;
  isOnBreak: boolean;
  finished: boolean;
  breaks: BusinessEntityUserDriverLogBreakViewObject[];
  preStartCheckEntries: BusinessEntityUserDriverLogPreStartCheckEntryViewObject[];
}

export interface BusinessEntityUserDriverLogViewObjectNullableClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model?: BusinessEntityUserDriverLogViewObject | null;
}

export interface BusinessEntityUserDriverLogsViewModel {
  /** @format int32 */
  total: number;
  logs: BusinessEntityUserDriverLogViewObject[];
}

export interface BusinessEntityUserDriverLogsViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: BusinessEntityUserDriverLogsViewModel;
}

export interface BusinessEntityUserLocationTrackingLogViewModel {
  logs: BusinessEntityUserLocationTrackingLogViewObject[];
}

export interface BusinessEntityUserLocationTrackingLogViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: BusinessEntityUserLocationTrackingLogViewModel;
}

export interface BusinessEntityUserLocationTrackingLogViewObject {
  /** @format uuid */
  userId: string;
  /** @format uuid */
  driverLogId?: string | null;
  timestamp: string;
  /** @format double */
  latitude: number;
  /** @format double */
  longitude: number;
}

export interface BusinessEntityUserLoginHistoryViewModel {
  loginHistories: BusinessEntityUserLoginHistoryViewObject[];
}

export interface BusinessEntityUserLoginHistoryViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: BusinessEntityUserLoginHistoryViewModel;
}

export type BusinessEntityUserLoginHistoryViewObject = UserLoginHistoryViewObject & {
  /** @format uuid */
  businessEntityId: string;
};

export enum BusinessEntityUserRole {
  None = "None",
  Carrier = "Carrier",
  Moderator = "Moderator",
  Administrator = "Administrator",
}

export interface BusinessEntityUserViewModel {
  user: EmployeeViewObject | UserViewObject;
}

export interface BusinessEntityUserViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: BusinessEntityUserViewModel;
}

export interface BusinessEntityUsersViewModel {
  users: (EmployeeViewObject | UserViewObject)[];
}

export interface BusinessEntityUsersViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: BusinessEntityUsersViewModel;
}

export interface BusinessEntityViewModel {
  businessEntity: BusinessEntityViewObject;
}

export interface BusinessEntityViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: BusinessEntityViewModel;
}

export type BusinessEntityViewObject = EntityViewObject & {
  name: string;
  description?: string | null;
  email: string;
  phone?: string | null;
  logoUrl: string;
  dashboardLogoOverride: boolean;
  dashboardRouteAreaAccess: DashboardRouteAreaAccess;
  address: AddressViewObject | ShipmentRequestDeliveryInformation;
  openingHours: OpeningHoursViewObject;
  settings: BusinessEntitySettingsViewObject;
  siteDefinition: BusinessEntitySiteDefinitionViewObject;
  customerDeliveryFees: BusinessEntityCustomerDeliveryFeeViewObject[];
};

export interface BusinessServerContextViewObject {
  apiBase: string;
  businessEntity: BusinessEntityViewObject;
}

export interface BusinessServerContextViewObjectClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: BusinessServerContextViewObject;
}

export interface BusinessSettings {
  loadTypes: string[];
  /** @minLength 1 */
  smsAccessToken: string;
  /** @format int32 */
  scheduleDaysLookAhead: number;
  scheduleDaysLookAheadSkipWeekends: boolean;
  scheduleDaysLookAheadShowDriverName: boolean;
  scheduleDaysLookAheadShowResourceName: boolean;
  defaultStorageLocation: StorageLocation;
  allowOverridingScheduleTime: boolean;
  showAwaitingStorageInStorage: boolean;
  playSoundWhenChatMessageReceived: boolean;
  /** @format int32 */
  trackingLogsExpirationDays: number;
  /** @format int32 */
  minutesSinceLastGpsUpdateWarning: number;
  canUseRoutePlanner: boolean;
  enableDeliveryTemplates: boolean;
  enableIndividualDirectVisibility: boolean;
}

export interface ChangePasswordRequest {
  /** @minLength 1 */
  currentPassword: string;
  /** @minLength 1 */
  newPassword: string;
  /** @minLength 1 */
  newPasswordConfirm: string;
}

export interface ContactViewObject {
  /** @format int32 */
  id: number;
  name: string;
  lastName: string;
  email: string;
  phone: string;
}

export type ContainsTextFieldContentParser = UtilRequiredKeys<MailReaderContentParser, "TypeDiscriminator"> & {
  TypeDiscriminator: string;
};

export enum ContentParserStrategy {
  CurrentLine = "CurrentLine",
  NextLine = "NextLine",
}

export interface CopyCustomerDeliveryPriceConfigurationRequest {
  /** @format uuid */
  customerId: string;
  /** @format uuid */
  copyFromCustomerId: string;
  /** @format uuid */
  configurationId?: string | null;
  /** @format uuid */
  copyFromConfigurationId: string;
}

export interface CreateChatRequest {
  members: string[];
  /** @maxLength 255 */
  title?: string | null;
}

export interface CreateCustomerRequest {
  address: AddressViewObject | ShipmentRequestDeliveryInformation;
  contacts: CustomerContact[];
  allowedShipmentPayerTypes: ShipmentPayer[];
  sendConfirmationEmails: boolean;
  allowFastDelivery: boolean;
  showDeliveryPrices: boolean;
  trackTruckKM: boolean;
  requirePickupImageVerification: boolean;
  requireDeliveryImageVerification: boolean;
  canUseDeliveryInformationTemplates: boolean;
  /** @minLength 1 */
  paymentCurrency: string;
  /** @format int32 */
  hoursSinceCreationTaskCanBeEdited?: number | null;
  /** @format int32 */
  hoursSinceCreationTaskCanBeDeleted?: number | null;
  aliases: string[];
  logoUrl: string;
  /** @format uuid */
  useDataFromCustomerId?: string | null;
}

export interface CreateCustomerUserRequest {
  /** @format uuid */
  customerId: string;
  /** @minLength 1 */
  name: string;
  /** @minLength 1 */
  lastName: string;
  /** @minLength 1 */
  email: string;
  phone?: string | null;
  whatsAppNumber?: string | null;
  customerUserRole: CustomerUserRole;
  profileImageUrl?: string | null;
  sendWelcomeEmail: boolean;
}

export interface CreateResourceCategoryRequest {
  /** @minLength 1 */
  name: string;
  description?: string | null;
  image?: string | null;
  /** @format double */
  maxLoadKg: number;
  /** @format double */
  heightCM: number;
  /** @format double */
  widthCM: number;
  /** @format double */
  lengthCM: number;
  /** @format double */
  loadMeter: number;
  /** @format double */
  maximumSpeedKM: number;
  /** @format double */
  co2EmissionsPerKM: number;
  /** @format int32 */
  maxEuroPallets: number;
}

export interface CreateResourceRequest {
  /** @minLength 1 */
  title: string;
  description?: string | null;
  /** @format int32 */
  identifier: number;
  /** @format double */
  maxLoadKg?: number | null;
  /** @format double */
  loadMeter?: number | null;
  /** @format int32 */
  maxEuroPallets?: number | null;
  /** @format double */
  maximumSpeedKM?: number | null;
  /** @format double */
  co2EmissionsPerKM?: number | null;
  /** @format uuid */
  categoryId: string;
  startAddress?: AddressViewObject | ShipmentRequestDeliveryInformation | null;
}

export type CreateScheduleEntryIntervalRequest = ScheduleRequest & {
  /** @format int32 */
  numberOfDays: number;
};

export interface CreateStorageRequest {
  /** @minLength 1 */
  name: string;
  description?: string | null;
  isDefault: boolean;
  address: AddressViewObject | ShipmentRequestDeliveryInformation;
}

export interface CreateTaskRequest {
  shipmentRequest: ShipmentRequest;
}

export interface CreateTasksRequest {
  shipmentRequests: ShipmentRequest[];
  taskAssignment?: TaskStepAssignment | null;
  markPickupAsVisibleForDriver: boolean;
  markDeliveryAsVisibleForDriver: boolean;
  markPickupAsCompleted: boolean;
}

export interface CreateUserRequest {
  /** @minLength 1 */
  name: string;
  /** @minLength 1 */
  lastName: string;
  /** @minLength 1 */
  email: string;
  phone?: string | null;
  whatsAppNumber?: string | null;
  role: BusinessEntityUserRole;
  profileImageUrl?: string | null;
  /** @format int32 */
  identifier: number;
  hideInLiveView: boolean;
  isExternalDriver: boolean;
  homeAddress?: AddressViewObject | ShipmentRequestDeliveryInformation | null;
  whatsAppInstanceId?: string | null;
  whatsAppAccessToken?: string | null;
  sendWelcomeEmail: boolean;
}

export interface CustomerActionLogViewModel {
  logs: CustomerActionLogViewObject[];
  /** @format int32 */
  count: number;
}

export interface CustomerActionLogViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: CustomerActionLogViewModel;
}

export type CustomerActionLogViewObject = CustomerLogActionLogViewObject;

export type CustomerAddressEquals = UtilRequiredKeys<DeliveryValidation, "TypeDiscriminator"> & {
  scope: CustomerAddressEqualsEvaluationScope;
  TypeDiscriminator: string;
};

export enum CustomerAddressEqualsEvaluationScope {
  None = "None",
  Sender = "Sender",
  Receiver = "Receiver",
  Billing = "Billing",
}

export interface CustomerAddressViewObject {
  /** @format int32 */
  id: number;
  address: AddressViewObject | ShipmentRequestDeliveryInformation;
  created: string;
  modified?: string | null;
  /** @format uuid */
  modifiedBy?: string | null;
  isCustomerAddress: boolean;
}

export interface CustomerAddressViewObjectClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: CustomerAddressViewObject;
}

export interface CustomerAddressesViewModel {
  addresses: CustomerAddressViewObject[];
}

export interface CustomerAddressesViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: CustomerAddressesViewModel;
}

export interface CustomerCo2AggregatedStatisticsTaskViewObject {
  /** @format uuid */
  id: string;
  date: string;
  senderAddress: AddressViewObject | ShipmentRequestDeliveryInformation;
  receiverAddress: AddressViewObject | ShipmentRequestDeliveryInformation;
  /** @format double */
  distanceInKm?: number | null;
  /** @format double */
  co2Emissions?: number | null;
}

export interface CustomerCo2AggregatedStatisticsViewModel {
  entries: CustomerCo2AggregatedStatisticsViewObject[];
}

export interface CustomerCo2AggregatedStatisticsViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: CustomerCo2AggregatedStatisticsViewModel;
}

export interface CustomerCo2AggregatedStatisticsViewObject {
  /** @format uuid */
  customerId: string;
  /** @format int32 */
  numberOfTasks: number;
  /** @format double */
  totalKm: number;
  uniqueResourcesUsed: string[];
  /** @format double */
  averageCo2PerTask: number;
  /** @format double */
  totalCo2: number;
  tasks: CustomerCo2AggregatedStatisticsTaskViewObject[];
}

export interface CustomerCo2DateEntryViewObject {
  description: string;
  /** @format double */
  co2Emissions: number;
  date: string;
  entries: CustomerCo2DateEntryViewObject[];
}

export interface CustomerCo2DateSeriesEntryViewModel {
  entries: CustomerCo2DateSeriesEntryViewObject[];
}

export interface CustomerCo2DateSeriesEntryViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: CustomerCo2DateSeriesEntryViewModel;
}

export interface CustomerCo2DateSeriesEntryViewObject {
  description: string;
  entries: CustomerCo2DateEntryViewObject[];
}

export interface CustomerCo2StatisticsEntryViewObject {
  customerName: string;
  /** @format double */
  co2Emissions: number;
}

export interface CustomerCo2StatisticsRequest {
  /** @format date-time */
  start: string;
  /** @format date-time */
  end: string;
  customerIds: string[];
  customerRecipients?: string[] | null;
}

export interface CustomerCo2StatisticsViewModel {
  entries: CustomerCo2StatisticsEntryViewObject[];
}

export interface CustomerCo2StatisticsViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: CustomerCo2StatisticsViewModel;
}

export interface CustomerContact {
  /** @format int32 */
  id?: number | null;
  /** @minLength 1 */
  name: string;
  /** @minLength 1 */
  lastName: string;
  /** @minLength 1 */
  email: string;
  /** @minLength 1 */
  phone: string;
}

export type CustomerContactViewObject = ContactViewObject & {
  /** @format uuid */
  customerId: string;
};

export interface CustomerDeliveryFee {
  /** @format uuid */
  id: string;
  /** @minLength 1 */
  name: string;
  /** @minLength 1 */
  description: string;
  validations: (
    | CustomerAddressEquals
    | DeliveryDateTimeOfDay
    | DeliveryItemsCount
    | DeliveryItemsTypeAndCount
    | DeliveryTypeEquals
    | DeliveryValidationAndCondition
    | DeliveryValidationDeliveryStatus
    | DeliveryValidationDeliveryStepStatus
    | DeliveryValidationDeliveryStepType
    | DeliveryValidationHasDeliveryDate
    | DeliveryValidationHasDescription
    | DeliveryValidationHasPickupDate
    | DeliveryValidationOrCondition
    | DeliveryValidationStepHasDescription
    | DeliveryValidationStorageLocation
    | ShipmentPayerEquals
  )[];
  value?: FeePercentageValue | PostponeDeliveryDateDeliveryFeeValue | ValueDeliveryFeeValue | null;
  /** @format int32 */
  order: number;
  active: boolean;
  /** @format date-time */
  activeFrom?: string | null;
  /** @format date-time */
  activeTo?: string | null;
}

export interface CustomerDeliveryFeeActionLogViewModel {
  logs: CustomerDeliveryFeeActionLogViewObject[];
  /** @format int32 */
  count: number;
}

export interface CustomerDeliveryFeeActionLogViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: CustomerDeliveryFeeActionLogViewModel;
}

export type CustomerDeliveryFeeActionLogViewObject = CustomerDeliveryFeeLogActionLogViewObject & {
  /** @format uuid */
  customerId: string;
};

export interface CustomerDeliveryFeeEconomicDataLog {
  changes?: PropertyChangeEvent[] | null;
}

export interface CustomerDeliveryFeeEconomicDataViewObject {
  /** @format uuid */
  economicAccessId: string;
  economicProductId: string;
}

export interface CustomerDeliveryFeeLog {
  changes?: PropertyChangeEvent[] | null;
  /** @format uuid */
  id: string;
  economicDataLogs?: CustomerDeliveryFeeEconomicDataLog[] | null;
}

export interface CustomerDeliveryFeeLogActionLogViewObject {
  TypeDiscriminator: string;
  /** @format uuid */
  id: string;
  /** @format uuid */
  entityId: string;
  created: string;
  /** @format uuid */
  createdBy: string;
  description: string;
  logType: EntityActionLogType;
  log?: CustomerDeliveryFeeLog | null;
}

export type CustomerDeliveryFeeViewObject = DeliveryFeeViewObject & {
  /** @format uuid */
  customerId: string;
  economicData: CustomerDeliveryFeeEconomicDataViewObject[];
};

export interface CustomerDeliveryFeeViewObjectArrayClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: CustomerDeliveryFeeViewObject[];
}

export interface CustomerDeliveryFeeViewObjectClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: CustomerDeliveryFeeViewObject;
}

export interface CustomerDeliveryFeesRequest {
  customerIds: string[];
}

export interface CustomerDeliveryInformationDistanceMatrixViewObject {
  /** @format int32 */
  distanceInMeters: number;
  /** @format int32 */
  estimatedDurationMinutes: number;
}

export interface CustomerDeliveryInformationDurationViewObject {
  startDate: string;
  endDate: string;
}

export interface CustomerDeliveryInformationFeeViewObject {
  name: string;
  /** @format double */
  fee: number;
  /** @format int32 */
  order: number;
}

export interface CustomerDeliveryInformationServiceFeeViewObject {
  /** @minLength 1 */
  name: string;
  /** @format double */
  fee: number;
  /** @format int32 */
  order: number;
  calculationEntry: ServiceFeeCalculationEntry;
}

export interface CustomerDeliveryInformationTemplateViewObject {
  /** @format uuid */
  id: string;
  title: string;
  created: string;
  /** @format uuid */
  createdBy?: string | null;
  modified?: string | null;
  /** @format uuid */
  modifiedBy?: string | null;
  isCustomerOnly: boolean;
  deliveryInformation: CustomerDeliveryInformationViewObject;
}

export interface CustomerDeliveryInformationTemplateViewObjectArrayClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: CustomerDeliveryInformationTemplateViewObject[];
}

export interface CustomerDeliveryInformationTemplateViewObjectClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: CustomerDeliveryInformationTemplateViewObject;
}

export interface CustomerDeliveryInformationViewObject {
  /** @format uuid */
  id: string;
  /** @format uuid */
  customerId: string;
  senderAddress: AddressViewObject | ShipmentRequestDeliveryInformation;
  receiverAddress: AddressViewObject | ShipmentRequestDeliveryInformation;
  billingAddress?: AddressViewObject | ShipmentRequestDeliveryInformation | null;
  /** @format double */
  deliveryPrice: number;
  /** @format double */
  totalDeliveryPrice: number;
  /** @format double */
  total: number;
  currency: string;
  /** @format double */
  discountPercentage?: number | null;
  /** @format double */
  discountPrice: number;
  /** @format double */
  shipmentWeight: number;
  deliveryFees: CustomerDeliveryInformationFeeViewObject[];
  serviceFees: CustomerDeliveryInformationServiceFeeViewObject[];
  duration?: CustomerDeliveryInformationDurationViewObject | null;
  /** @format double */
  hourPrice?: number | null;
  /** @format double */
  totalHourPrice?: number | null;
  /** @format double */
  fullLoadPrice?: number | null;
  /** @format double */
  weightPrice: number;
  /** @format double */
  totalCubicMetre: number;
  /** @format double */
  totalLoadMetre: number;
  created: string;
  deliveryDateTime?: string | null;
  pickupDate?: string | null;
  deliveryDate?: string | null;
  deliveredDate?: string | null;
  shipmentPayer: ShipmentPayer;
  deliveryType: DeliveryType;
  description?: string | null;
  isSenderOrReceiverCustomer: boolean;
  isDeliveryPriceUnavailable: boolean;
  requiresManualPriceUpdate: boolean;
  distanceMatrix?: CustomerDeliveryInformationDistanceMatrixViewObject | null;
  deliveryItems: DeliveryItemViewObject[];
}

export interface CustomerDeliveryItemTemplateViewObject {
  /** @format uuid */
  id: string;
  /** @format uuid */
  shipmentTypeId: string;
  /** @format int32 */
  amount: number;
  /** @format double */
  cost?: number | null;
  /** @format double */
  weightKG?: number | null;
  /** @format double */
  heightCM?: number | null;
  /** @format double */
  lengthCM?: number | null;
  /** @format double */
  widthCM?: number | null;
  /** @format double */
  cubicMetre?: number | null;
  /** @format double */
  loadMetre?: number | null;
  description?: string | null;
  reference?: string | null;
}

export interface CustomerDeliveryPriceConfigurationActionLogViewModel {
  logs: CustomerDeliveryPriceConfigurationActionLogViewObject[];
  /** @format int32 */
  count: number;
}

export interface CustomerDeliveryPriceConfigurationActionLogViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: CustomerDeliveryPriceConfigurationActionLogViewModel;
}

export type CustomerDeliveryPriceConfigurationActionLogViewObject =
  CustomerDeliveryPriceConfigurationLogActionLogViewObject & {
    /** @format uuid */
    customerId: string;
  };

export interface CustomerDeliveryPriceConfigurationLog {
  changes?: PropertyChangeEvent[] | null;
  /** @format uuid */
  id: string;
  postalCodePriceRangeLogs?: CustomerPostalCodePriceRangeLog[] | null;
  shipmentTypeWeightPriceRangeLogs?: CustomerShipmentTypeWeightPriceRangeLog[] | null;
  shipmentWeightPriceRangeLogs?: CustomerShipmentWeightPriceRangeLog[] | null;
  shipmentTypeTimePriceLogs?: CustomerShipmentTypeTimePriceLog[] | null;
}

export interface CustomerDeliveryPriceConfigurationLogActionLogViewObject {
  TypeDiscriminator: string;
  /** @format uuid */
  id: string;
  /** @format uuid */
  entityId: string;
  created: string;
  /** @format uuid */
  createdBy: string;
  description: string;
  logType: EntityActionLogType;
  log?: CustomerDeliveryPriceConfigurationLog | null;
}

export interface CustomerDeliveryPriceConfigurationViewModel {
  deliveryPriceConfigurations: CustomerDeliveryPriceConfigurationViewObject[];
}

export interface CustomerDeliveryPriceConfigurationViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: CustomerDeliveryPriceConfigurationViewModel;
}

export interface CustomerDeliveryPriceConfigurationViewObject {
  /** @format uuid */
  id: string;
  title: string;
  activeFrom: string;
  activeTo?: string | null;
  isActive: boolean;
  /** @format double */
  discountPercentage: number;
  shipmentWeightPriceRanges: CustomerShipmentWeightPriceRangeViewObject[];
  postalCodePriceRanges: CustomerPostalCodePriceRangeViewObject[];
  shipmentTypeWeightPriceRanges: CustomerShipmentTypeWeightPriceRangeViewObject[];
  hourPriceRanges: CustomerHourPriceRangeViewObject[];
}

export interface CustomerDeliveryPriceConfigurationViewObjectClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: CustomerDeliveryPriceConfigurationViewObject;
}

export interface CustomerEconomicDataLog {
  changes?: PropertyChangeEvent[] | null;
}

export interface CustomerHourPriceRange {
  /** @format uuid */
  id: string;
  /** @format double */
  hourFrom: number;
  /** @format double */
  hourTo: number;
  /** @format double */
  cost: number;
  /** @format uuid */
  shipmentTypeId?: string | null;
}

export interface CustomerHourPriceRangeViewObject {
  /** @format uuid */
  id: string;
  /** @format double */
  hourFrom: number;
  /** @format double */
  hourTo: number;
  /** @format double */
  cost: number;
  /** @format uuid */
  shipmentTypeId?: string | null;
}

export type CustomerHubConnectionViewObject = HubConnectionViewObject;

export interface CustomerHubUserCustomerHubConnectionHubUserViewObject {
  /** @format uuid */
  userId: string;
  location?: GeoLocation | null;
  locationTrackingIsAvailable: boolean;
  lastLocationPing?: string | null;
  activity?: string | null;
  lastActivity: string;
  lastChannel?: string | null;
  lastAppVersionId?: string | null;
}

export type CustomerHubUserViewObject = CustomerHubUserCustomerHubConnectionHubUserViewObject & {
  /** @format uuid */
  customerId: string;
  connections: CustomerHubConnectionViewObject[];
};

export interface CustomerIdExtractorConfiguration {
  TypeDiscriminator: string;
}

export type CustomerIdExtractorMailReaderDataExtractor = MailReaderDataExtractor & {
  configurations: Record<string, CustomerIdMailToGuidEntry | CustomerIdParserEntry>;
};

export type CustomerIdMailToGuidEntry = UtilRequiredKeys<CustomerIdExtractorConfiguration, "TypeDiscriminator"> & {
  /** @format uuid */
  customerId: string;
  TypeDiscriminator: string;
};

export type CustomerIdParserEntry = UtilRequiredKeys<CustomerIdExtractorConfiguration, "TypeDiscriminator"> & {
  parser:
    | ContainsTextFieldContentParser
    | LineTextFieldContentParser
    | HtmlKeyValueTableFieldContentParser
    | HtmlTableContentParser;
  fields: CustomerIdParserEntryField[];
  TypeDiscriminator: string;
};

export interface CustomerIdParserEntryField {
  field: string;
  value: string;
  /** @format uuid */
  customerId: string;
}

export interface CustomerKMDateEntryViewObject {
  description: string;
  /** @format double */
  totalKM: number;
  date: string;
  entries: CustomerKMDateEntryViewObject[];
}

export interface CustomerKMDateSeriesEntryViewModel {
  entries: CustomerKMDateSeriesEntryViewObject[];
}

export interface CustomerKMDateSeriesEntryViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: CustomerKMDateSeriesEntryViewModel;
}

export interface CustomerKMDateSeriesEntryViewObject {
  description: string;
  entries: CustomerKMDateEntryViewObject[];
}

export interface CustomerKMStatisticsEntryViewObject {
  /** @format uuid */
  customerId: string;
  /** @format double */
  km: number;
}

export interface CustomerKMStatisticsViewModel {
  entries: CustomerKMStatisticsEntryViewObject[];
}

export interface CustomerKMStatisticsViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: CustomerKMStatisticsViewModel;
}

export type CustomerLockViewObject = GuidEntityLockViewObject;

export interface CustomerLog {
  changes?: PropertyChangeEvent[] | null;
  addressLog?: AddressLog | null;
  deliveryFeeLogs?: CustomerDeliveryFeeLog[] | null;
  shipmentTypeLogs?: CustomerShipmentTypeLog[] | null;
  shipmentTypeCategoryLogs?: CustomerShipmentTypeCategoryLog[] | null;
  settingsLog?: CustomerSettingsLog | null;
  economicDataLogs?: CustomerEconomicDataLog[] | null;
}

export interface CustomerLogActionLogViewObject {
  TypeDiscriminator: string;
  /** @format uuid */
  id: string;
  /** @format uuid */
  entityId: string;
  created: string;
  /** @format uuid */
  createdBy: string;
  description: string;
  logType: EntityActionLogType;
  log?: CustomerLog | null;
}

export interface CustomerNoteViewModel {
  notes: CustomerNoteViewObject[];
  /** @format int32 */
  total: number;
}

export interface CustomerNoteViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: CustomerNoteViewModel;
}

export interface CustomerNoteViewObject {
  /** @format int32 */
  id: number;
  note: string;
  date: string;
  /** @format uuid */
  createdBy?: string | null;
  created: string;
  /** @format uuid */
  modifiedBy?: string | null;
  modified?: string | null;
}

export interface CustomerNoteViewObjectClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: CustomerNoteViewObject;
}

export interface CustomerOrderCountResponse {
  counts: Record<string, number>;
}

export interface CustomerOrderCountResponseClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: CustomerOrderCountResponse;
}

export interface CustomerPostalCodePriceRange {
  /** @format uuid */
  id: string;
  /** @format double */
  baseCost: number;
  /** @format double */
  minCost: number;
  /** @minLength 1 */
  name: string;
  postalCodeRanges: CustomerPostalCodePriceRangeEntry[];
  shipmentTypePriceRanges: CustomerPostalCodeShipmentTypePriceRange[];
  shipmentTypePriceRangeMetaData: CustomerPostalCodeShipmentTypePriceRangeMetaData[];
}

export interface CustomerPostalCodePriceRangeEntry {
  /** @format uuid */
  id: string;
  /** @format uuid */
  postalCodePriceRangeId: string;
  /** @format int32 */
  codeFrom: number;
  /** @format int32 */
  codeTo: number;
}

export interface CustomerPostalCodePriceRangeEntryViewObject {
  /** @format uuid */
  id: string;
  /** @format int32 */
  codeFrom: number;
  /** @format int32 */
  codeTo: number;
  /** @format uuid */
  postalCodePriceRangeId: string;
}

export interface CustomerPostalCodePriceRangeLog {
  changes?: PropertyChangeEvent[] | null;
  /** @format uuid */
  id: string;
}

export interface CustomerPostalCodePriceRangeViewObject {
  /** @format uuid */
  id: string;
  /** @format double */
  baseCost: number;
  /** @format double */
  minCost: number;
  name: string;
  shipmentTypePriceRangeMetaData: CustomerPostalCodeShipmentTypePriceRangeMetaDataViewObject[];
  postalCodeRanges: CustomerPostalCodePriceRangeEntryViewObject[];
  shipmentTypePriceRanges: CustomerShipmentTypePriceRangeViewObject[];
}

export interface CustomerPostalCodeShipmentTypePriceRange {
  /** @format uuid */
  id: string;
  /** @format uuid */
  postalCodePriceRangeId: string;
  /** @format uuid */
  shipmentTypeId: string;
  /** @format int32 */
  amountFrom: number;
  /** @format int32 */
  amountTo: number;
  /** @format double */
  weightFromKg: number;
  /** @format double */
  weightToKg: number;
  /** @format double */
  cubicMetreFrom: number;
  /** @format double */
  cubicMetreTo: number;
  /** @format double */
  loadMetreFrom: number;
  /** @format double */
  loadMetreTo: number;
  /** @format double */
  cost: number;
  /** @format double */
  costPerCubicMetre: number;
  /** @format double */
  costPerLoadMetre: number;
}

export interface CustomerPostalCodeShipmentTypePriceRangeMetaData {
  /** @format uuid */
  postalCodePriceRangeId: string;
  /** @format uuid */
  shipmentTypeId: string;
  /** @format double */
  baseCost: number;
  /** @format double */
  minCost: number;
  fallbackStrategy: PostalCodeShipmentTypePriceRangeFallbackStrategy;
}

export interface CustomerPostalCodeShipmentTypePriceRangeMetaDataViewObject {
  /** @format uuid */
  postalCodePriceRangeId: string;
  /** @format uuid */
  shipmentTypeId: string;
  fallbackStrategy: PostalCodeShipmentTypePriceRangeFallbackStrategy;
  /** @format double */
  baseCost: number;
  /** @format double */
  minCost: number;
}

export enum CustomerPriceCalculationMethod {
  None = "None",
  PostalCode = "PostalCode",
  Weight = "Weight",
  FullLoad = "FullLoad",
  Hour = "Hour",
}

export interface CustomerRevenueDateEntryViewObject {
  description: string;
  /** @format double */
  revenue: number;
  date: string;
  entries: CustomerRevenueDateEntryViewObject[];
}

export interface CustomerRevenueDateSeriesEntryViewModel {
  entries: CustomerRevenueDateSeriesEntryViewObject[];
}

export interface CustomerRevenueDateSeriesEntryViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: CustomerRevenueDateSeriesEntryViewModel;
}

export interface CustomerRevenueDateSeriesEntryViewObject {
  description: string;
  entries: CustomerRevenueDateEntryViewObject[];
}

export interface CustomerRevenueStatisticsEntryViewObject {
  /** @format uuid */
  customerId: string;
  /** @format double */
  revenue: number;
}

export interface CustomerRevenueStatisticsViewModel {
  entries: CustomerRevenueStatisticsEntryViewObject[];
}

export interface CustomerRevenueStatisticsViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: CustomerRevenueStatisticsViewModel;
}

export interface CustomerSettingsLog {
  changes?: PropertyChangeEvent[] | null;
}

export interface CustomerSettingsViewObject {
  allowedShipmentPayerTypes: ShipmentPayer[];
  sendConfirmationEmails: boolean;
  allowFastDelivery: boolean;
  showDeliveryPrices: boolean;
  trackTruckKM: boolean;
  requirePickupImageVerification: boolean;
  requireDeliveryImageVerification: boolean;
  /** @format int32 */
  hoursSinceCreationTaskCanBeEdited?: number | null;
  /** @format int32 */
  hoursSinceCreationTaskCanBeDeleted?: number | null;
  canUseDeliveryInformationTemplates: boolean;
  paymentCurrency: string;
}

export interface CustomerShipmentType {
  /** @format uuid */
  id: string;
  /** @minLength 1 */
  name: string;
  shortName?: string | null;
  description?: string | null;
  imageUrl: string;
  heightLimitCM: DimensionRestriction;
  widthLimitCM: DimensionRestriction;
  lengthLimitCM: DimensionRestriction;
  weightLimitKG: DimensionRestriction;
  cubicMetreLimit: DimensionRestriction;
  loadMetreLimit: DimensionRestriction;
  /** @format int32 */
  sort: number;
  /** @format uuid */
  shipmentTypeCategoryId: string;
  /** @format double */
  baseCost: number;
  /** @format double */
  minCost: number;
  /** @format int32 */
  salesId: number;
  /** @maxLength 7 */
  colorCodeIdentification?: string | null;
  aliases: string[];
  /** @format date-time */
  deletedSince?: string | null;
}

export interface CustomerShipmentTypeCategoriesRequest {
  customerIds: string[];
}

export interface CustomerShipmentTypeCategoriesViewModel {
  shipmentTypeCategories: Record<string, CustomerShipmentTypeCategoryViewObject[]>;
}

export interface CustomerShipmentTypeCategoriesViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: CustomerShipmentTypeCategoriesViewModel;
}

export interface CustomerShipmentTypeCategory {
  /** @format uuid */
  id: string;
  /** @minLength 1 */
  name: string;
  description: string;
  priceCalculationMethod: CustomerPriceCalculationMethod;
  /** @format double */
  baseCost: number;
  /** @format double */
  minCost: number;
  /** @format date-time */
  deletedSince?: string | null;
  /** @format date-time */
  modified?: string | null;
  /** @format uuid */
  modifiedBy?: string | null;
  dimensionsDisplayOrder: DimensionsType[];
}

export interface CustomerShipmentTypeCategoryEconomicDataLog {
  changes?: PropertyChangeEvent[] | null;
}

export interface CustomerShipmentTypeCategoryEconomicDataViewObject {
  /** @format uuid */
  economicAccessId: string;
  /** @format int32 */
  economicUnitId: number;
}

export interface CustomerShipmentTypeCategoryLog {
  changes?: PropertyChangeEvent[] | null;
  /** @format uuid */
  id: string;
  economicDataLogs?: CustomerShipmentTypeCategoryEconomicDataLog[] | null;
}

export interface CustomerShipmentTypeCategoryViewObject {
  /** @format uuid */
  id: string;
  /** @format uuid */
  customerId: string;
  name: string;
  description: string;
  priceCalculationMethod: CustomerPriceCalculationMethod;
  /** @format double */
  baseCost: number;
  /** @format double */
  minCost: number;
  deletedSince?: string | null;
  modified?: string | null;
  /** @format uuid */
  modifiedBy?: string | null;
  dimensionsDisplayOrder: DimensionsType[];
  economicData: CustomerShipmentTypeCategoryEconomicDataViewObject[];
}

export interface CustomerShipmentTypeCategoryViewObjectArrayClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: CustomerShipmentTypeCategoryViewObject[];
}

export interface CustomerShipmentTypeCategoryViewObjectClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: CustomerShipmentTypeCategoryViewObject;
}

export interface CustomerShipmentTypeEconomicDataLog {
  changes?: PropertyChangeEvent[] | null;
}

export interface CustomerShipmentTypeEconomicDataViewObject {
  /** @format uuid */
  economicAccessId: string;
  economicProductId: string;
}

export interface CustomerShipmentTypeLog {
  changes?: PropertyChangeEvent[] | null;
  /** @format uuid */
  id: string;
  economicDataLogs?: CustomerShipmentTypeEconomicDataLog[] | null;
}

export interface CustomerShipmentTypePriceRangeViewObject {
  /** @format uuid */
  id: string;
  /** @format uuid */
  shipmentTypeId: string;
  /** @format uuid */
  postalCodePriceRangeId: string;
  /** @format int32 */
  amountFrom: number;
  /** @format int32 */
  amountTo: number;
  /** @format double */
  weightFromKg: number;
  /** @format double */
  weightToKg: number;
  /** @format double */
  cubicMetreFrom: number;
  /** @format double */
  cubicMetreTo: number;
  /** @format double */
  loadMetreFrom: number;
  /** @format double */
  loadMetreTo: number;
  /** @format double */
  cost: number;
  /** @format double */
  costPerCubicMetre: number;
  /** @format double */
  costPerLoadMetre: number;
}

export interface CustomerShipmentTypeTimePriceLog {
  changes?: PropertyChangeEvent[] | null;
  /** @format int32 */
  id: number;
  /** @format uuid */
  shipmentTypeId: string;
}

export interface CustomerShipmentTypeViewObject {
  /** @format uuid */
  id: string;
  /** @format uuid */
  shipmentTypeCategoryId: string;
  /** @format uuid */
  customerId: string;
  name: string;
  shortName?: string | null;
  description?: string | null;
  imageUrl: string;
  heightLimitCM: DimensionRestriction;
  widthLimitCM: DimensionRestriction;
  lengthLimitCM: DimensionRestriction;
  cubicMetreLimit: DimensionRestriction;
  loadMetreLimit: DimensionRestriction;
  weightLimitKG: DimensionRestriction;
  /** @format int32 */
  sort: number;
  /** @format double */
  baseCost: number;
  /** @format double */
  minCost: number;
  modified?: string | null;
  /** @format uuid */
  modifiedBy?: string | null;
  deletedSince?: string | null;
  /** @format int32 */
  salesId: number;
  colorCodeIdentification?: string | null;
  aliases: string[];
  economicData: CustomerShipmentTypeEconomicDataViewObject[];
}

export interface CustomerShipmentTypeViewObjectArrayClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: CustomerShipmentTypeViewObject[];
}

export interface CustomerShipmentTypeViewObjectClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: CustomerShipmentTypeViewObject;
}

export interface CustomerShipmentTypeWeightPriceRange {
  /** @format uuid */
  id: string;
  /** @format double */
  weightFromKg: number;
  /** @format double */
  weightToKg: number;
  /** @format double */
  cost: number;
  /** @format uuid */
  shipmentTypeId?: string | null;
}

export interface CustomerShipmentTypeWeightPriceRangeLog {
  changes?: PropertyChangeEvent[] | null;
  /** @format uuid */
  id: string;
}

export interface CustomerShipmentTypeWeightPriceRangeViewObject {
  /** @format uuid */
  id: string;
  /** @format double */
  weightFromKg: number;
  /** @format double */
  weightToKg: number;
  /** @format double */
  cost: number;
}

export interface CustomerShipmentTypesRequest {
  customerIds: string[];
  excludeDeleted: boolean;
}

export interface CustomerShipmentTypesViewModel {
  shipmentTypes: Record<string, CustomerShipmentTypeViewObject[]>;
}

export interface CustomerShipmentTypesViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: CustomerShipmentTypesViewModel;
}

export interface CustomerShipmentWeightPriceRange {
  /** @format uuid */
  id: string;
  /** @format double */
  weightFromKg: number;
  /** @format double */
  weightToKg: number;
  /** @format double */
  cost: number;
}

export interface CustomerShipmentWeightPriceRangeLog {
  changes?: PropertyChangeEvent[] | null;
  /** @format uuid */
  id: string;
}

export interface CustomerShipmentWeightPriceRangeViewObject {
  /** @format uuid */
  id: string;
  /** @format double */
  weightFromKg: number;
  /** @format double */
  weightToKg: number;
  /** @format double */
  cost: number;
}

export interface CustomerUserActionLogViewModel {
  logs: CustomerUserActionLogViewObject[];
  /** @format int32 */
  count: number;
}

export interface CustomerUserActionLogViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: CustomerUserActionLogViewModel;
}

export type CustomerUserActionLogViewObject = UserLogActionLogViewObject & {
  /** @format uuid */
  customerId: string;
};

export interface CustomerUserCountResponse {
  counts: Record<string, number>;
}

export interface CustomerUserCountResponseClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: CustomerUserCountResponse;
}

export interface CustomerUserLoginDateEntryViewObject {
  description: string;
  /** @format int32 */
  count: number;
  date: string;
  entries: CustomerUserLoginDateEntryViewObject[];
}

export interface CustomerUserLoginDateSeriesEntryViewModel {
  entries: CustomerUserLoginDateSeriesEntryViewObject[];
}

export interface CustomerUserLoginDateSeriesEntryViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: CustomerUserLoginDateSeriesEntryViewModel;
}

export interface CustomerUserLoginDateSeriesEntryViewObject {
  description: string;
  entries: CustomerUserLoginDateEntryViewObject[];
}

export interface CustomerUserLoginHistoryViewModel {
  loginHistories: CustomerUserLoginHistoryViewObject[];
}

export interface CustomerUserLoginHistoryViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: CustomerUserLoginHistoryViewModel;
}

export type CustomerUserLoginHistoryViewObject = UserLoginHistoryViewObject & {
  /** @format uuid */
  businessEntityId: string;
  /** @format uuid */
  customerId: string;
};

export enum CustomerUserRole {
  None = "None",
}

export interface CustomerUserViewModel {
  user: CustomerUserViewObject;
}

export interface CustomerUserViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: CustomerUserViewModel;
}

export type CustomerUserViewObject = EntityViewObject & {
  name: string;
  email: string;
  lastName: string;
  fullName: string;
  phone?: string | null;
  whatsAppNumber?: string | null;
  lastLogin?: string | null;
  lastIp?: string | null;
  activeConnection?: CustomerHubUserViewObject | null;
};

export interface CustomerUsersViewModel {
  users: CustomerUserViewObject[];
}

export interface CustomerUsersViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: CustomerUsersViewModel;
}

export interface CustomerViewModel {
  customer: CustomerViewObject;
}

export interface CustomerViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: CustomerViewModel;
}

export type CustomerViewObject = EntityViewObject & {
  /** @format uuid */
  businessEntityId: string;
  name: string;
  description?: string | null;
  logoUrl: string;
  isAvailableForShipping: boolean;
  /** @format int32 */
  userCount: number;
  /** @format int32 */
  orderCount: number;
  /** @format int32 */
  addressCount: number;
  /** @format int32 */
  activeUserCount: number;
  settings: CustomerSettingsViewObject;
  aliases: string[];
  contacts: CustomerContactViewObject[];
  address: AddressViewObject | ShipmentRequestDeliveryInformation;
  lock?: CustomerLockViewObject | null;
} & {
  marked: boolean;
  dragging: boolean;
  sort: number;
};

export interface CustomersViewModel {
  customers: CustomerViewObject[];
}

export interface CustomersViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: CustomersViewModel;
}

export interface DashboardColorSettings {
  /** @minLength 1 */
  assignedTaskColor: string;
  /** @minLength 1 */
  completedTaskColor: string;
  /** @minLength 1 */
  dateAssignedTaskColor: string;
  /** @minLength 1 */
  directDeliveryTaskColor: string;
  /** @minLength 1 */
  inProgressTaskColor: string;
  /** @minLength 1 */
  inStorageTaskColor: string;
  /** @minLength 1 */
  awaitingStorageTaskColor: string;
  /** @minLength 1 */
  invoicedTaskColor: string;
  /** @minLength 1 */
  nextDayDeliveryTaskColor: string;
  /** @minLength 1 */
  paidTaskColor: string;
  /** @minLength 1 */
  unassignedTaskColor: string;
  /** @minLength 1 */
  taskOriginManualColor: string;
  /** @minLength 1 */
  taskOriginScheduleColor: string;
  /** @minLength 1 */
  taskOriginBookingColor: string;
  /** @minLength 1 */
  taskOriginMailColor: string;
}

export enum DashboardRouteAreaAccess {
  Dashboard = "Dashboard",
  LiveView = "LiveView",
  Resources = "Resources",
  Storages = "Storages",
  Tasks = "Tasks",
  Customers = "Customers",
  Employees = "Employees",
  Dictionary = "Dictionary",
  Statistics = "Statistics",
  Administration = "Administration",
  Auth = "Auth",
  Notifications = "Notifications",
  IntegrationsAccounting = "Integrations_Accounting",
  Projects = "Projects",
}

export interface DashboardViewEntry {
  /** @format uuid */
  id: string;
  /** @maxLength 2000 */
  name: string;
  /** @format int32 */
  fontSize: number;
  /** @format int32 */
  order: number;
  /** @format int32 */
  columns: number;
  resourceIds: string[];
  driverIds: string[];
  storageIds: string[];
  hideHeader: boolean;
  allowSticky: boolean;
  allowMessages: boolean;
  allowScheduling: boolean;
  allowSorting: boolean;
  dynamicScheduling: boolean;
  /** @format int32 */
  marginBottom: number;
  isDefault: boolean;
  checkboxes: DashboardViewEntryCheckbox[];
}

export interface DashboardViewEntryCheckbox {
  /** @format int32 */
  id: number;
  /** @minLength 1 */
  name: string;
  description?: string | null;
  location: DashboardViewEntryCheckboxLocation;
}

export interface DashboardViewEntryCheckboxCheckedEntryViewObject {
  /** @format uuid */
  dashboardViewId: string;
  /** @format uuid */
  checkedId: string;
  /** @format int32 */
  checkboxId: number;
  date: string;
}

export interface DashboardViewEntryCheckboxCheckedEntryViewObjectArrayClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: DashboardViewEntryCheckboxCheckedEntryViewObject[];
}

export interface DashboardViewEntryCheckboxCheckedEntryViewObjectClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: DashboardViewEntryCheckboxCheckedEntryViewObject;
}

export enum DashboardViewEntryCheckboxLocation {
  Entry = "Entry",
  Driver = "Driver",
  Storage = "Storage",
}

export interface DashboardViewEntryCheckboxViewObject {
  /** @format int32 */
  id: number;
  /** @format uuid */
  dashboardEntryId: string;
  name: string;
  description?: string | null;
  location: DashboardViewEntryCheckboxLocation;
  created: string;
  /** @format uuid */
  createdBy?: string | null;
  modified?: string | null;
  /** @format uuid */
  modifiedBy?: string | null;
  checkedEntries: DashboardViewEntryCheckboxCheckedEntryViewObject[];
}

export type DashboardViewEntryLockViewObject = GuidDateTimeEntityLockViewObject & {
  /** @format uuid */
  entryId: string;
  date: string;
};

export interface DashboardViewEntryMessageViewObject {
  /** @format int32 */
  id: number;
  /** @format uuid */
  dashboardViewId: string;
  /** @format uuid */
  dashboardEntryId: string;
  date: string;
  message: string;
  /** @format int32 */
  order: number;
  created: string;
  /** @format uuid */
  createdBy?: string | null;
  modified?: string | null;
  /** @format uuid */
  modifiedBy?: string | null;
}

export interface DashboardViewEntryMessageViewObjectArrayClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: DashboardViewEntryMessageViewObject[];
}

export interface DashboardViewEntryMessageViewObjectClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: DashboardViewEntryMessageViewObject;
}

export interface DashboardViewEntryTaskGridDividerViewObject {
  /** @format int32 */
  id: number;
  /** @format uuid */
  dashboardViewId: string;
  /** @format uuid */
  dashboardEntryId: string;
  date: string;
  /** @format uuid */
  scheduleEntryId?: string | null;
  description: string;
  color: string;
  /** @format int32 */
  buttonSize: number;
  /** @format int32 */
  buttonRadius: number;
  /** @format int32 */
  lineHeight: number;
  /** @format int32 */
  columnIndex: number;
  alwaysShowText: boolean;
  created: string;
  /** @format uuid */
  createdBy?: string | null;
  modified?: string | null;
  /** @format uuid */
  modifiedBy?: string | null;

  hovering: boolean;
}

export interface DashboardViewEntryTaskGridDividerViewObjectArrayClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: DashboardViewEntryTaskGridDividerViewObject[];
}

export interface DashboardViewEntryTaskGridDividerViewObjectClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: DashboardViewEntryTaskGridDividerViewObject;
}

export interface DashboardViewEntryViewObject {
  /** @format uuid */
  id: string;
  /** @format uuid */
  dashboardViewId: string;
  name: string;
  /** @format int32 */
  fontSize: number;
  /** @format int32 */
  order: number;
  /** @format int32 */
  columns: number;
  hideHeader: boolean;
  allowSticky: boolean;
  allowMessages: boolean;
  allowScheduling: boolean;
  allowSorting: boolean;
  dynamicScheduling: boolean;
  /** @format int32 */
  marginBottom: number;
  isDefault: boolean;
  resourceIds: string[];
  driverIds: string[];
  storageIds: string[];
  messages: DashboardViewEntryMessageViewObject[];
  checkboxes: DashboardViewEntryCheckboxViewObject[];
  locks: DashboardViewEntryLockViewObject[];

  isSticky: boolean;
}

export type DashboardViewLockViewObject = GuidEntityLockViewObject;

export interface DashboardViewViewModel {
  views: DashboardViewViewObject[];
}

export interface DashboardViewViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: DashboardViewViewModel;
}

export interface DashboardViewViewObject {
  /** @format uuid */
  id: string;
  name: string;
  description?: string | null;
  activeFrom?: string | null;
  activeTo?: string | null;
  /** @format int32 */
  columns: number;
  isActive: boolean;
  created: string;
  modified?: string | null;
  /** @format uuid */
  modifiedBy?: string | null;
  entries: DashboardViewEntryViewObject[];
  lock?: DashboardViewLockViewObject | null;
}

export interface DashboardViewViewObjectClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: DashboardViewViewObject;
}

export type DateExtractorMailReaderDataExtractor = MailReaderDataExtractor & {
  parser:
    | ContainsTextFieldContentParser
    | LineTextFieldContentParser
    | HtmlKeyValueTableFieldContentParser
    | HtmlTableContentParser;
  /** @uniqueItems true */
  fields?: string[] | null;
};

export interface DateTimeNullableFieldWithError {
  /** @format date-time */
  data?: string | null;
  errors?: string[] | null;
}

export interface DeleteChatRequest {
  /** @format uuid */
  chatId: string;
}

export type DeliveryDateExtractorMailReaderDataExtractor = DateExtractorMailReaderDataExtractor;

export type DeliveryDateFilter = UtilRequiredKeys<TaskSearchFilter, "TypeDiscriminator"> & {
  /** @format date-time */
  from: string;
  /** @format date-time */
  to: string;
  TypeDiscriminator: string;
};

export type DeliveryDateTimeOfDay = UtilRequiredKeys<DeliveryValidation, "TypeDiscriminator"> & {
  scope: DeliveryDateTimeOfDayEvaluationScope;
  time: TimeOfDay;
  TypeDiscriminator: string;
};

export enum DeliveryDateTimeOfDayEvaluationScope {
  LessThan = "LessThan",
  Equals = "Equals",
  GreaterThan = "GreaterThan",
}

export interface DeliveryDeviationLog {
  changes?: PropertyChangeEvent[] | null;
}

export interface DeliveryDeviationTypeViewModel {
  deliveryDeviations: DeliveryDeviationTypeViewObject[];
}

export interface DeliveryDeviationTypeViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: DeliveryDeviationTypeViewModel;
}

export interface DeliveryDeviationTypeViewObject {
  /** @format uuid */
  id: string;
  name: string;
  description: DeviationFieldType;
  images: DeviationFieldType;
  amount: DeviationFieldType;
  created: string;
  /** @format uuid */
  createdBy?: string | null;
  modified?: string | null;
  /** @format uuid */
  modifiedBy?: string | null;
  /** @format int32 */
  taskGridColumnId?: number | null;
  enabled: boolean;
}

export interface DeliveryDeviationTypeViewObjectClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: DeliveryDeviationTypeViewObject;
}

export interface DeliveryDeviationViewObject {
  /** @format uuid */
  deviationTypeId: string;
  description?: string | null;
  imageUrls?: string[] | null;
  /** @format double */
  amount?: number | null;
}

export interface DeliveryFeeValue {
  TypeDiscriminator: string;
}

export interface DeliveryFeeViewObject {
  /** @format uuid */
  id: string;
  name: string;
  description: string;
  /** @format uuid */
  modifiedBy: string;
  modified: string;
  /** @format uuid */
  createdBy: string;
  created: string;
  /** @format int32 */
  order: number;
  active: boolean;
  activeFrom?: string | null;
  activeTo?: string | null;
  validations: (
    | CustomerAddressEquals
    | DeliveryDateTimeOfDay
    | DeliveryItemsCount
    | DeliveryItemsTypeAndCount
    | DeliveryTypeEquals
    | DeliveryValidationAndCondition
    | DeliveryValidationDeliveryStatus
    | DeliveryValidationDeliveryStepStatus
    | DeliveryValidationDeliveryStepType
    | DeliveryValidationHasDeliveryDate
    | DeliveryValidationHasDescription
    | DeliveryValidationHasPickupDate
    | DeliveryValidationOrCondition
    | DeliveryValidationStepHasDescription
    | DeliveryValidationStorageLocation
    | ShipmentPayerEquals
  )[];
  value: FeePercentageValue | PostponeDeliveryDateDeliveryFeeValue | ValueDeliveryFeeValue;
}

export interface DeliveryInformationLog {
  changes?: PropertyChangeEvent[] | null;
  senderAddressLog?: AddressLog | null;
  receiverAddressLog?: AddressLog | null;
  billingAddressLog?: AddressLog | null;
  deliveryItemLogs?: DeliveryItemLog[] | null;
}

export type DeliveryItemAmountExportConfigurationEntry = UtilRequiredKeys<
  ExportConfigurationEntry,
  "TypeDiscriminator"
> & {
  TypeDiscriminator: string;
};

export interface DeliveryItemLog {
  changes?: PropertyChangeEvent[] | null;
  name: string;
  /** @format uuid */
  id: string;
}

export type DeliveryItemNameExportConfigurationEntry = UtilRequiredKeys<
  ExportConfigurationEntry,
  "TypeDiscriminator"
> & {
  includeAmount: boolean;
  TypeDiscriminator: string;
};

export type DeliveryItemPriceExportConfigurationEntry = UtilRequiredKeys<
  ExportConfigurationEntry,
  "TypeDiscriminator"
> & {
  format: boolean;
  TypeDiscriminator: string;
};

export interface DeliveryItemViewObject {
  /** @format uuid */
  id: string;
  name: string;
  /** @format uuid */
  customerId: string;
  /** @format uuid */
  deliveryInformationId: string;
  /** @format uuid */
  shipmentTypeId: string;
  /** @format int32 */
  amount: number;
  /** @format double */
  cost?: number | null;
  /** @format double */
  totalCost?: number | null;
  /** @format double */
  weightKG: number;
  /** @format double */
  heightCM?: number | null;
  /** @format double */
  lengthCM?: number | null;
  /** @format double */
  widthCM?: number | null;
  /** @format double */
  cubicMetre?: number | null;
  /** @format double */
  loadMetre?: number | null;
  /** @format double */
  totalWeightKG: number;
  /** @format double */
  totalCubicMetre?: number | null;
  /** @format double */
  totalLoadMetre?: number | null;
  description?: string | null;
  reference?: string | null;
  customCost: boolean;
}

export type DeliveryItemsCount = UtilRequiredKeys<DeliveryValidation, "TypeDiscriminator"> & {
  scope: DeliveryItemsCountEvaluationScope;
  /** @format int32 */
  count: number;
  method: DeliveryItemsCountEvaluationMethod;
  TypeDiscriminator: string;
};

export enum DeliveryItemsCountEvaluationMethod {
  Sum = "Sum",
  Lines = "Lines",
}

export enum DeliveryItemsCountEvaluationScope {
  LessThan = "LessThan",
  Equals = "Equals",
  GreaterThan = "GreaterThan",
}

export type DeliveryItemsExtractorMailReaderDataExtractor = MailReaderDataExtractor & {
  fields?: Record<string, MailExtractedDeliveryItemFields>;
  parser:
    | ContainsTextFieldContentParser
    | LineTextFieldContentParser
    | HtmlKeyValueTableFieldContentParser
    | HtmlTableContentParser;
  strict: boolean;
  shipmentTypeLookup: Record<string, Record<string, string>>;
};

export type DeliveryItemsTypeAndCount = UtilRequiredKeys<DeliveryValidation, "TypeDiscriminator"> & {
  /** @format int32 */
  count: number;
  scope: DeliveryItemsTypeAndCountEvaluationScope;
  /** @format uuid */
  shipmentTypeId: string;
  TypeDiscriminator: string;
};

export enum DeliveryItemsTypeAndCountEvaluationScope {
  LessThan = "LessThan",
  Equals = "Equals",
  GreaterThan = "GreaterThan",
}

export interface DeliverySettings {
  /** @format int32 */
  maxDaysForward: number;
  forceGeoLocationTracking: boolean;
  breakReasons: string[];
  breakReasonsIsRequired: boolean;
  allowCustomBreakReason: boolean;
  alwaysShowBothAddresses: boolean;
  showDeliveryTime: boolean;
  showPickupTime: boolean;
  showDescription: boolean;
  deliveryTypesToShowAsOne: DeliveryType[];
  trackTaskStart: boolean;
  trackTruckKM: boolean;
  trackNumberOfLoads: boolean;
  /** @format int32 */
  taskCompletionCancellationTime: number;
  showDownloadScheduleButton: boolean;
  allowOverwritingStorageLocation: boolean;
  allowEndingDayWithoutCompletingAllTasks: boolean;
  /** @format int32 */
  driverLogDurationHoursWarning: number;
  driverCanReOrganizeSchedule: boolean;
  showTaskOrderOnOverview: boolean;
  showScheduleOnOverview: boolean;
  /** @format int32 */
  earlyScheduleStartMinutes: number;
}

export interface DeliveryStatistics {
  /** @format double */
  timeBetweenStepsSeconds?: number | null;
  /** @format double */
  totalDurationSeconds?: number | null;
  /** @format double */
  co2Emissions: number;
}

export enum DeliveryStatus {
  Unassigned = "Unassigned",
  DateAssigned = "DateAssigned",
  Assigned = "Assigned",
  AwaitingStorage = "AwaitingStorage",
  InStorage = "InStorage",
  InProgress = "InProgress",
  Complete = "Complete",
  Invoiced = "Invoiced",
}

export type DeliveryStepDeliveryViewObject = DeliveryStepViewObject & {
  /** @format uuid */
  storageId?: string | null;
  /** @format uuid */
  storedByUserId?: string | null;
  storedSince?: string | null;
  isInStorage: boolean;
  isAwaitingStorage: boolean;
  signature?: DeliveryTaskStepSignatureViewObject | null;
};

export interface DeliveryStepInformationLog {
  changes?: PropertyChangeEvent[] | null;
}

export type DeliveryStepPickupViewObject = DeliveryStepViewObject & {
  storageLocation: StorageLocation;
  /** @format uuid */
  selectedStorageId?: string | null;
  deliveryDeviation?: DeliveryDeviationViewObject | null;
};

export enum DeliveryStepStatus {
  Unassigned = "Unassigned",
  DateAssigned = "DateAssigned",
  Assigned = "Assigned",
  AwaitingStorage = "AwaitingStorage",
  InStorage = "InStorage",
  InProgress = "InProgress",
  Complete = "Complete",
}

export enum DeliveryStepType {
  Pickup = "Pickup",
  Delivery = "Delivery",
}

export interface DeliveryStepViewObject {
  /** @format uuid */
  deliveryTaskId: string;
  title: string;
  description?: string | null;
  status: DeliveryStepStatus;
  requestedHandleDate?: string | null;
  scheduleDate?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  expectedDuration: DurationViewObject;
  carrierDuration: DurationViewObject;
  startLocation?: GeoLocation | null;
  endLocation?: GeoLocation | null;
  /** @format double */
  startKM?: number | null;
  /** @format double */
  endKM?: number | null;
  imageUrl?: string | null;
  /** @format uuid */
  startedBy?: string | null;
  /** @format uuid */
  completedBy?: string | null;
  isInProgress: boolean;
  isCompleted: boolean;
  /** @format int32 */
  order: number;
  notifyDriver: boolean;
  visibleForDriver: boolean;
  /** @format double */
  segmentDistanceInMeters: number;
  /** @format double */
  segmentDurationInSeconds: number;
  /** @format double */
  co2Emissions: number;
  /** @format double */
  isolatedCo2Emissions: number;
  /** @format double */
  estimatedCo2Emissions: number;
  /** @format uuid */
  driverLogId?: string | null;
  /** @format uuid */
  scheduleEntryId?: string | null;
  /** @format uuid */
  projectId?: string | null;
  /** @format uuid */
  projectEntryId?: string | null;
  dashboardEntries?: Record<string, string | null>;
  information?: DeliveryTaskStepInformationViewObject | null;
  deliveryStepType: DeliveryStepType;
}

export interface DeliveryTaskActionLogViewModel {
  logs: DeliveryTaskActionLogViewObject[];
  /** @format int32 */
  count: number;
}

export interface DeliveryTaskActionLogViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: DeliveryTaskActionLogViewModel;
}

export type DeliveryTaskActionLogViewObject = DeliveryTaskLogActionLogViewObject;

export type DeliveryTaskDeliveryLog = DeliveryTaskStepLog;

export type DeliveryTaskEntityLockViewObject = GuidEntityLockViewObject;

export interface DeliveryTaskLog {
  changes?: PropertyChangeEvent[] | null;
  pickupLog?: DeliveryTaskPickupLog | null;
  deliveryLog?: DeliveryTaskDeliveryLog | null;
  deliveryInformationLog?: DeliveryInformationLog | null;
}

export interface DeliveryTaskLogActionLogViewObject {
  TypeDiscriminator: string;
  /** @format uuid */
  id: string;
  /** @format uuid */
  entityId: string;
  created: string;
  /** @format uuid */
  createdBy: string;
  description: string;
  logType: EntityActionLogType;
  log?: DeliveryTaskLog | null;
}

export interface DeliveryTaskMarkStepNotified {
  /** @format uuid */
  taskId: string;
  pickup: boolean;
  delivery: boolean;
}

export enum DeliveryTaskOrigin {
  Manual = "Manual",
  Schedule = "Schedule",
  Booking = "Booking",
  Mail = "Mail",
  Import = "Import",
}

export type DeliveryTaskOriginFilter = UtilRequiredKeys<TaskSearchFilter, "TypeDiscriminator"> & {
  origin: DeliveryTaskOrigin;
  TypeDiscriminator: string;
};

export type DeliveryTaskPickupLog = DeliveryTaskStepLog & {
  deliveryDeviationLog?: DeliveryDeviationLog | null;
};

export type DeliveryTaskStatusFilter = UtilRequiredKeys<TaskSearchFilter, "TypeDiscriminator"> & {
  status: DeliveryStatus;
  TypeDiscriminator: string;
};

export interface DeliveryTaskStepInformationViewObject {
  images?: string[] | null;
  information?: string | null;
}

export interface DeliveryTaskStepLog {
  changes?: PropertyChangeEvent[] | null;
  startLocationLog?: GeoLocationLog | null;
  endLocationLog?: GeoLocationLog | null;
  deliveryStepInformationLog?: DeliveryStepInformationLog | null;
}

export interface DeliveryTaskStepOrder {
  /** @format uuid */
  taskId: string;
  /** @format int32 */
  pickupOrder?: number | null;
  pickupNotifyDriver: boolean;
  /** @format int32 */
  deliveryOrder?: number | null;
  deliveryNotifyDriver: boolean;
}

export interface DeliveryTaskStepOrderArrayClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: DeliveryTaskStepOrder[];
}

export interface DeliveryTaskStepSignatureViewObject {
  info?: string | null;
  imageUrl: string;
  created: string;
}

export interface DeliveryTaskUpdateDriverVisibility {
  /** @format uuid */
  taskId: string;
  pickup?: boolean | null;
  delivery?: boolean | null;
}

export interface DeliveryTaskUpdateDriverVisibilityRequest {
  visibilities: DeliveryTaskUpdateDriverVisibility[];
}

export interface DeliveryTaskViewObject {
  /** @format uuid */
  id: string;
  title: string;
  description?: string | null;
  information?: string | null;
  loadType?: string | null;
  origin: DeliveryTaskOrigin;
  status: DeliveryStatus;
  /** @format uuid */
  businessEntityId: string;
  /** @format uuid */
  customerId: string;
  deliveryInformation: CustomerDeliveryInformationViewObject;
  pickup: DeliveryStepPickupViewObject;
  delivery: DeliveryStepDeliveryViewObject;
  created: string;
  /** @format uuid */
  createdBy: string;
  modified?: string | null;
  startDate?: string | null;
  completionDate?: string | null;
  /** @format uuid */
  invoiceId?: string | null;
  createdFromMailId?: string | null;
  /** @format uuid */
  createdFromScheduledTaskId?: string | null;
  locked: boolean;
  isCompleted: boolean;
  isInvoiced: boolean;
  mergeTaskIntoSinglePickupDelivery: boolean;
  deliveryStatistics: DeliveryStatistics;
  lock?: DeliveryTaskEntityLockViewObject | null;

  marked: boolean;
  hovering: boolean;
  dragging: boolean;
  loading: boolean;
  step?: DeliveryStepViewObject;
}

export enum DeliveryType {
  Direct = "Direct",
  NextDay = "NextDay",
}

export type DeliveryTypeEquals = UtilRequiredKeys<DeliveryValidation, "TypeDiscriminator"> & {
  deliveryType: DeliveryType;
  TypeDiscriminator: string;
};

export type DeliveryTypeExtractorMailReaderDataExtractor = MailReaderDataExtractor & {
  fields?: Record<string, DeliveryType>;
  default?: DeliveryType | null;
};

export type DeliveryTypeFilter = UtilRequiredKeys<TaskSearchFilter, "TypeDiscriminator"> & {
  deliveryType: DeliveryType;
  TypeDiscriminator: string;
};

export interface DeliveryTypeNullableFieldWithError {
  data?: DeliveryType | null;
  errors?: string[] | null;
}

export interface DeliveryValidation {
  TypeDiscriminator: string;
  negated: boolean;
}

export type DeliveryValidationAndCondition = UtilRequiredKeys<DeliveryValidation, "TypeDiscriminator"> & {
  validations: (
    | CustomerAddressEquals
    | DeliveryDateTimeOfDay
    | DeliveryItemsCount
    | DeliveryItemsTypeAndCount
    | DeliveryTypeEquals
    | DeliveryValidationAndCondition
    | DeliveryValidationDeliveryStatus
    | DeliveryValidationDeliveryStepStatus
    | DeliveryValidationDeliveryStepType
    | DeliveryValidationHasDeliveryDate
    | DeliveryValidationHasDescription
    | DeliveryValidationHasPickupDate
    | DeliveryValidationOrCondition
    | DeliveryValidationStepHasDescription
    | DeliveryValidationStorageLocation
    | ShipmentPayerEquals
  )[];
  TypeDiscriminator: string;
};

export type DeliveryValidationDeliveryStatus = UtilRequiredKeys<DeliveryValidation, "TypeDiscriminator"> & {
  status: DeliveryStatus;
  TypeDiscriminator: string;
};

export type DeliveryValidationDeliveryStepStatus = UtilRequiredKeys<DeliveryValidation, "TypeDiscriminator"> & {
  status: DeliveryStepStatus;
  TypeDiscriminator: string;
};

export type DeliveryValidationDeliveryStepType = UtilRequiredKeys<DeliveryValidation, "TypeDiscriminator"> & {
  deliveryStepType: DeliveryStepType;
  TypeDiscriminator: string;
};

export type DeliveryValidationHasDeliveryDate = UtilRequiredKeys<DeliveryValidation, "TypeDiscriminator"> & {
  TypeDiscriminator: string;
};

export type DeliveryValidationHasDescription = UtilRequiredKeys<DeliveryValidation, "TypeDiscriminator"> & {
  TypeDiscriminator: string;
};

export type DeliveryValidationHasPickupDate = UtilRequiredKeys<DeliveryValidation, "TypeDiscriminator"> & {
  TypeDiscriminator: string;
};

export type DeliveryValidationOrCondition = UtilRequiredKeys<DeliveryValidation, "TypeDiscriminator"> & {
  validations: (
    | CustomerAddressEquals
    | DeliveryDateTimeOfDay
    | DeliveryItemsCount
    | DeliveryItemsTypeAndCount
    | DeliveryTypeEquals
    | DeliveryValidationAndCondition
    | DeliveryValidationDeliveryStatus
    | DeliveryValidationDeliveryStepStatus
    | DeliveryValidationDeliveryStepType
    | DeliveryValidationHasDeliveryDate
    | DeliveryValidationHasDescription
    | DeliveryValidationHasPickupDate
    | DeliveryValidationOrCondition
    | DeliveryValidationStepHasDescription
    | DeliveryValidationStorageLocation
    | ShipmentPayerEquals
  )[];
  TypeDiscriminator: string;
};

export type DeliveryValidationStepHasDescription = UtilRequiredKeys<DeliveryValidation, "TypeDiscriminator"> & {
  deliveryStepType: DeliveryStepType;
  TypeDiscriminator: string;
};

export type DeliveryValidationStorageLocation = UtilRequiredKeys<DeliveryValidation, "TypeDiscriminator"> & {
  storageLocation: StorageLocation;
  TypeDiscriminator: string;
};

export type DescriptionExtractorMailReaderDataExtractor = MailReaderDataExtractor & {
  mailField?: MailFields | null;
  parsers: MailReaderContentParserWithFields[];
};

export enum DeviationFieldType {
  Hidden = "Hidden",
  Allowed = "Allowed",
  Required = "Required",
}

export type DeviationTypeFilter = UtilRequiredKeys<TaskSearchFilter, "TypeDiscriminator"> & {
  /** @format uuid */
  deviationTypeId: string;
  TypeDiscriminator: string;
};

export interface DictionaryLabelByLanguageViewModel {
  label: DictionaryLabelByLanguagesViewObject;
}

export interface DictionaryLabelByLanguageViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: DictionaryLabelByLanguageViewModel;
}

export interface DictionaryLabelByLanguagesViewObject {
  key: string;
  labels: DictionaryLabelViewObject[];
}

export enum DictionaryLabelContentType {
  Text = "Text",
  HTML = "HTML",
}

export interface DictionaryLabelViewObject {
  key: string;
  text: string;
  area?: string | null;
  languageCode: string;
  lastModified?: string | null;
  /** @format uuid */
  lastModifiedBy?: string | null;
  contentType: DictionaryLabelContentType;
}

export interface DictionaryLabelsByLanguageViewModel {
  labels: DictionaryLabelByLanguagesViewObject[];
}

export interface DictionaryLabelsByLanguageViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: DictionaryLabelsByLanguageViewModel;
}

export interface DictionaryLabelsViewModel {
  labels: DictionaryLabelViewObject[];
}

export interface DictionaryLabelsViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: DictionaryLabelsViewModel;
}

export interface DictionaryLanguageViewModel {
  language: DictionaryLanguageViewObject;
}

export interface DictionaryLanguageViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: DictionaryLanguageViewModel;
}

export interface DictionaryLanguageViewObject {
  languageCode: string;
  dictionaryKey: string;
  isFallback: boolean;
}

export interface DictionaryLanguagesViewModel {
  languages: DictionaryLanguageViewObject[];
}

export interface DictionaryLanguagesViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: DictionaryLanguagesViewModel;
}

export interface DimensionRestriction {
  /** @format double */
  baseValue?: number | null;
  /** @format double */
  minValue?: number | null;
  /** @format double */
  maxValue?: number | null;
  fieldType: DimensionRestrictionFieldType;
}

export enum DimensionRestrictionFieldType {
  Hidden = "Hidden",
  Allowed = "Allowed",
  Required = "Required",
}

export enum DimensionsType {
  Height = "Height",
  Width = "Width",
  Length = "Length",
}

export interface DirectionsRequest {
  start?: DirectionsRequestLocation | null;
  end?: DirectionsRequestLocation | null;
  withGeometry: boolean;
  withInstructions: boolean;
  addresses: DirectionsRequestTaskAddressInfo[];
  /** @format double */
  maximumSpeedKM: number;
}

export interface DirectionsRequestAddress {
  /** @minLength 1 */
  street: string;
  /** @minLength 1 */
  postalCode: string;
  /** @minLength 1 */
  city: string;
  /** @minLength 1 */
  country: string;
}

export interface DirectionsRequestCoordinates {
  /** @format double */
  longitude: number;
  /** @format double */
  latitude: number;
}

export interface DirectionsRequestLocation {
  address?: DirectionsRequestAddress | null;
  coordinates?: DirectionsRequestCoordinates | null;
}

export interface DirectionsRequestTaskAddressInfo {
  /** @format uuid */
  taskId: string;
  deliveryType: DeliveryStepType;
  address: AddressViewObject | ShipmentRequestDeliveryInformation;
}

export interface DriverLogsRequest {
  driverLogIds: string[];
}

export interface DriverTaskOverviewPdfBuilderViewModel {
  builder: DriverTaskOverviewPdfBuilderViewObject;
}

export interface DriverTaskOverviewPdfBuilderViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: DriverTaskOverviewPdfBuilderViewModel;
}

export type DriverTaskOverviewPdfBuilderViewObject = PdfBuilderViewObject & {
  /** @format double */
  paddingBetweenSteps: number;
};

export interface DriverTaskOverviewPdfBuildersViewModel {
  builders: DriverTaskOverviewPdfBuilderViewObject[];
}

export interface DriverTaskOverviewPdfBuildersViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: DriverTaskOverviewPdfBuildersViewModel;
}

export interface DurationViewObject {
  startDateTime?: string | null;
  endDateTime?: string | null;
  /** @format double */
  totalDurationSeconds?: number | null;
}

export type EconomicCombinedProductInvoiceLine = UtilRequiredKeys<EconomicInvoiceLine, "TypeDiscriminator"> & {
  unit: UnitInfo;
  product: ProductInfo;
  /** @format double */
  unitNetPrice: number;
  /** @format double */
  totalNetAmount: number;
  /** @format int32 */
  quantity: number;
  TypeDiscriminator: string;
};

export interface EconomicCreateInvoiceRequest {
  /** @format uuid */
  economicAccessId: string;
  /** @format uuid */
  invoiceId: string;
  /** @format date-time */
  dueDate: string;
  /** @format int32 */
  paymentTermsNumber: number;
  lineItems: (
    | EconomicDescriptionInvoiceLine
    | EconomicProductInvoiceLine
    | EconomicCombinedProductInvoiceLine
    | EconomicFeeInvoiceLine
  )[];
  reference?: string | null;
}

export interface EconomicCustomerGroupViewObject {
  /** @format int32 */
  number: number;
  name: string;
}

export interface EconomicCustomerGroupViewObjectArrayClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: EconomicCustomerGroupViewObject[];
}

export interface EconomicCustomerViewObject {
  /** @format int32 */
  number: number;
  name: string;
  currency: string;
  address?: string | null;
  city?: string | null;
  country?: string | null;
  email?: string | null;
  zip?: string | null;
  cvrNumber?: string | null;
  telephoneAndFaxNumber?: string | null;
  mobilePhone?: string | null;
  /** @format int32 */
  paymentTermsNumber: number;
  /** @format int32 */
  customerGroupNumber: number;
  /** @format int32 */
  vatZoneNumber: number;
  /** @format int32 */
  layoutNumber?: number | null;
}

export interface EconomicCustomerViewObjectClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: EconomicCustomerViewObject;
}

export type EconomicDescriptionInvoiceLine = UtilRequiredKeys<EconomicInvoiceLine, "TypeDiscriminator"> & {
  TypeDiscriminator: string;
};

export type EconomicFeeInvoiceLine = UtilRequiredKeys<EconomicInvoiceLine, "TypeDiscriminator"> & {
  product: ProductInfo;
  /** @format double */
  unitNetPrice: number;
  /** @format double */
  totalNetAmount: number;
  /** @format int32 */
  quantity: number;
  TypeDiscriminator: string;
};

export interface EconomicInvoiceLine {
  TypeDiscriminator: string;
  /** @format int32 */
  lineNumber: number;
  /**
   * @minLength 1
   * @maxLength 2500
   */
  description: string;

  id: string;
  isValid: boolean;
}

export interface EconomicLayoutNumberViewObject {
  /** @format int32 */
  number: number;
  name: string;
}

export interface EconomicLayoutNumberViewObjectArrayClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: EconomicLayoutNumberViewObject[];
}

export interface EconomicPaymentTermViewObject {
  /** @format int32 */
  number: number;
  name: string;
}

export interface EconomicPaymentTermViewObjectArrayClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: EconomicPaymentTermViewObject[];
}

export interface EconomicPaymentTypeViewObject {
  /** @format int32 */
  number: number;
  name: string;
}

export interface EconomicPaymentTypeViewObjectArrayClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: EconomicPaymentTypeViewObject[];
}

export interface EconomicProductGroupViewObject {
  /** @format int32 */
  number: number;
  name: string;
}

export interface EconomicProductGroupViewObjectArrayClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: EconomicProductGroupViewObject[];
}

export type EconomicProductInvoiceLine = UtilRequiredKeys<EconomicInvoiceLine, "TypeDiscriminator"> & {
  unit: UnitInfo;
  product: ProductInfo;
  /** @format double */
  unitNetPrice: number;
  /** @format double */
  totalNetAmount: number;
  /** @format int32 */
  quantity: number;
  TypeDiscriminator: string;
};

export interface EconomicProductViewObject {
  number: string;
  name: string;
  /** @format int32 */
  productGroupNumber: number;
}

export interface EconomicProductViewObjectArrayClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: EconomicProductViewObject[];
}

export interface EconomicProductViewObjectClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: EconomicProductViewObject;
}

export interface EconomicUnitViewObject {
  /** @format int32 */
  number: number;
  name: string;
}

export interface EconomicUnitViewObjectArrayClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: EconomicUnitViewObject[];
}

export interface EconomicUnitViewObjectClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: EconomicUnitViewObject;
}

export interface EconomicVatZoneViewObject {
  /** @format int32 */
  number: number;
  name: string;
}

export interface EconomicVatZoneViewObjectArrayClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: EconomicVatZoneViewObject[];
}

export interface EmployeeViewObject {
  /** @format uuid */
  id: string;
  /** @format uuid */
  createdBy?: string | null;
  created: string;
  modified?: string | null;
  /** @format uuid */
  modifiedBy?: string | null;
  deletedSince?: string | null;
  name: string;
  email: string;
  phone?: string | null;
  whatsAppNumber?: string | null;
  lastName: string;
  fullName: string;
  blocked: boolean;
  isStaff: boolean;
  lastLogin?: string | null;
  lastIp?: string | null;
  profileImageUrl?: string | null;
  allowableIps: string[];
  /** @format int32 */
  identifier: number;
  hideInLiveView: boolean;
  isExternalDriver: boolean;
  homeAddress?: AddressViewObject | ShipmentRequestDeliveryInformation | null;
  whatsAppInstanceId?: string | null;
  whatsAppAccessToken?: string | null;
  role: BusinessEntityUserRole;
  activeConnections?: BusinessEntityHubConnectionViewObject[] | null;
}

export interface EndpointAccessViewObject {
  controller: string;
  action: string;
  group: string;
  description: string;
  minRole: BusinessEntityUserRole;
  modified?: string | null;
  /** @format uuid */
  modifiedBy?: string | null;
}

export interface EndpointAccessViewObjectArrayClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: EndpointAccessViewObject[];
}

export interface EndpointAccessViewObjectClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: EndpointAccessViewObject;
}

export enum EntityActionLogType {
  BusinessEntityUser = "BusinessEntityUser",
  Customer = "Customer",
  DeliveryTask = "DeliveryTask",
  BusinessEntity = "BusinessEntity",
  Resource = "Resource",
  ResourceCategory = "ResourceCategory",
  CustomerUser = "CustomerUser",
  Storage = "Storage",
  CustomerDeliveryPriceConfiguration = "CustomerDeliveryPriceConfiguration",
  CustomerDeliveryFee = "CustomerDeliveryFee",
}

export interface EntityViewObject {
  /** @format uuid */
  id: string;
  /** @format uuid */
  createdBy?: string | null;
  created: string;
  modified?: string | null;
  /** @format uuid */
  modifiedBy?: string | null;
  deletedSince?: string | null;
}

export interface ExportConfigurationColumn {
  /** @format uuid */
  id: string;
  entries: (
    | TextExportConfigurationEntry
    | AggregatedFeeConfigurationEntry
    | TaskPropertyExportConfigurationEntry
    | DeliveryItemPriceExportConfigurationEntry
    | DeliveryItemNameExportConfigurationEntry
    | InvoiceIdExportConfigurationEntry
    | DeliveryItemAmountExportConfigurationEntry
  )[];
}

export interface ExportConfigurationCustomColumn {
  /** @format uuid */
  id: string;
  columns: ExportConfigurationColumn[];
}

export interface ExportConfigurationEntry {
  TypeDiscriminator: string;
  title: string;
  titleOverride?: string | null;
}

export type ExportTasksToCSVRequest = SearchTaskRequest & {
  properties: string[];
};

export interface ExtendedTextMessageData {
  text: string;
}

export interface FallbackSettings {
  senderAddressFallbackToCustomerIfNotSupplied: boolean;
}

export type FeePercentageValue = UtilRequiredKeys<DeliveryFeeValue, "TypeDiscriminator"> & {
  /** @format double */
  percentage: number;
  accumulative: boolean;
  includeServiceFees: boolean;
  TypeDiscriminator: string;
};

export interface FileMessageData {
  downloadUrl: string;
  caption?: string | null;
  fileName: string;
  isAnimated: boolean;
  mimeType: string;
  /** @format int32 */
  forwardingScore: number;
  isForwarded: boolean;
}

export interface GPSDirections {
  routes: Route[];
  summary: Summary;
}

export interface GPSDirectionsClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: GPSDirections;
}

export interface GeoLocation {
  /** @format double */
  accuracy: number;
  /** @format double */
  latitude: number;
  /** @format double */
  longitude: number;
  /** @format double */
  altitude?: number | null;
  /** @format double */
  altitudeAccuracy?: number | null;
  /** @format double */
  heading?: number | null;
  /** @format double */
  speed?: number | null;
}

export interface GeoLocationAddress {
  /** @format double */
  latitude: number;
  /** @format double */
  longitude: number;
  street: string;
  city: string;
  postalCode: string;
  country: string;
  /** @format date-time */
  created: string;
  source: string;
  /** @format int32 */
  id: number;
  formatted: string;
  isExpired: boolean;
}

export interface GeoLocationAddressClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: GeoLocationAddress;
}

export interface GeoLocationLog {
  changes?: PropertyChangeEvent[] | null;
}

export interface GetDriverLogsRequest {
  userIds?: string[] | null;
  resourceIds?: string[] | null;
  /** @format date-time */
  fromDate: string;
  /** @format date-time */
  toDate: string;
  /** @format int32 */
  skip: number;
  /** @format int32 */
  take: number;
}

export interface GlobalActionLogViewModel {
  logs: (
    | ActionLogViewObject1
    | BusinessEntityActionLogViewObject
    | BusinessEntityUserActionLogViewObject
    | CustomerActionLogViewObject
    | CustomerDeliveryFeeActionLogViewObject
    | CustomerDeliveryPriceConfigurationActionLogViewObject
    | CustomerUserActionLogViewObject
    | DeliveryTaskActionLogViewObject
    | ResourceActionLogViewObject
    | ResourceCategoryActionLogViewObject
    | StorageActionLogViewObject
  )[];
  /** @format int32 */
  count: number;
}

export interface GlobalActionLogViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: GlobalActionLogViewModel;
}

export type GlobalLogsRequest = LogsRequest & {
  logTypes?: EntityActionLogType[] | null;
};

export interface GroupChatMemberViewObject {
  /** @format uuid */
  userId: string;
  joinedDate: string;
}

export interface GroupChatMessageViewObject {
  /** @format uuid */
  id: string;
  /** @format uuid */
  chatId: string;
  /** @format uuid */
  senderUserId: string;
  message?: string | null;
  imageUrl?: string | null;
  created: string;
  modified?: string | null;
  readBy: string[];
  whatsAppChatId?: string | null;
}

export interface GroupChatMessageViewObjectClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: GroupChatMessageViewObject;
}

export interface GroupChatMessagesViewModel {
  /** @format int32 */
  total: number;
  messages: GroupChatMessageViewObject[];
}

export interface GroupChatMessagesViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: GroupChatMessagesViewModel;
}

export interface GroupChatViewModel {
  groupChats: GroupChatViewObject[];
}

export interface GroupChatViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: GroupChatViewModel;
}

export interface GroupChatViewObject {
  /** @format uuid */
  id: string;
  title?: string | null;
  created: string;
  updated: string;
  /** @format uuid */
  createdBy?: string | null;
  whatsAppGroupId?: string | null;
  members: GroupChatMemberViewObject[];
  messages: GroupChatMessageViewObject[];
}

export interface GroupChatViewObjectClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: GroupChatViewObject;
}

export interface GuidBooleanDictionaryClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: Record<string, boolean>;
}

export interface GuidDateTimeEntityLockViewObject {
  /** @format uuid */
  userId: string;
  userName: string;
  lockAcquired: string;
  lockReason: string;
  connectionId: string;
}

export interface GuidEntityLockViewObject {
  /** @format uuid */
  entityId: string;
  /** @format uuid */
  userId: string;
  userName: string;
  lockAcquired: string;
  lockReason: string;
  connectionId: string;
}

export interface GuidFieldWithError {
  /** @format uuid */
  data: string;
  errors?: string[] | null;
}

export type HtmlKeyValueTableFieldContentParser = UtilRequiredKeys<MailReaderContentParser, "TypeDiscriminator"> & {
  /** @format int32 */
  tableIndex: number;
  TypeDiscriminator: string;
};

export type HtmlTableContentParser = UtilRequiredKeys<MailReaderContentParser, "TypeDiscriminator"> & {
  /** @format int32 */
  tableIndex: number;
  /** @format int32 */
  headerIndex: number;
  TypeDiscriminator: string;
};

export type HttpValidationProblemDetails = ProblemDetails & {
  errors: Record<string, string[]>;
  [key: string]: any;
};

export interface HubConnectionViewObject {
  /** @format uuid */
  userId: string;
  connectionId: string;
  ipAddress?: string | null;
  channel?: string | null;
  appVersionId?: string | null;
  connected: string;
  tokenVersion: string;
  activity: string;
  /** @format uuid */
  isTypingInChat?: string | null;
  lastActivity: string;
}

export interface ImageUploadResponse {
  url: string;
  name: string;
}

export interface ImageUploadResponseClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: ImageUploadResponse;
}

export interface Int32ClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  /** @format int32 */
  model: number;
}

export type InvoiceIdExportConfigurationEntry = UtilRequiredKeys<ExportConfigurationEntry, "TypeDiscriminator"> & {
  TypeDiscriminator: string;
};

export type InvoiceLockViewObject = GuidEntityLockViewObject;

export interface InvoiceStatisticsDateEntryViewObject {
  /** @format int32 */
  count: number;
  date: string;
  allInvoicesComplete: boolean;
}

export interface InvoiceStatisticsDateEntryViewObjectArrayClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: InvoiceStatisticsDateEntryViewObject[];
}

export enum InvoiceStatus {
  Draft = "Draft",
  Pending = "Pending",
  Approved = "Approved",
  Overdue = "Overdue",
  Rejected = "Rejected",
  Paid = "Paid",
}

export type InvoiceStatusFilter = UtilRequiredKeys<TaskSearchFilter, "TypeDiscriminator"> & {
  status: InvoiceStatus;
  TypeDiscriminator: string;
};

export interface InvoiceViewModel {
  invoice: InvoiceViewObject;
}

export interface InvoiceViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: InvoiceViewModel;
}

export interface InvoiceViewObject {
  /** @format uuid */
  id: string;
  /** @format uuid */
  customerId: string;
  deliveries: DeliveryTaskViewObject[];
  created: string;
  /** @format uuid */
  createdBy?: string | null;
  modified?: string | null;
  /** @format uuid */
  modifiedBy?: string | null;
  currency: string;
  /** @format double */
  invoicedAmount: number;
  dueDate: string;
  overdue: boolean;
  reference?: string | null;
  /** @format uuid */
  economicAccessId?: string | null;
  /** @format int32 */
  economicInvoiceId?: number | null;
  paidDate?: string | null;
  status: InvoiceStatus;
  lock?: InvoiceLockViewObject | null;
  canBeEdited: boolean;
  canBeDeleted: boolean;

  marked: boolean;
  dragging: boolean;
  sort: number;
}

export interface InvoiceViewObjectClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: InvoiceViewObject;
}

export interface InvoicesViewModel {
  invoices: InvoiceViewObject[];
}

export interface InvoicesViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: InvoicesViewModel | PagedInvoicesViewModel;
}

export interface IpInformationViewObject {
  ip: string;
  zip: string;
  city: string;
  country: string;
  countryCode: string;
}

export interface IpInformationViewObjectClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: IpInformationViewObject;
}

export type LineTextFieldContentParser = UtilRequiredKeys<MailReaderContentParser, "TypeDiscriminator"> & {
  separators: string[];
  strategy: ContentParserStrategy;
  sectionFrom?: string | null;
  sectionTo?: string | null;
  includeFieldOnSuccessfulParse: boolean;
  TypeDiscriminator: string;
};

export interface LoginRequest {
  /** @minLength 1 */
  domain: string;
  /** @minLength 1 */
  email: string;
  /** @minLength 8 */
  password: string;
}

export interface LoginResponse {
  token: string;
  user: UserViewObject;
}

export interface LoginResponseClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: LoginResponse | RefreshSessionResponse;
}

export interface LogsRequest {
  userIds?: string[] | null;
  /** @format uuid */
  id?: string | null;
  /** @format date-time */
  startDate?: string | null;
  /** @format date-time */
  endDate?: string | null;
  /** @format int32 */
  skip: number;
  /** @format int32 */
  take: number;
}

export interface MailExtractedDeliveryItem {
  /** @format int32 */
  amount?: number | null;
  /** @format double */
  weightKG?: number | null;
  /** @format double */
  heightCM?: number | null;
  /** @format double */
  lengthCM?: number | null;
  /** @format double */
  widthCM?: number | null;
  description?: string | null;
  reference?: string | null;
  shipmentType?: string | null;
  /** @format double */
  cubicMetre?: number | null;
  /** @format double */
  loadMetre?: number | null;
}

export interface MailExtractedDeliveryItemArrayFieldWithError {
  data?: MailExtractedDeliveryItem[] | null;
  errors?: string[] | null;
}

export enum MailExtractedDeliveryItemFields {
  Amount = "Amount",
  WeightKg = "WeightKg",
  HeightCm = "HeightCm",
  LengthCm = "LengthCm",
  WidthCm = "WidthCm",
  Description = "Description",
  Reference = "Reference",
  PackageType = "PackageType",
}

export interface MailFailedRecord {
  /** @format int32 */
  id: number;
  /** @format uuid */
  businessEntityId: string;
  fromEmail: string;
  fromName: string;
  toEmail: string;
  toName: string;
  template: any;
  error: string;
  /** @format date-time */
  created: string;
  /** @format date-time */
  handled?: string | null;
}

export interface MailFailedRecordArrayClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: MailFailedRecord[];
}

export interface MailFailedRecordClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: MailFailedRecord;
}

export enum MailFields {
  Subject = "Subject",
}

export type MailHandledMailFilter = UtilRequiredKeys<MailSearchFilter, "TypeDiscriminator"> & {
  TypeDiscriminator: string;
};

export interface MailReaderContentParser {
  TypeDiscriminator: string;
}

export type MailReaderContentParserWithFields = MailReaderDataExtractor & {
  parser:
    | ContainsTextFieldContentParser
    | LineTextFieldContentParser
    | HtmlKeyValueTableFieldContentParser
    | HtmlTableContentParser;
  /** @uniqueItems true */
  fields: string[];
};

export type MailReaderDataExtractor = object;

export interface MailSearchFilter {
  TypeDiscriminator: string;
  negated: boolean;
}

export interface MainTaskStatistics {
  /** @format int32 */
  unassigned: number;
  /** @format int32 */
  assigned: number;
  /** @format int32 */
  inProgress: number;
  /** @format int32 */
  complete: number;
  /** @format int32 */
  invoiced: number;
  /** @format int32 */
  total: number;
}

export interface MainTaskStatisticsClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: MainTaskStatistics;
}

export interface MarkChatAsReadByUserRequest {
  /** @format uuid */
  chatId: string;
}

export interface MarkLabelAsMissingRequest {
  /** @minLength 1 */
  key: string;
  /** @minLength 1 */
  language: string;
  /** @minLength 1 */
  location: string;
}

export interface MarkTaskStepsAsNotifiedRequest {
  taskStepMarkers: DeliveryTaskMarkStepNotified[];
}

export interface MessageData {
  textMessageData?: TextMessageData | null;
  extendedTextMessageData?: ExtendedTextMessageData | null;
  fileMessageData?: FileMessageData | null;
}

export enum NotificationAlignment {
  Left = "Left",
  Right = "Right",
  Center = "Center",
}

export enum NotificationPosition {
  Top = "Top",
  Bottom = "Bottom",
  Center = "Center",
}

export enum NotificationTarget {
  Business = "Business",
  Shipping = "Shipping",
  Delivery = "Delivery",
}

export enum NotificationType {
  Success = "Success",
  Info = "Info",
  Warning = "Warning",
  Error = "Error",
}

export interface NotificationViewModel {
  notifications: NotificationViewObject[];
}

export interface NotificationViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: NotificationViewModel;
}

export type NotificationViewObject = EntityViewObject & {
  title: string;
  message?: string | null;
  activeFrom?: string | null;
  activeTo?: string | null;
  target: NotificationTarget;
  type: NotificationType;
  alignment: NotificationAlignment;
  position: NotificationPosition;
  /** @format int32 */
  order: number;
  fullWidth: boolean;
  enabled: boolean;
};

export interface NotificationViewObjectClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: NotificationViewObject;
}

export enum OnCreationAction {
  AssignToDay = "AssignToDay",
  PlaceInUnassigned = "PlaceInUnassigned",
}

export interface OpeningHoursViewObject {
  /** @format double */
  from: number;
  /** @format double */
  to: number;
}

export interface OrderAssignedTaskRequest {
  /** @format uuid */
  scheduleId?: string | null;
  /** @format date-time */
  date?: string | null;
  /** @format uuid */
  taskId: string;
  /** @format int32 */
  order: number;
  notifyDriver: boolean;
}

export interface OrderAssignedTaskStepsRequest {
  /** @format uuid */
  scheduleId?: string | null;
  /** @format date-time */
  date?: string | null;
  taskStepOrder: DeliveryTaskStepOrder;
}

export interface OrderHistoryViewModel {
  orders: CustomerDeliveryInformationViewObject[];
  /** @format int32 */
  total: number;
}

export interface OrderHistoryViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: OrderHistoryViewModel;
}

export interface OutgoingMessageReceivedWebhookData {
  idMessage: string;
  senderData: SenderData;
  messageData: MessageData;
}

export type PagedInvoicesViewModel = InvoicesViewModel & {
  /** @format int32 */
  total: number;
};

export interface PagedInvoicesViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: PagedInvoicesViewModel;
}

export type PagedProjectsViewModel = ProjectsViewModel & {
  /** @format int32 */
  total: number;
};

export interface PagedProjectsViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: PagedProjectsViewModel;
}

export type PagedTasksViewModel = TasksViewModel & {
  /** @format int32 */
  total: number;
};

export interface PagedTasksViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: PagedTasksViewModel;
}

export interface ParsedDeliveryTaskDataFromMailViewObject {
  /** @format uuid */
  configurationId: string;
  customerId: GuidFieldWithError;
  description: StringFieldWithError;
  senderAddress: AddressFieldWithError;
  receiverAddress: AddressFieldWithError;
  billingAddress?: AddressFieldWithError | null;
  deliveryType: DeliveryTypeNullableFieldWithError;
  shipmentPayer: ShipmentPayerNullableFieldWithError;
  deliveryDate?: DateTimeNullableFieldWithError | null;
  pickupDate?: DateTimeNullableFieldWithError | null;
  deliveryItems: MailExtractedDeliveryItemArrayFieldWithError;
}

export enum PdfBuilderPageSize {
  A3 = "A3",
  A4 = "A4",
  A5 = "A5",
  A6 = "A6",
}

export interface PdfBuilderViewObject {
  /** @format uuid */
  id: string;
  title: string;
  description: string;
  pageSize: PdfBuilderPageSize;
  /** @format double */
  padding: number;
  created: string;
  modified?: string | null;
  /** @format uuid */
  modifiedBy: string;
  activeFrom: string;
  activeTo?: string | null;
  isDefault: boolean;
  components: (
    | ShipmentPdfBusinessEntityLogoComponent
    | ShipmentPdfDateComponent
    | ShipmentPdfDeliveryDataComponent
    | ShipmentPdfDeliveryItemsComponent
    | ShipmentPdfImageComponent
    | ShipmentPdfLineComponent
    | ShipmentPdfRectangleComponent
    | ShipmentPdfShipmentIdComponent
    | ShipmentPdfStorageNameComponent
    | ShipmentPdfTextComponent
  )[];
}

export interface PdfComponentPosition {
  /** @format double */
  x: number;
  /** @format double */
  y: number;
  modifiers: PdfComponentPositionModifier[];
  /** @format double */
  width: number;
  /** @format double */
  height: number;
}

export interface PdfComponentPositionModifier {
  conditions: (
    | CustomerAddressEquals
    | DeliveryDateTimeOfDay
    | DeliveryItemsCount
    | DeliveryItemsTypeAndCount
    | DeliveryTypeEquals
    | DeliveryValidationAndCondition
    | DeliveryValidationDeliveryStatus
    | DeliveryValidationDeliveryStepStatus
    | DeliveryValidationDeliveryStepType
    | DeliveryValidationHasDeliveryDate
    | DeliveryValidationHasDescription
    | DeliveryValidationHasPickupDate
    | DeliveryValidationOrCondition
    | DeliveryValidationStepHasDescription
    | DeliveryValidationStorageLocation
    | ShipmentPayerEquals
  )[];
  /** @format double */
  x: number;
  /** @format double */
  y: number;
  title: string;
  /** @format double */
  height: number;
  /** @format double */
  width: number;
}

export interface PdfTextComponentColor {
  /** @format int32 */
  red: number;
  /** @format int32 */
  green: number;
  /** @format int32 */
  blue: number;
}

export enum PdfTextComponentFontStyle {
  Regular = "Regular",
  Bold = "Bold",
  Italic = "Italic",
  Underline = "Underline",
  Strikeout = "Strikeout",
}

export enum PdfTextComponentTextAlignment {
  Left = "Left",
  Right = "Right",
  Center = "Center",
}

export type PickupDateExtractorMailReaderDataExtractor = DateExtractorMailReaderDataExtractor;

export type PickupDateFilter = UtilRequiredKeys<TaskSearchFilter, "TypeDiscriminator"> & {
  /** @format date-time */
  from: string;
  /** @format date-time */
  to: string;
  TypeDiscriminator: string;
};

export interface PostalCodeInformation {
  /** @format int32 */
  number: number;
  name: string;
}

export type PostalCodeRangeFilter = UtilRequiredKeys<TaskSearchFilter, "TypeDiscriminator"> & {
  /** @format int32 */
  senderFrom: number;
  /** @format int32 */
  senderTo: number;
  /** @format int32 */
  receiverFrom: number;
  /** @format int32 */
  receiverTo: number;
  TypeDiscriminator: string;
};

export interface PostalCodeSearchResponse {
  postalCodeInformations: PostalCodeInformation[];
}

export interface PostalCodeSearchResponseClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: PostalCodeSearchResponse;
}

export enum PostalCodeShipmentTypePriceRangeFallbackStrategy {
  None = "None",
  LowestAmount = "LowestAmount",
  HighestAmount = "HighestAmount",
  LowestWeight = "LowestWeight",
  HighestWeight = "HighestWeight",
  LowestCubicMetre = "LowestCubicMetre",
  HighestCubicMetre = "HighestCubicMetre",
  LowestPrice = "LowestPrice",
  HighestPrice = "HighestPrice",
  LowestLoadMetre = "LowestLoadMetre",
  HighestLoadMetre = "HighestLoadMetre",
}

export type PostponeDeliveryDateDeliveryFeeValue = UtilRequiredKeys<DeliveryFeeValue, "TypeDiscriminator"> & {
  /** @format double */
  baseCost: number;
  shipmentTypeCosts: Record<string, number>;
  TypeDiscriminator: string;
};

export interface ProblemDetails {
  type?: string | null;
  title?: string | null;
  /** @format int32 */
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  [key: string]: any;
}

export interface ProductInfo {
  /** @minLength 1 */
  productNumber: string;
}

export type ProjectDateFilter = UtilRequiredKeys<ProjectSearchFilter, "TypeDiscriminator"> & {
  /** @format date-time */
  from: string;
  /** @format date-time */
  to: string;
  TypeDiscriminator: string;
};

export type ProjectEntityLockViewObject = GuidEntityLockViewObject;

export enum ProjectEntryItemType {
  Retrieval = "Retrieval",
  Removal = "Removal",
}

export enum ProjectEntryItemUnit {
  Amount = "Amount",
  Kg = "Kg",
  CubicMetre = "CubicMetre",
}

export interface ProjectEntryItemViewObject {
  /** @format uuid */
  projectEntryId: string;
  /** @format uuid */
  shipmentTypeId: string;
  type: ProjectEntryItemType;
  unit: ProjectEntryItemUnit;
  /** @format double */
  requiredAmount?: number | null;
  /** @format double */
  handledAmount: number;
  isComplete: boolean;
}

export type ProjectEntryViewObject = EntityViewObject & {
  name: string;
  description: string;
  /** @format uuid */
  projectId: string;
  items: ProjectEntryItemViewObject[];
};

export interface ProjectSearchFilter {
  TypeDiscriminator: string;
}

export enum ProjectStatus {
  Draft = "Draft",
  InProgress = "InProgress",
  Complete = "Complete",
}

export type ProjectStatusFilter = UtilRequiredKeys<ProjectSearchFilter, "TypeDiscriminator"> & {
  status: ProjectStatus;
  TypeDiscriminator: string;
};

export type ProjectViewObject = EntityViewObject & {
  name: string;
  description: string;
  /** @format uuid */
  customerId: string;
  address: AddressViewObject | ShipmentRequestDeliveryInformation;
  startDate: string;
  endDate?: string | null;
  status: ProjectStatus;
  isDraft: boolean;
  isInProgress: boolean;
  isComplete: boolean;
  entries: ProjectEntryViewObject[];
  lock?: ProjectEntityLockViewObject | null;
};

export interface ProjectViewObjectClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: ProjectViewObject;
}

export interface ProjectsViewModel {
  projects: ProjectViewObject[];
}

export interface ProjectsViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: ProjectsViewModel | PagedProjectsViewModel;
}

export interface PropertyChangeEvent {
  propertyName: string;
  previous?: any;
  current?: any;
}

export type RefreshSessionResponse = LoginResponse;

export interface RefreshSessionResponseClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: RefreshSessionResponse;
}

export interface RemoveUserFromChatRequest {
  /** @format uuid */
  chatId: string;
  userIds: string[];
}

export interface RenameChatRequest {
  /** @format uuid */
  chatId: string;
  title?: string | null;
}

export interface RentalSettings {
  enabled: boolean;
}

export interface RequestPasswordResetRequest {
  /** @minLength 1 */
  domain: string;
  /** @minLength 1 */
  email: string;
}

export interface ResendCustomerUserWelcomeMailRequest {
  /** @format uuid */
  customerId: string;
  /** @format uuid */
  userId: string;
}

export interface ResetPasswordRequest {
  /** @minLength 1 */
  domain: string;
  /** @format uuid */
  resetPasswordToken: string;
  /** @minLength 1 */
  password: string;
}

export interface ResourceActionLogViewModel {
  logs: ResourceActionLogViewObject[];
  /** @format int32 */
  count: number;
}

export interface ResourceActionLogViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: ResourceActionLogViewModel;
}

export type ResourceActionLogViewObject = ResourceLogActionLogViewObject;

export interface ResourceCategoriesViewModel {
  categories: ResourceCategoryViewObject[];
}

export interface ResourceCategoriesViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: ResourceCategoriesViewModel;
}

export interface ResourceCategoryActionLogViewModel {
  logs: ResourceCategoryActionLogViewObject[];
  /** @format int32 */
  count: number;
}

export interface ResourceCategoryActionLogViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: ResourceCategoryActionLogViewModel;
}

export type ResourceCategoryActionLogViewObject = ResourceCategoryLogActionLogViewObject;

export type ResourceCategoryEntityLockViewObject = GuidEntityLockViewObject;

export interface ResourceCategoryLog {
  changes?: PropertyChangeEvent[] | null;
}

export interface ResourceCategoryLogActionLogViewObject {
  TypeDiscriminator: string;
  /** @format uuid */
  id: string;
  /** @format uuid */
  entityId: string;
  created: string;
  /** @format uuid */
  createdBy: string;
  description: string;
  logType: EntityActionLogType;
  log?: ResourceCategoryLog | null;
}

export interface ResourceCategoryViewModel {
  category: ResourceCategoryViewObject;
}

export interface ResourceCategoryViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: ResourceCategoryViewModel;
}

export type ResourceCategoryViewObject = EntityViewObject & {
  name: string;
  description?: string | null;
  image?: string | null;
  /** @format double */
  maxLoadKg: number;
  /** @format int32 */
  maxEuroPallets: number;
  /** @format double */
  heightCM: number;
  /** @format double */
  widthCM: number;
  /** @format double */
  lengthCM: number;
  /** @format double */
  loadMeter: number;
  /** @format double */
  maximumSpeedKM: number;
  /** @format double */
  co2EmissionsPerKM: number;
  lock?: ResourceCategoryEntityLockViewObject | null;
} & {
  marked: boolean;
  dragging: boolean;
  sort: number;
};

export type ResourceEntityLockViewObject = GuidEntityLockViewObject;

export type ResourceFilter = UtilRequiredKeys<TaskSearchFilter, "TypeDiscriminator"> & {
  resourceIds: string[];
  TypeDiscriminator: string;
};

export interface ResourceKMDateEntryViewObject {
  description: string;
  /** @format double */
  totalKM: number;
  date: string;
  entries: ResourceKMDateEntryViewObject[];
}

export interface ResourceKMDateSeriesEntryViewModel {
  entries: ResourceKMDateSeriesEntryViewObject[];
}

export interface ResourceKMDateSeriesEntryViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: ResourceKMDateSeriesEntryViewModel;
}

export interface ResourceKMDateSeriesEntryViewObject {
  description: string;
  entries: ResourceKMDateEntryViewObject[];
}

export interface ResourceKMStatisticsEntryViewObject {
  /** @format uuid */
  resourceId: string;
  /** @format double */
  km: number;
}

export interface ResourceKMStatisticsViewModel {
  entries: ResourceKMStatisticsEntryViewObject[];
}

export interface ResourceKMStatisticsViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: ResourceKMStatisticsViewModel;
}

export interface ResourceLog {
  changes?: PropertyChangeEvent[] | null;
  addressLog?: AddressLog | null;
}

export interface ResourceLogActionLogViewObject {
  TypeDiscriminator: string;
  /** @format uuid */
  id: string;
  /** @format uuid */
  entityId: string;
  created: string;
  /** @format uuid */
  createdBy: string;
  description: string;
  logType: EntityActionLogType;
  log?: ResourceLog | null;
}

export interface ResourceViewModel {
  resource: ResourceViewObject;
}

export interface ResourceViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: ResourceViewModel;
}

export type ResourceViewObject = EntityViewObject & {
  title: string;
  description?: string | null;
  /** @format int32 */
  identifier: number;
  /** @format double */
  maxLoadKg?: number | null;
  /** @format int32 */
  maxEuroPallets?: number | null;
  /** @format double */
  loadMeter?: number | null;
  /** @format double */
  maximumSpeedKM?: number | null;
  /** @format double */
  co2EmissionsPerKM?: number | null;
  /** @format uuid */
  categoryId: string;
  startAddress?: AddressViewObject | ShipmentRequestDeliveryInformation | null;
  lock?: ResourceEntityLockViewObject | null;
} & {
  marked: boolean;
  dragging: boolean;
  sort: number;
};

export interface ResourcesViewModel {
  resources: ResourceViewObject[];
}

export interface ResourcesViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: ResourcesViewModel;
}

export interface Route {
  summary: Summary;
  geometry?: string | null;
  addressGeoLocations: TaskAddressInfo[];
}

export interface ScheduleEntryDashboardViewEntryLocationViewObject {
  /** @format uuid */
  dashboardViewId: string;
  /** @format uuid */
  dashboardEntryId: string;
}

export enum ScheduleEntryType {
  Schedule = "Schedule",
  Service = "Service",
  Vacant = "Vacant",
  Vacation = "Vacation",
  Sick = "Sick",
  Other = "Other",
}

export interface ScheduleEntryViewObject {
  /** @format uuid */
  id: string;
  type: ScheduleEntryType;
  /** @format uuid */
  resourceId?: string | null;
  /** @format uuid */
  userId?: string | null;
  dashboardEntries?: ScheduleEntryDashboardViewEntryLocationViewObject[] | null;
  created: string;
  /** @format uuid */
  createdBy: string;
  modified?: string | null;
  /** @format uuid */
  modifiedBy?: string | null;
  startDate: string;
  endDate: string;
  description?: string | null;
  color?: string | null;
  isActive: boolean;
  userInformation?: string | null;
  scheduleInformation?: string | null;
  resourceInformation?: string | null;
  /** @format double */
  totalCo2Emissions: number;
  /** @format double */
  totalDistanceInMeters: number;
  /** @format double */
  totalDurationInSeconds: number;
  startAddress?: AddressViewObject | ShipmentRequestDeliveryInformation | null;
  lock?: ScheduleLockViewObject | null;
}

export interface ScheduleEntryViewObjectClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: ScheduleEntryViewObject;
}

export type ScheduleLockViewObject = GuidEntityLockViewObject & {
  /** @format uuid */
  scheduleId: string;
};

export interface ScheduleOrderRequest {
  /** @format uuid */
  scheduleId: string;
  /** @format date-time */
  date: string;
  taskStepOrders: DeliveryTaskStepOrder[];
}

export interface ScheduleRequest {
  scheduleEntryType: ScheduleEntryType;
  /** @format uuid */
  resourceId?: string | null;
  /** @format uuid */
  userId?: string | null;
  dashboardViewEntryLocations?: ScheduleRequestDashboardViewEntryLocation[] | null;
  /** @format date-time */
  from: string;
  /** @format date-time */
  to: string;
  /** @maxLength 2000 */
  description?: string | null;
  /** @maxLength 2000 */
  userInformation?: string | null;
  /** @maxLength 2000 */
  scheduleInformation?: string | null;
  /** @maxLength 2000 */
  resourceInformation?: string | null;
  /** @maxLength 10 */
  color?: string | null;
  startAddress?: AddressViewObject | ShipmentRequestDeliveryInformation | null;
}

export interface ScheduleRequestDashboardViewEntryLocation {
  /** @format uuid */
  dashboardViewId: string;
  /** @format uuid */
  dashboardEntryId: string;
}

export interface ScheduledTaskDeliveryInformationViewObject {
  /** @format uuid */
  id: string;
  /** @format uuid */
  scheduledTaskId: string;
  description?: string | null;
  shipmentPayer: ShipmentPayer;
  deliveryType: DeliveryType;
  senderAddress: AddressViewObject | ShipmentRequestDeliveryInformation;
  receiverAddress: AddressViewObject | ShipmentRequestDeliveryInformation;
  billingAddress?: AddressViewObject | ShipmentRequestDeliveryInformation | null;
  deliveryItems: ScheduledTaskDeliveryItemViewObject[];
  pickupTime?: TimeOfDay | null;
  deliveryTime?: TimeOfDay | null;
}

export interface ScheduledTaskDeliveryItemViewObject {
  /** @format uuid */
  id: string;
  /** @format uuid */
  scheduledTaskDeliveryInformationId: string;
  description?: string | null;
  reference?: string | null;
  /** @format int32 */
  amount: number;
  /** @format double */
  weightKG: number;
  /** @format double */
  heightCM?: number | null;
  /** @format double */
  lengthCM?: number | null;
  /** @format double */
  widthCM?: number | null;
  /** @format double */
  cubicMetre?: number | null;
  /** @format double */
  loadMetre?: number | null;
  /** @format uuid */
  shipmentTypeId: string;
}

export type ScheduledTaskLockViewObject = GuidEntityLockViewObject;

export interface ScheduledTaskRunViewModel {
  /** @format int32 */
  total: number;
  runs: ScheduledTaskRunViewObject[];
}

export interface ScheduledTaskRunViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: ScheduledTaskRunViewModel;
}

export interface ScheduledTaskRunViewObject {
  /** @format uuid */
  id: string;
  /** @format uuid */
  scheduledTaskId: string;
  success: boolean;
  errorMessage?: string | null;
  startDate: string;
  completionDate: string;
  tasks: DeliveryTaskViewObject[];
}

export interface ScheduledTaskViewModel {
  scheduledTasks: ScheduledTaskViewObject[];
}

export interface ScheduledTaskViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: ScheduledTaskViewModel;
}

export interface ScheduledTaskViewObject {
  /** @format uuid */
  id: string;
  /** @format uuid */
  customerId: string;
  title: string;
  description?: string | null;
  start: string;
  end?: string | null;
  /** @format int32 */
  intervalMinutes: number;
  /** @format int32 */
  intervalCount: number;
  nextRun?: string | null;
  lastRun?: string | null;
  allowBookingWeekends: boolean;
  mergeTaskIntoSinglePickupDelivery: boolean;
  onCreationAction: OnCreationAction;
  /** @format uuid */
  dashboardViewId?: string | null;
  /** @format uuid */
  dashboardViewEntryId?: string | null;
  enabled: boolean;
  lastRunFailed: boolean;
  created: string;
  modified?: string | null;
  /** @format uuid */
  modifiedBy?: string | null;
  deliveryInformation: ScheduledTaskDeliveryInformationViewObject;
  lock?: ScheduledTaskLockViewObject | null;

  marked: boolean;
}

export interface ScheduledTaskViewObjectClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: ScheduledTaskViewObject;
}

export interface ScheduledTasksRequest {
  customerIds: string[];
}

export interface SchedulesCanBeOptimizedRequest {
  scheduleEntryIds: string[];
  /** @format date-time */
  date: string;
}

export interface SchedulesEntryViewModel {
  entries: ScheduleEntryViewObject[];
}

export interface SchedulesEntryViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: SchedulesEntryViewModel;
}

export interface SchedulesForTypeRequest {
  type: ScheduleEntryType;
  ids?: string[] | null;
  resourceIds?: string[] | null;
  userIds?: string[] | null;
  /** @format date-time */
  from?: string | null;
  /** @format date-time */
  to?: string | null;
}

export interface SchedulesRequest {
  types?: ScheduleEntryType[] | null;
  ids?: string[] | null;
  resourceIds?: string[] | null;
  userIds?: string[] | null;
  /** @format date-time */
  from?: string | null;
  /** @format date-time */
  to?: string | null;
}

export interface SearchAddressResponse {
  addressId: string;
  address: string;
  street: string;
  city: string;
  /** @format int32 */
  postalCode: number;
}

export interface SearchAddressesResponse {
  addresses: SearchAddressResponse[];
}

export interface SearchAddressesResponseClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: SearchAddressesResponse;
}

export interface SearchEmailsRequest {
  /** @format int32 */
  take?: number | null;
  /** @format int32 */
  skip?: number | null;
  /** @format date-time */
  from?: string | null;
  /** @format date-time */
  to?: string | null;
  term?: string | null;
  filters?: (MailHandledMailFilter | TasksCreatedCountMailFilter)[] | null;
}

export interface SearchInvoicesRequest {
  customerIds: string[];
  taskId?: string | null;
  term?: string | null;
  /** @format int32 */
  skip: number;
  /** @format int32 */
  take: number;
  /** @format date-time */
  start?: string | null;
  /** @format date-time */
  end?: string | null;
  filters: (
    | DeliveryDateFilter
    | DeliveryTaskOriginFilter
    | DeliveryTaskStatusFilter
    | DeliveryTypeFilter
    | DeviationTypeFilter
    | InvoiceStatusFilter
    | PickupDateFilter
    | PostalCodeRangeFilter
    | ResourceFilter
  )[];
}

export interface SearchProjectsRequest {
  customerIds: string[];
  projectId?: string | null;
  term?: string | null;
  /** @format int32 */
  skip: number;
  /** @format int32 */
  take: number;
  /** @format date-time */
  from?: string | null;
  /** @format date-time */
  to?: string | null;
  statuses: ProjectStatus[];
  filters: (ProjectDateFilter | ProjectStatusFilter)[];
}

export interface SearchTaskRequest {
  customerIds: string[];
  customerRecipients?: string[] | null;
  taskId?: string | null;
  term?: string | null;
  /** @format int32 */
  skip: number;
  /** @format int32 */
  take: number;
  /** @format date-time */
  start?: string | null;
  /** @format date-time */
  end?: string | null;
  payerStatuses: ShipmentPayer[];
  statuses: DeliveryStatus[];
  filters: (
    | DeliveryDateFilter
    | DeliveryTaskOriginFilter
    | DeliveryTaskStatusFilter
    | DeliveryTypeFilter
    | DeviationTypeFilter
    | InvoiceStatusFilter
    | PickupDateFilter
    | PostalCodeRangeFilter
    | ResourceFilter
  )[];
}

export interface SendChatMessageRequest {
  /** @format uuid */
  chatId: string;
  message?: string | null;
  imageUrl?: string | null;
}

export interface SendSmsRequest {
  /** @minLength 1 */
  toNumber: string;
  /**
   * @minLength 1
   * @maxLength 1600
   */
  message: string;
  /** @minLength 1 */
  accessToken: string;
}

export interface SendSmsToUserRequest {
  /** @format uuid */
  userId: string;
  /**
   * @minLength 1
   * @maxLength 1600
   */
  message: string;
}

export interface SendTaskScheduleOverviewPdfViaWhatsAppForTasksRequest {
  /** @uniqueItems true */
  taskIds: string[];
  /** @format uuid */
  userId: string;
}

export type SendWhatsAppMessageRequest = WhatsAppRequest & {
  /** @minLength 1 */
  toNumber: string;
  /**
   * @minLength 1
   * @maxLength 5000
   */
  message: string;
};

export interface SenderData {
  chatId: string;
  sender: string;
}

export enum ServiceFeeCalculationEntry {
  BeforeDeliveryFees = "BeforeDeliveryFees",
  AfterDeliveryPrices = "AfterDeliveryPrices",
}

export enum ShipmentPayer {
  Sender = "Sender",
  Receiver = "Receiver",
  Other = "Other",
}

export type ShipmentPayerEquals = UtilRequiredKeys<DeliveryValidation, "TypeDiscriminator"> & {
  shipmentPayer: ShipmentPayer;
  TypeDiscriminator: string;
};

export type ShipmentPayerExtractorMailReaderDataExtractor = MailReaderDataExtractor & {
  fields?: Record<string, ShipmentPayer>;
  default?: ShipmentPayer | null;
};

export interface ShipmentPayerNullableFieldWithError {
  data?: ShipmentPayer | null;
  errors?: string[] | null;
}

export type ShipmentPdfBusinessEntityLogoComponent = UtilRequiredKeys<ShipmentPdfComponent, "TypeDiscriminator"> & {
  TypeDiscriminator: string;
};

export interface ShipmentPdfComponent {
  TypeDiscriminator: string;
  position: PdfComponentPosition;
  /** @format int32 */
  zIndex: number;
  conditions: (
    | CustomerAddressEquals
    | DeliveryDateTimeOfDay
    | DeliveryItemsCount
    | DeliveryItemsTypeAndCount
    | DeliveryTypeEquals
    | DeliveryValidationAndCondition
    | DeliveryValidationDeliveryStatus
    | DeliveryValidationDeliveryStepStatus
    | DeliveryValidationDeliveryStepType
    | DeliveryValidationHasDeliveryDate
    | DeliveryValidationHasDescription
    | DeliveryValidationHasPickupDate
    | DeliveryValidationOrCondition
    | DeliveryValidationStepHasDescription
    | DeliveryValidationStorageLocation
    | ShipmentPayerEquals
  )[];
  active: boolean;
  /** @format uuid */
  key: string;
}

export type ShipmentPdfDateComponent = UtilRequiredKeys<ShipmentPdfComponent, "TypeDiscriminator"> & {
  fontFamily: string;
  /** @format double */
  fontSize: number;
  fontStyle: PdfTextComponentFontStyle;
  alignment: PdfTextComponentTextAlignment;
  color: PdfTextComponentColor;
  uppercase: boolean;
  format?: string | null;
  TypeDiscriminator: string;
};

export type ShipmentPdfDeliveryDataComponent = UtilRequiredKeys<ShipmentPdfComponent, "TypeDiscriminator"> & {
  fontFamily: string;
  /** @format double */
  fontSize: number;
  fontStyle: PdfTextComponentFontStyle;
  alignment: PdfTextComponentTextAlignment;
  color: PdfTextComponentColor;
  uppercase: boolean;
  properties: ShipmentPdfDeliveryDataComponentDataProperty[][];
  delimiter: string;
  TypeDiscriminator: string;
};

export interface ShipmentPdfDeliveryDataComponentDataProperty {
  name: string;
  propertySelector: string;
}

export interface ShipmentPdfDeliveryItemColumn {
  /** @format double */
  width: number;
  fontFamily: string;
  /** @format double */
  fontSize: number;
  fontStyle: PdfTextComponentFontStyle;
  alignment: PdfTextComponentTextAlignment;
  color: PdfTextComponentColor;
  uppercase: boolean;
  displayName: string;
  columnKey: ShipmentPdfDeliveryItemColumnKey;
  columnEntry: ShipmentPdfDeliveryItemColumnEntry;
  fillColor?: PdfTextComponentColor | null;
}

export interface ShipmentPdfDeliveryItemColumnEntry {
  /** @minLength 1 */
  fontFamily: string;
  /** @format double */
  fontSize: number;
  fontStyle: PdfTextComponentFontStyle;
  alignment: PdfTextComponentTextAlignment;
  color: PdfTextComponentColor;
  uppercase: boolean;
  fillColor?: PdfTextComponentColor | null;
}

export enum ShipmentPdfDeliveryItemColumnKey {
  Name = "Name",
  ShortName = "ShortName",
  Height = "Height",
  Width = "Width",
  Length = "Length",
  Weight = "Weight",
  Dimensions = "Dimensions",
  Reference = "Reference",
  Amount = "Amount",
  LDM = "LDM",
  CubicMetre = "CubicMetre",
  LoadMetre = "LoadMetre",
}

export type ShipmentPdfDeliveryItemsComponent = UtilRequiredKeys<ShipmentPdfComponent, "TypeDiscriminator"> & {
  columns: ShipmentPdfDeliveryItemColumn[];
  /** @format double */
  thickness: number;
  borderColor: PdfTextComponentColor;
  TypeDiscriminator: string;
};

export type ShipmentPdfImageComponent = UtilRequiredKeys<ShipmentPdfComponent, "TypeDiscriminator"> & {
  imageUrl: string;
  TypeDiscriminator: string;
};

export type ShipmentPdfLineComponent = UtilRequiredKeys<ShipmentPdfComponent, "TypeDiscriminator"> & {
  position2: PdfComponentPosition;
  /** @format double */
  thickness: number;
  color: PdfTextComponentColor;
  TypeDiscriminator: string;
};

export type ShipmentPdfRectangleComponent = UtilRequiredKeys<ShipmentPdfComponent, "TypeDiscriminator"> & {
  /** @format double */
  thickness: number;
  borderColor: PdfTextComponentColor;
  fillColor?: PdfTextComponentColor | null;
  /** @format int32 */
  rows: number;
  /** @format int32 */
  columns: number;
  TypeDiscriminator: string;
};

export type ShipmentPdfShipmentIdComponent = UtilRequiredKeys<ShipmentPdfComponent, "TypeDiscriminator"> & {
  fontFamily: string;
  /** @format double */
  fontSize: number;
  fontStyle: PdfTextComponentFontStyle;
  alignment: PdfTextComponentTextAlignment;
  color: PdfTextComponentColor;
  uppercase: boolean;
  TypeDiscriminator: string;
};

export type ShipmentPdfStorageNameComponent = UtilRequiredKeys<ShipmentPdfComponent, "TypeDiscriminator"> & {
  fontFamily: string;
  /** @format double */
  fontSize: number;
  fontStyle: PdfTextComponentFontStyle;
  alignment: PdfTextComponentTextAlignment;
  color: PdfTextComponentColor;
  uppercase: boolean;
  TypeDiscriminator: string;
};

export type ShipmentPdfTextComponent = UtilRequiredKeys<ShipmentPdfComponent, "TypeDiscriminator"> & {
  text: string;
  fontFamily: string;
  /** @format double */
  fontSize: number;
  fontStyle: PdfTextComponentFontStyle;
  alignment: PdfTextComponentTextAlignment;
  color: PdfTextComponentColor;
  uppercase: boolean;
  TypeDiscriminator: string;
};

export interface ShipmentRequest {
  /** @format uuid */
  id?: string | null;
  /** @format uuid */
  customerId: string;
  /** @format uuid */
  projectId?: string | null;
  /** @format uuid */
  projectEntryId?: string | null;
  senderAddress: ShipmentRequestDeliveryInformation;
  receiverAddress: ShipmentRequestDeliveryInformation;
  billingAddress?: ShipmentRequestDeliveryInformation | null;
  description?: string | null;
  pickupDescription?: string | null;
  deliveryDescription?: string | null;
  /** @format date-time */
  pickupDate?: string | null;
  /** @format date-time */
  deliveryDate?: string | null;
  shipmentPayer: ShipmentPayer;
  deliveryType: DeliveryType;
  serviceFees: CustomerDeliveryInformationServiceFeeViewObject[];
  deliveryItems: ShipmentRequestDeliveryItem[];
  sendMailsToCustomer: boolean;
  sendMailsToBusinessEntity: boolean;
  createdFromMailId?: string | null;
  mergeTaskIntoSinglePickupDelivery?: boolean | null;
  /** @format double */
  discountPercentage?: number | null;
  recalculateDeliveryPrice?: boolean | null;
  saveToDatabase?: boolean | null;
  duration?: ShipmentRequestDuration | null;
  /** @format double */
  hourPrice?: number | null;
  /** @format double */
  fullLoadPrice?: number | null;
}

export type ShipmentRequestDeliveryInformation = AddressViewObject & {
  /** @format int32 */
  id?: number | null;
  isCustomerAddress: boolean;
};

export interface ShipmentRequestDeliveryItem {
  /** @format uuid */
  id: string;
  /** @format uuid */
  shipmentTypeId: string;
  /** @format int32 */
  amount: number;
  /** @format double */
  cost?: number | null;
  /** @format double */
  weightKG?: number | null;
  /** @format double */
  heightCM?: number | null;
  /** @format double */
  lengthCM?: number | null;
  /** @format double */
  widthCM?: number | null;
  /** @format double */
  cubicMetre?: number | null;
  /** @format double */
  loadMetre?: number | null;
  description?: string | null;
  reference?: string | null;
  customCost: boolean;
}

export interface ShipmentRequestDuration {
  /** @format date-time */
  startDate?: string | null;
  /** @format date-time */
  endDate?: string | null;
}

export interface ShipmentResponse {
  task?: DeliveryTaskViewObject | null;
  deliveryInformation: CustomerDeliveryInformationViewObject;
}

export interface ShipmentResponseClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: ShipmentResponse;
}

export interface ShippingColorSettings {
  /** @minLength 1 */
  buttonColorFrom: string;
  /** @minLength 1 */
  buttonColorTo: string;
}

export interface ShippingSettings {
  deliveryItemsShowCost: boolean;
  shipmentPayerUserVerificationDialog: boolean;
  showDownloadPdfOnOrderSuccessPage: boolean;
  allowBookingWeekends: boolean;
  showDurationMatrix: boolean;
  showTotalCubicMetre: boolean;
  showTotalLoadMetre: boolean;
  allowUpdatingAddressesAsCustomerAddress: boolean;
  /** @format int32 */
  hoursSinceCreationTaskCanBeEdited: number;
  /** @format int32 */
  hoursSinceCreationTaskCanBeDeleted: number;
}

export interface SmsStatisticsDateEntryViewObject {
  /** @format int32 */
  count: number;
  date: string;
}

export interface SmsStatisticsDateSeriesEntryViewModel {
  entries: SmsStatisticsDateSeriesEntryViewObject[];
}

export interface SmsStatisticsDateSeriesEntryViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: SmsStatisticsDateSeriesEntryViewModel;
}

export interface SmsStatisticsDateSeriesEntryViewObject {
  /** @format uuid */
  userId: string;
  entries: SmsStatisticsDateEntryViewObject[];
}

export enum SortOrder {
  Ascending = "Ascending",
  Descending = "Descending",
}

export interface StorageActionLogViewModel {
  logs: StorageActionLogViewObject[];
  /** @format int32 */
  count: number;
}

export interface StorageActionLogViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: StorageActionLogViewModel;
}

export type StorageActionLogViewObject = StorageLogActionLogViewObject;

export enum StorageLocation {
  Storage = "Storage",
  KeepOnTruck = "KeepOnTruck",
}

export interface StorageLog {
  changes?: PropertyChangeEvent[] | null;
  addressLog?: AddressLog | null;
}

export interface StorageLogActionLogViewObject {
  TypeDiscriminator: string;
  /** @format uuid */
  id: string;
  /** @format uuid */
  entityId: string;
  created: string;
  /** @format uuid */
  createdBy: string;
  description: string;
  logType: EntityActionLogType;
  log?: StorageLog | null;
}

export interface StringClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: string;
}

export interface StringEntityLockViewObject {
  entityId: string;
  /** @format uuid */
  userId: string;
  userName: string;
  lockAcquired: string;
  lockReason: string;
  connectionId: string;
}

export interface StringFieldWithError {
  data?: string | null;
  errors?: string[] | null;
}

export interface StringListClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: string[];
}

export interface Summary {
  /** @format double */
  distanceInMeters: number;
  /** @format double */
  durationInSeconds: number;
}

export interface TLog {
  changes?: PropertyChangeEvent[] | null;
}

export interface TaskAddressInfo {
  /** @format uuid */
  taskId: string;
  deliveryType: DeliveryStepType;
  geoLocationAddress: GeoLocationAddress;
  /** @format double */
  latitude: number;
  /** @format double */
  longitude: number;
  /** @format double */
  sharedSegmentDistanceInMeters: number;
  /** @format double */
  actualSegmentDistanceInMeters: number;
  /** @format double */
  sharedSegmentDurationInSeconds: number;
  /** @format double */
  actualSegmentDurationInSeconds: number;
}

export interface TaskImportConfiguration {
  customer: TaskImportConfigurationCustomer;
  shipmentType: TaskImportConfigurationShipmentType;
  properties: Record<string, TaskImportConfigurationProperty>;
}

export interface TaskImportConfigurationCustomer {
  id?: string | null;
  header?: string | null;
}

export interface TaskImportConfigurationProperty {
  manual?: string | null;
  header?: string | null;
  customer?: string | null;
}

export interface TaskImportConfigurationShipmentType {
  shipmentTypeName?: string | null;
  header?: string | null;
}

export interface TaskProgressRequest {
  /** @format uuid */
  taskId: string;
  type: DeliveryStepType;
  location?: GeoLocation | null;
}

export interface TaskProjectAssignment {
  /** @format uuid */
  projectId: string;
  /** @format uuid */
  projectEntryId: string;
}

export type TaskPropertyExportConfigurationEntry = UtilRequiredKeys<ExportConfigurationEntry, "TypeDiscriminator"> & {
  propertySelector: string;
  TypeDiscriminator: string;
};

export interface TaskSearchFilter {
  TypeDiscriminator: string;
}

export enum TaskSortOptions {
  Created = "Created",
  SenderCompany = "SenderCompany",
  ReceiverCompany = "ReceiverCompany",
}

export interface TaskSortOptionsSorting {
  sortBy: TaskSortOptions;
  sortOrder: SortOrder;
}

export interface TaskStatisticsByDeliveryTypeEntryByDateViewModel {
  entries: TaskStatisticsByDeliveryTypeEntryByDateViewObject[];
}

export interface TaskStatisticsByDeliveryTypeEntryByDateViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: TaskStatisticsByDeliveryTypeEntryByDateViewModel;
}

export interface TaskStatisticsByDeliveryTypeEntryByDateViewObject {
  /** @format date-time */
  date: string;
  entries: TaskStatisticsByDeliveryTypeEntryViewObject[];
}

export interface TaskStatisticsByDeliveryTypeEntryViewModel {
  entries: TaskStatisticsByDeliveryTypeEntryViewObject[];
}

export interface TaskStatisticsByDeliveryTypeEntryViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: TaskStatisticsByDeliveryTypeEntryViewModel;
}

export interface TaskStatisticsByDeliveryTypeEntryViewObject {
  deliveryType: DeliveryType;
  /** @format int32 */
  count: number;
}

export interface TaskStatisticsByOriginByDate {
  /** @format date-time */
  date: string;
  entries: TaskStatisticsByOriginEntry[];
}

export interface TaskStatisticsByOriginEntry {
  origin: DeliveryTaskOrigin;
  /** @format int32 */
  count: number;
}

export interface TaskStatisticsByOriginViewModel {
  entries: TaskStatisticsByOriginByDate[];
}

export interface TaskStatisticsByOriginViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: TaskStatisticsByOriginViewModel;
}

export interface TaskStatisticsDateEntryViewObject {
  /** @format int32 */
  count: number;
  date: string;
  allTasksComplete: boolean;
}

export interface TaskStatisticsDateEntryViewObjectArrayClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: TaskStatisticsDateEntryViewObject[];
}

export interface TaskStatisticsDateRangeViewModel {
  start?: string | null;
  end?: string | null;
}

export interface TaskStatisticsDateRangeViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: TaskStatisticsDateRangeViewModel;
}

export interface TaskStatisticsDateSeriesEntryViewModel {
  entries: TaskStatisticsDateSeriesEntryViewObject[];
}

export interface TaskStatisticsDateSeriesEntryViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: TaskStatisticsDateSeriesEntryViewModel;
}

export interface TaskStatisticsDateSeriesEntryViewObject {
  /** @format uuid */
  customerId: string;
  entries: TaskStatisticsDateEntryViewObject[];
}

export interface TaskStatisticsShipmentTypeEntryByDateViewModel {
  entries: TaskStatisticsShipmentTypeEntryByDateViewObject[];
}

export interface TaskStatisticsShipmentTypeEntryByDateViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: TaskStatisticsShipmentTypeEntryByDateViewModel;
}

export interface TaskStatisticsShipmentTypeEntryByDateViewObject {
  /** @format date-time */
  date: string;
  entries: TaskStatisticsShipmentTypeEntryViewObject[];
}

export interface TaskStatisticsShipmentTypeEntryViewModel {
  entries: TaskStatisticsShipmentTypeEntryViewObject[];
}

export interface TaskStatisticsShipmentTypeEntryViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: TaskStatisticsShipmentTypeEntryViewModel;
}

export interface TaskStatisticsShipmentTypeEntryViewObject {
  /** @format int32 */
  count: number;
  shipmentTypeName: string;
}

export interface TaskStepAssignment {
  /** @format uuid */
  scheduleId?: string | null;
  /** @format uuid */
  storageId?: string | null;
  storageLocation?: StorageLocation | null;
  /** @format date-time */
  date?: string | null;
  dashboardEntry?: TaskStepDashboardEntryAssignment | null;
  project?: TaskProjectAssignment | null;
  /** @format int32 */
  order: number;
  description?: string | null;
}

export interface TaskStepDashboardEntryAssignment {
  /** @format uuid */
  dashboardViewId: string;
  /** @format uuid */
  dashboardEntryId?: string | null;
}

export interface TaskViewModel {
  task: DeliveryTaskViewObject;
}

export interface TaskViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: TaskViewModel;
}

export interface TasksAndInvoiceId {
  taskIds: string[];
  /** @format uuid */
  invoiceId?: string | null;
}

export interface TasksByIdRequest {
  taskIds: string[];
  force?: boolean | null;
}

export type TasksCreatedCountMailFilter = UtilRequiredKeys<MailSearchFilter, "TypeDiscriminator"> & {
  /** @format int32 */
  min: number;
  /** @format int32 */
  max: number;
  evaluationScope: TasksCreatedCountMailFilterEvaluationScope;
  TypeDiscriminator: string;
};

export enum TasksCreatedCountMailFilterEvaluationScope {
  LessThan = "LessThan",
  EqualTo = "EqualTo",
  GreaterThan = "GreaterThan",
  Between = "Between",
}

export interface TasksForDriverLogRequest {
  driverLogIds: string[];
}

export interface TasksForSchedulesRequest {
  scheduleEntryIds: string[];
}

export interface TasksViewModel {
  tasks: DeliveryTaskViewObject[];
}

export interface TasksViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: TasksViewModel | PagedTasksViewModel;
}

export type TextExportConfigurationEntry = UtilRequiredKeys<ExportConfigurationEntry, "TypeDiscriminator"> & {
  text: string;
  TypeDiscriminator: string;
};

export interface TextMessageData {
  textMessage: string;
}

export interface TimeOfDay {
  /** @format int32 */
  hour: number;
  /** @format int32 */
  minute: number;
  /** @format int32 */
  second: number;
}

export interface ToggleDashboardViewEntryCheckboxRequest {
  /** @format uuid */
  dashboardViewId: string;
  /** @format uuid */
  checkedId: string;
  /** @format int32 */
  checkboxId: number;
  /** @format date-time */
  date: string;
}

export interface UInt32ClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  /** @format int32 */
  model: number;
}

export interface UnassignedTasksRequest {
  /** @format int32 */
  skip: number;
  /** @format int32 */
  take: number;
  term?: string | null;
  customerIds?: string[] | null;
  sorting?: TaskSortOptionsSorting | null;
}

export interface UnitInfo {
  /** @format int32 */
  unitNumber: number;
}

export interface UpdateBusinessEntityRequest {
  address: UpdateBusinessEntityRequestAddress;
  openingHours: UpdateBusinessEntityRequestOpeningHours;
  settings: BusinessEntitySettingsViewObject;
  deliveryFees: BusinessEntityCustomerDeliveryFee[];
  dashboardRouteAreaAccess: DashboardRouteAreaAccess;
}

export interface UpdateBusinessEntityRequestAddress {
  street: string;
  /** @format int32 */
  postalCode: number;
  /** @minLength 1 */
  city: string;
  /** @minLength 1 */
  country: string;
  /** @minLength 1 */
  companyName: string;
  phone?: string | null;
  email?: string | null;
  cvr?: string | null;
  att?: string | null;
  description?: string | null;
}

export interface UpdateBusinessEntityRequestOpeningHours {
  /** @format double */
  from: number;
  /** @format double */
  to: number;
}

export interface UpdateCustomerAliasesRequest {
  /** @format uuid */
  customerId: string;
  aliases: string[];
}

export type UpdateCustomerRequest = CreateCustomerRequest & {
  /** @format uuid */
  customerId: string;
  shipmentTypeCategories: CustomerShipmentTypeCategory[];
  shipmentTypes: CustomerShipmentType[];
};

export interface UpdateCustomerShipmentTypeAliasesRequest {
  /** @format uuid */
  customerId: string;
  /** @format uuid */
  shipmentTypeId: string;
  aliases: string[];
}

export interface UpdateCustomerShipmentTypeCategoryRequest {
  /** @format uuid */
  customerId: string;
  shipmentTypeCategory: CustomerShipmentTypeCategory;
}

export interface UpdateCustomerShipmentTypeRequest {
  /** @format uuid */
  customerId: string;
  shipmentType: CustomerShipmentType;
}

export type UpdateCustomerUserRequest = CreateCustomerUserRequest & {
  /** @format uuid */
  userId: string;
};

export interface UpdateDictionaryLabelRequest {
  /** @minLength 1 */
  key: string;
  area?: string | null;
  textsByLanguages: UpdateDictionaryLabelRequestTextByLanguage[];
}

export interface UpdateDictionaryLabelRequestTextByLanguage {
  /** @minLength 1 */
  languageCode: string;
  text: string;
  contentType: DictionaryLabelContentType;
}

export interface UpdateEndpointAccessRequest {
  /** @minLength 1 */
  controller: string;
  /** @minLength 1 */
  action: string;
  minRole: BusinessEntityUserRole;
}

export type UpdateResourceCategoryRequest = CreateResourceCategoryRequest & {
  /** @format uuid */
  id: string;
};

export type UpdateResourceRequest = CreateResourceRequest & {
  /** @format uuid */
  id: string;
};

export type UpdateStorageRequest = CreateStorageRequest & {
  /** @format uuid */
  id: string;
};

export interface UpdateTaskDeliveryInformationRequest {
  /** @format uuid */
  id: string;
  shipmentRequest: ShipmentRequest;
}

export type UpdateTaskRequest = UpdateTaskRequestBase & {
  locked: boolean;
  /** @maxLength 250 */
  loadType?: string | null;
  mergeTaskIntoSinglePickupDelivery: boolean;
  useDescriptionForDeliveryDescription?: boolean | null;
};

export interface UpdateTaskRequestBase {
  /** @format uuid */
  id: string;
  /** @minLength 1 */
  title: string;
  /** @maxLength 2000 */
  description?: string | null;
  /** @maxLength 2000 */
  information?: string | null;
}

export type UpdateTaskStepRequest = UpdateTaskRequestBase & {
  type: DeliveryStepType;
};

export type UpdateUserRequest = CreateUserRequest & {
  /** @format uuid */
  userId: string;
};

export interface UserInformationMessageLogViewModel {
  messages: UserInformationMessageLogViewObject[];
}

export interface UserInformationMessageLogViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: UserInformationMessageLogViewModel;
}

export interface UserInformationMessageLogViewObject {
  /** @format int32 */
  id: number;
  /** @format uuid */
  userId: string;
  created: string;
  /** @format uuid */
  createdBy?: string | null;
  modified?: string | null;
  /** @format uuid */
  modifiedBy?: string | null;
  message: string;
  date: string;
}

export interface UserInformationMessageLogViewObjectClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: UserInformationMessageLogViewObject;
}

export interface UserLog {
  changes?: PropertyChangeEvent[] | null;
}

export interface UserLogActionLogViewObject {
  TypeDiscriminator: string;
  /** @format uuid */
  id: string;
  /** @format uuid */
  entityId: string;
  created: string;
  /** @format uuid */
  createdBy: string;
  description: string;
  logType: EntityActionLogType;
  log?: UserLog | null;
}

export interface UserLoginHistoryViewObject {
  /** @format uuid */
  userId: string;
  ip: string;
  date: string;
  logoutDate?: string | null;
  /** @format int32 */
  sessionDuration?: number | null;
  channel?: string | null;
  appVersionId?: string | null;
}

export interface UserSmsHistoryEntryViewObject {
  /** @format int32 */
  id: number;
  /** @format uuid */
  userId: string;
  receivedDate: string;
  content: string;
  /** @format uuid */
  senderId?: string | null;
}

export interface UserSmsHistoryEntryViewObjectArrayClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: UserSmsHistoryEntryViewObject[];
}

export interface UserSmsHistoryEntryViewObjectClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: UserSmsHistoryEntryViewObject;
}

export type UserViewObject = EmployeeViewObject & {
  businessEntity: BusinessEntityViewObject;
};

export interface UsersRequest {
  userIds?: string[] | null;
}

export interface ValidationMessage {
  message: string;
  args?: any[] | null;
}

export type ValueDeliveryFeeValue = UtilRequiredKeys<DeliveryFeeValue, "TypeDiscriminator"> & {
  /** @format double */
  value: number;
  TypeDiscriminator: string;
};

export interface WhatsAppRequest {
  /** @minLength 1 */
  instanceId: string;
  /** @minLength 1 */
  accessToken: string;
}

import type { AxiosInstance, AxiosRequestConfig, AxiosResponse, HeadersDefaults, ResponseType } from "axios";
import axios from "axios";

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, "data" | "cancelToken"> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || "" });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === "object" && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    if (input instanceof FormData) {
      return input;
    }
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === "object") {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== "string") {
      body = JSON.stringify(body);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type ? { "Content-Type": type } : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title Imendo.Business API
 * @version v1
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  api = {
    /**
     * No description
     *
     * @tags Auth
     * @name AuthLoginCreate
     * @request POST:/api/Auth/Login
     * @secure
     */
    authLoginCreate: (data: LoginRequest, params: RequestParams = {}) =>
      this.request<LoginResponseClientResponse, ProblemDetails | HttpValidationProblemDetails>({
        path: `/api/Auth/Login`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthLogoutCreate
     * @request POST:/api/Auth/Logout
     * @secure
     */
    authLogoutCreate: (params: RequestParams = {}) =>
      this.request<BooleanClientResponse, ProblemDetails | HttpValidationProblemDetails>({
        path: `/api/Auth/Logout`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthRefreshSessionList
     * @request GET:/api/Auth/RefreshSession
     * @secure
     */
    authRefreshSessionList: (params: RequestParams = {}) =>
      this.request<RefreshSessionResponseClientResponse, ProblemDetails | HttpValidationProblemDetails>({
        path: `/api/Auth/RefreshSession`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthChangePasswordCreate
     * @request POST:/api/Auth/ChangePassword
     * @secure
     */
    authChangePasswordCreate: (data: ChangePasswordRequest, params: RequestParams = {}) =>
      this.request<RefreshSessionResponseClientResponse, any>({
        path: `/api/Auth/ChangePassword`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthRequestPasswordResetCreate
     * @request POST:/api/Auth/RequestPasswordReset
     * @secure
     */
    authRequestPasswordResetCreate: (data: RequestPasswordResetRequest, params: RequestParams = {}) =>
      this.request<BooleanClientResponse, any>({
        path: `/api/Auth/RequestPasswordReset`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthResetPasswordCreate
     * @request POST:/api/Auth/ResetPassword
     * @secure
     */
    authResetPasswordCreate: (data: ResetPasswordRequest, params: RequestParams = {}) =>
      this.request<BooleanClientResponse, any>({
        path: `/api/Auth/ResetPassword`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AzureAd
     * @name AzureAdGetAzureAdSignInUrlList
     * @request GET:/api/AzureAd/GetAzureAdSignInUrl
     * @secure
     */
    azureAdGetAzureAdSignInUrlList: (params: RequestParams = {}) =>
      this.request<StringClientResponse, any>({
        path: `/api/AzureAd/GetAzureAdSignInUrl`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AzureAd
     * @name AzureAdGetAzureAdSignInUrlCreate
     * @request POST:/api/AzureAd/GetAzureAdSignInUrl
     * @secure
     */
    azureAdGetAzureAdSignInUrlCreate: (params: RequestParams = {}) =>
      this.request<StringClientResponse, any>({
        path: `/api/AzureAd/GetAzureAdSignInUrl`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AzureAd
     * @name AzureAdGetAzureAccessList
     * @request GET:/api/AzureAd/GetAzureAccess
     * @secure
     */
    azureAdGetAzureAccessList: (params: RequestParams = {}) =>
      this.request<BusinessEntityAzureAdAccessViewModelClientResponse, any>({
        path: `/api/AzureAd/GetAzureAccess`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AzureAd
     * @name AzureAdRemoveAzureAccessDelete
     * @request DELETE:/api/AzureAd/RemoveAzureAccess
     * @secure
     */
    azureAdRemoveAzureAccessDelete: (
      query?: {
        azureUserId?: string;
        clearMails?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<BusinessEntityAzureAdAccessViewObjectClientResponse, any>({
        path: `/api/AzureAd/RemoveAzureAccess`,
        method: "DELETE",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AzureAd
     * @name AzureAdEnableAzureAccessCreate
     * @request POST:/api/AzureAd/EnableAzureAccess
     * @secure
     */
    azureAdEnableAzureAccessCreate: (
      query?: {
        azureUserId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<BusinessEntityAzureAdAccessViewObjectClientResponse, any>({
        path: `/api/AzureAd/EnableAzureAccess`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AzureAd
     * @name AzureAdDisableAzureAccessCreate
     * @request POST:/api/AzureAd/DisableAzureAccess
     * @secure
     */
    azureAdDisableAzureAccessCreate: (
      query?: {
        azureUserId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<BusinessEntityAzureAdAccessViewObjectClientResponse, any>({
        path: `/api/AzureAd/DisableAzureAccess`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AzureAd
     * @name AzureAdResetLastMailSyncCreate
     * @request POST:/api/AzureAd/ResetLastMailSync
     * @secure
     */
    azureAdResetLastMailSyncCreate: (
      query?: {
        azureUserId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<BusinessEntityAzureAdAccessViewObjectClientResponse, any>({
        path: `/api/AzureAd/ResetLastMailSync`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AzureAd
     * @name AzureAdDeleteAzureMailReaderConfigurationDelete
     * @request DELETE:/api/AzureAd/DeleteAzureMailReaderConfiguration
     * @secure
     */
    azureAdDeleteAzureMailReaderConfigurationDelete: (
      query: {
        /** @format uuid */
        configurationId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<BusinessEntityMailReaderConfigurationViewObjectClientResponse, any>({
        path: `/api/AzureAd/DeleteAzureMailReaderConfiguration`,
        method: "DELETE",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AzureAd
     * @name AzureAdGetAzureMailReaderConfigurationList
     * @request GET:/api/AzureAd/GetAzureMailReaderConfiguration
     * @secure
     */
    azureAdGetAzureMailReaderConfigurationList: (
      query: {
        /** @format uuid */
        configurationId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<BusinessEntityMailReaderConfigurationViewObjectClientResponse, any>({
        path: `/api/AzureAd/GetAzureMailReaderConfiguration`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AzureAd
     * @name AzureAdGetAzureMailReaderConfigurationsList
     * @request GET:/api/AzureAd/GetAzureMailReaderConfigurations
     * @secure
     */
    azureAdGetAzureMailReaderConfigurationsList: (params: RequestParams = {}) =>
      this.request<BusinessEntityMailReaderConfigurationViewModelClientResponse, any>({
        path: `/api/AzureAd/GetAzureMailReaderConfigurations`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AzureAd
     * @name AzureAdAddOrUpdateAzureMailReaderConfigurationCreate
     * @request POST:/api/AzureAd/AddOrUpdateAzureMailReaderConfiguration
     * @secure
     */
    azureAdAddOrUpdateAzureMailReaderConfigurationCreate: (
      data: AddOrUpdateMailReaderConfigurationRequest,
      params: RequestParams = {},
    ) =>
      this.request<BusinessEntityMailReaderConfigurationViewObjectClientResponse, any>({
        path: `/api/AzureAd/AddOrUpdateAzureMailReaderConfiguration`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AzureAd
     * @name AzureAdGetEmailsForBusinessEntityCreate
     * @request POST:/api/AzureAd/GetEmailsForBusinessEntity
     * @secure
     */
    azureAdGetEmailsForBusinessEntityCreate: (data: SearchEmailsRequest, params: RequestParams = {}) =>
      this.request<BusinessEntityAzureMailViewModelClientResponse, any>({
        path: `/api/AzureAd/GetEmailsForBusinessEntity`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AzureAd
     * @name AzureAdGetMailList
     * @request GET:/api/AzureAd/GetMail
     * @secure
     */
    azureAdGetMailList: (
      query: {
        mailId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<BusinessEntityAzureMailViewObjectClientResponse, any>({
        path: `/api/AzureAd/GetMail`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AzureAd
     * @name AzureAdDeleteMailsDelete
     * @request DELETE:/api/AzureAd/DeleteMails
     * @secure
     */
    azureAdDeleteMailsDelete: (data: AzureAdMailIdsRequest, params: RequestParams = {}) =>
      this.request<BusinessEntityAzureMailsViewModelClientResponse, any>({
        path: `/api/AzureAd/DeleteMails`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AzureAd
     * @name AzureAdMarkMailsAsHandledCreate
     * @request POST:/api/AzureAd/MarkMailsAsHandled
     * @secure
     */
    azureAdMarkMailsAsHandledCreate: (data: AzureAdMailIdsRequest, params: RequestParams = {}) =>
      this.request<BusinessEntityAzureMailsViewModelClientResponse, any>({
        path: `/api/AzureAd/MarkMailsAsHandled`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AzureAd
     * @name AzureAdUseConfigurationForMailCreate
     * @request POST:/api/AzureAd/UseConfigurationForMail
     * @secure
     */
    azureAdUseConfigurationForMailCreate: (
      query: {
        mailId: string;
        /** @format uuid */
        configurationId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<BusinessEntityAzureMailViewObjectClientResponse, any>({
        path: `/api/AzureAd/UseConfigurationForMail`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AzureAd
     * @name AzureAdGetMailRulesList
     * @request GET:/api/AzureAd/GetMailRules
     * @secure
     */
    azureAdGetMailRulesList: (params: RequestParams = {}) =>
      this.request<BusinessEntityAzureMailRuleViewModelClientResponse, any>({
        path: `/api/AzureAd/GetMailRules`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AzureAd
     * @name AzureAdAddOrUpdateAzureMailRuleCreate
     * @request POST:/api/AzureAd/AddOrUpdateAzureMailRule
     * @secure
     */
    azureAdAddOrUpdateAzureMailRuleCreate: (data: AddOrUpdateAzureMailRuleRequest, params: RequestParams = {}) =>
      this.request<BusinessEntityAzureMailRuleViewObjectClientResponse, any>({
        path: `/api/AzureAd/AddOrUpdateAzureMailRule`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AzureAd
     * @name AzureAdDeleteAzureMailRuleDelete
     * @request DELETE:/api/AzureAd/DeleteAzureMailRule
     * @secure
     */
    azureAdDeleteAzureMailRuleDelete: (
      query?: {
        /** @format uuid */
        id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<BusinessEntityAzureMailRuleViewObjectClientResponse, any>({
        path: `/api/AzureAd/DeleteAzureMailRule`,
        method: "DELETE",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BusinessEntity
     * @name BusinessEntityUpdateCreate
     * @request POST:/api/BusinessEntity/Update
     * @secure
     */
    businessEntityUpdateCreate: (data: UpdateBusinessEntityRequest, params: RequestParams = {}) =>
      this.request<BusinessEntityViewModelClientResponse, any>({
        path: `/api/BusinessEntity/Update`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BusinessEntity
     * @name BusinessEntityChangeLogoUpdate
     * @request PUT:/api/BusinessEntity/ChangeLogo
     * @secure
     */
    businessEntityChangeLogoUpdate: (
      data: {
        /** @format binary */
        formFile?: File;
      },
      params: RequestParams = {},
    ) =>
      this.request<BusinessEntityViewModelClientResponse, any>({
        path: `/api/BusinessEntity/ChangeLogo`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BusinessEntity
     * @name BusinessEntityGetTaskGridColumnsList
     * @request GET:/api/BusinessEntity/GetTaskGridColumns
     * @secure
     */
    businessEntityGetTaskGridColumnsList: (params: RequestParams = {}) =>
      this.request<BusinessEntityTaskGridColumnsViewModelClientResponse, any>({
        path: `/api/BusinessEntity/GetTaskGridColumns`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BusinessEntity
     * @name BusinessEntityAddOrUpdateTaskGridColumnCreate
     * @request POST:/api/BusinessEntity/AddOrUpdateTaskGridColumn
     * @secure
     */
    businessEntityAddOrUpdateTaskGridColumnCreate: (
      data: AddOrUpdateTaskGridColumnRequest,
      params: RequestParams = {},
    ) =>
      this.request<BusinessEntityTaskGridColumnViewModelClientResponse, any>({
        path: `/api/BusinessEntity/AddOrUpdateTaskGridColumn`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BusinessEntity
     * @name BusinessEntityAddOrUpdateTaskGridColumnsCreate
     * @request POST:/api/BusinessEntity/AddOrUpdateTaskGridColumns
     * @secure
     */
    businessEntityAddOrUpdateTaskGridColumnsCreate: (
      data: AddOrUpdateTaskGridColumnsRequest,
      params: RequestParams = {},
    ) =>
      this.request<BusinessEntityTaskGridColumnsViewModelClientResponse, any>({
        path: `/api/BusinessEntity/AddOrUpdateTaskGridColumns`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BusinessEntity
     * @name BusinessEntityDeleteTaskGridColumnDelete
     * @request DELETE:/api/BusinessEntity/DeleteTaskGridColumn
     * @secure
     */
    businessEntityDeleteTaskGridColumnDelete: (
      query: {
        /** @format int32 */
        columnId: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<BusinessEntityTaskGridColumnViewModelClientResponse, any>({
        path: `/api/BusinessEntity/DeleteTaskGridColumn`,
        method: "DELETE",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BusinessEntity
     * @name BusinessEntityGetTaskGridColumnLayoutsList
     * @request GET:/api/BusinessEntity/GetTaskGridColumnLayouts
     * @secure
     */
    businessEntityGetTaskGridColumnLayoutsList: (params: RequestParams = {}) =>
      this.request<BusinessEntityTaskGridColumnLayoutsViewModelClientResponse, any>({
        path: `/api/BusinessEntity/GetTaskGridColumnLayouts`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BusinessEntity
     * @name BusinessEntityAddOrUpdateTaskGridColumnLayoutCreate
     * @request POST:/api/BusinessEntity/AddOrUpdateTaskGridColumnLayout
     * @secure
     */
    businessEntityAddOrUpdateTaskGridColumnLayoutCreate: (
      data: AddOrUpdateTaskGridColumnLayoutRequest,
      params: RequestParams = {},
    ) =>
      this.request<BusinessEntityTaskGridColumnLayoutViewModelClientResponse, any>({
        path: `/api/BusinessEntity/AddOrUpdateTaskGridColumnLayout`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BusinessEntity
     * @name BusinessEntityDeleteTaskGridColumnLayoutDelete
     * @request DELETE:/api/BusinessEntity/DeleteTaskGridColumnLayout
     * @secure
     */
    businessEntityDeleteTaskGridColumnLayoutDelete: (
      query: {
        /** @format int32 */
        layoutId: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<BusinessEntityTaskGridColumnLayoutViewModelClientResponse, any>({
        path: `/api/BusinessEntity/DeleteTaskGridColumnLayout`,
        method: "DELETE",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BusinessEntity
     * @name BusinessEntityGetCsvExportConfigurationsList
     * @request GET:/api/BusinessEntity/GetCsvExportConfigurations
     * @secure
     */
    businessEntityGetCsvExportConfigurationsList: (params: RequestParams = {}) =>
      this.request<BusinessEntityCsvExportConfigurationsViewModelClientResponse, any>({
        path: `/api/BusinessEntity/GetCsvExportConfigurations`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BusinessEntity
     * @name BusinessEntityAddOrUpdateCsvExportConfigurationCreate
     * @request POST:/api/BusinessEntity/AddOrUpdateCsvExportConfiguration
     * @secure
     */
    businessEntityAddOrUpdateCsvExportConfigurationCreate: (
      data: AddOrUpdateCsvExportConfigurationRequest,
      params: RequestParams = {},
    ) =>
      this.request<BusinessEntityCsvExportConfigurationViewObjectClientResponse, any>({
        path: `/api/BusinessEntity/AddOrUpdateCsvExportConfiguration`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BusinessEntity
     * @name BusinessEntityDeleteCsvExportConfigurationDelete
     * @request DELETE:/api/BusinessEntity/DeleteCsvExportConfiguration
     * @secure
     */
    businessEntityDeleteCsvExportConfigurationDelete: (
      query: {
        /** @format uuid */
        configurationId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<BusinessEntityCsvExportConfigurationViewObjectClientResponse, any>({
        path: `/api/BusinessEntity/DeleteCsvExportConfiguration`,
        method: "DELETE",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BusinessEntity
     * @name BusinessEntityGetDeliveryTaskImportConfigurationsList
     * @request GET:/api/BusinessEntity/GetDeliveryTaskImportConfigurations
     * @secure
     */
    businessEntityGetDeliveryTaskImportConfigurationsList: (params: RequestParams = {}) =>
      this.request<BusinessEntityDeliveryTaskImportConfigurationsViewModelClientResponse, any>({
        path: `/api/BusinessEntity/GetDeliveryTaskImportConfigurations`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BusinessEntity
     * @name BusinessEntityAddOrUpdateDeliveryTaskImportConfigurationCreate
     * @request POST:/api/BusinessEntity/AddOrUpdateDeliveryTaskImportConfiguration
     * @secure
     */
    businessEntityAddOrUpdateDeliveryTaskImportConfigurationCreate: (
      data: AddOrUpdateDeliveryTaskImportConfigurationRequest,
      params: RequestParams = {},
    ) =>
      this.request<BusinessEntityDeliveryTaskImportConfigurationViewObjectClientResponse, any>({
        path: `/api/BusinessEntity/AddOrUpdateDeliveryTaskImportConfiguration`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BusinessEntity
     * @name BusinessEntityDeleteDeliveryTaskImportConfigurationDelete
     * @request DELETE:/api/BusinessEntity/DeleteDeliveryTaskImportConfiguration
     * @secure
     */
    businessEntityDeleteDeliveryTaskImportConfigurationDelete: (
      query: {
        /** @format uuid */
        configurationId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<BusinessEntityDeliveryTaskImportConfigurationViewObjectClientResponse, any>({
        path: `/api/BusinessEntity/DeleteDeliveryTaskImportConfiguration`,
        method: "DELETE",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BusinessEntity
     * @name BusinessEntityGetShipmentPdfBuildersList
     * @request GET:/api/BusinessEntity/GetShipmentPdfBuilders
     * @secure
     */
    businessEntityGetShipmentPdfBuildersList: (
      query?: {
        /** @format uuid */
        builderId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<BusinessEntityShipmentPdfBuildersViewModelClientResponse, any>({
        path: `/api/BusinessEntity/GetShipmentPdfBuilders`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BusinessEntity
     * @name BusinessEntityGetDriverTaskOverviewPdfBuildersList
     * @request GET:/api/BusinessEntity/GetDriverTaskOverviewPdfBuilders
     * @secure
     */
    businessEntityGetDriverTaskOverviewPdfBuildersList: (
      query?: {
        /** @format uuid */
        builderId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<DriverTaskOverviewPdfBuildersViewModelClientResponse, any>({
        path: `/api/BusinessEntity/GetDriverTaskOverviewPdfBuilders`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BusinessEntity
     * @name BusinessEntityAddOrUpdateShipmentPdfBuilderCreate
     * @request POST:/api/BusinessEntity/AddOrUpdateShipmentPdfBuilder
     * @secure
     */
    businessEntityAddOrUpdateShipmentPdfBuilderCreate: (
      data: AddOrUpdateShipmentPdfBuilderRequest,
      params: RequestParams = {},
    ) =>
      this.request<BusinessEntityShipmentPdfBuilderViewModelClientResponse, any>({
        path: `/api/BusinessEntity/AddOrUpdateShipmentPdfBuilder`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BusinessEntity
     * @name BusinessEntityAddOrUpdateDriverTaskOverviewPdfBuilderCreate
     * @request POST:/api/BusinessEntity/AddOrUpdateDriverTaskOverviewPdfBuilder
     * @secure
     */
    businessEntityAddOrUpdateDriverTaskOverviewPdfBuilderCreate: (
      data: AddOrUpdateDriverTaskOverviewPdfBuilderRequest,
      params: RequestParams = {},
    ) =>
      this.request<DriverTaskOverviewPdfBuilderViewModelClientResponse, any>({
        path: `/api/BusinessEntity/AddOrUpdateDriverTaskOverviewPdfBuilder`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BusinessEntity
     * @name BusinessEntityDeleteShipmentPdfBuilderDelete
     * @request DELETE:/api/BusinessEntity/DeleteShipmentPdfBuilder
     * @secure
     */
    businessEntityDeleteShipmentPdfBuilderDelete: (
      query: {
        /** @format uuid */
        builderId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<BusinessEntityShipmentPdfBuilderViewModelClientResponse, any>({
        path: `/api/BusinessEntity/DeleteShipmentPdfBuilder`,
        method: "DELETE",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BusinessEntity
     * @name BusinessEntityDeleteDriverTaskOverviewPdfBuilderDelete
     * @request DELETE:/api/BusinessEntity/DeleteDriverTaskOverviewPdfBuilder
     * @secure
     */
    businessEntityDeleteDriverTaskOverviewPdfBuilderDelete: (
      query: {
        /** @format uuid */
        builderId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<DriverTaskOverviewPdfBuilderViewModelClientResponse, any>({
        path: `/api/BusinessEntity/DeleteDriverTaskOverviewPdfBuilder`,
        method: "DELETE",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BusinessEntity
     * @name BusinessEntityGetUserDriverLogPreStartChecksList
     * @request GET:/api/BusinessEntity/GetUserDriverLogPreStartChecks
     * @secure
     */
    businessEntityGetUserDriverLogPreStartChecksList: (params: RequestParams = {}) =>
      this.request<BusinessEntityUserDriverLogPreStartCheckViewModelClientResponse, any>({
        path: `/api/BusinessEntity/GetUserDriverLogPreStartChecks`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BusinessEntity
     * @name BusinessEntityAddOrUpdateUserDriverLogPreStartCheckCreate
     * @request POST:/api/BusinessEntity/AddOrUpdateUserDriverLogPreStartCheck
     * @secure
     */
    businessEntityAddOrUpdateUserDriverLogPreStartCheckCreate: (
      data: AddOrUpdateUserDriverLogPreStartCheckRequest,
      params: RequestParams = {},
    ) =>
      this.request<BusinessEntityUserDriverLogPreStartCheckViewObjectClientResponse, any>({
        path: `/api/BusinessEntity/AddOrUpdateUserDriverLogPreStartCheck`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BusinessEntity
     * @name BusinessEntityAddOrUpdateUserDriverLogPreStartCheckOrdersCreate
     * @request POST:/api/BusinessEntity/AddOrUpdateUserDriverLogPreStartCheckOrders
     * @secure
     */
    businessEntityAddOrUpdateUserDriverLogPreStartCheckOrdersCreate: (data: string[], params: RequestParams = {}) =>
      this.request<BusinessEntityUserDriverLogPreStartCheckViewModelClientResponse, any>({
        path: `/api/BusinessEntity/AddOrUpdateUserDriverLogPreStartCheckOrders`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BusinessEntity
     * @name BusinessEntityDeleteUserDriverLogPreStartCheckDelete
     * @request DELETE:/api/BusinessEntity/DeleteUserDriverLogPreStartCheck
     * @secure
     */
    businessEntityDeleteUserDriverLogPreStartCheckDelete: (
      query: {
        /** @format uuid */
        checkId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<BusinessEntityUserDriverLogPreStartCheckViewObjectClientResponse, any>({
        path: `/api/BusinessEntity/DeleteUserDriverLogPreStartCheck`,
        method: "DELETE",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BusinessEntity
     * @name BusinessEntityGetCapacityThresholdsList
     * @request GET:/api/BusinessEntity/GetCapacityThresholds
     * @secure
     */
    businessEntityGetCapacityThresholdsList: (params: RequestParams = {}) =>
      this.request<BusinessEntityDeliveryCapacityThresholdViewModelClientResponse, any>({
        path: `/api/BusinessEntity/GetCapacityThresholds`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BusinessEntity
     * @name BusinessEntityAddOrUpdateDeliveryCapacityThresholdCreate
     * @request POST:/api/BusinessEntity/AddOrUpdateDeliveryCapacityThreshold
     * @secure
     */
    businessEntityAddOrUpdateDeliveryCapacityThresholdCreate: (
      data: AddOrUpdateDeliveryCapacityThresholdRequest,
      params: RequestParams = {},
    ) =>
      this.request<BusinessEntityDeliveryCapacityThresholdViewObjectClientResponse, any>({
        path: `/api/BusinessEntity/AddOrUpdateDeliveryCapacityThreshold`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BusinessEntity
     * @name BusinessEntityDeleteCapacityThresholdDelete
     * @request DELETE:/api/BusinessEntity/DeleteCapacityThreshold
     * @secure
     */
    businessEntityDeleteCapacityThresholdDelete: (
      query: {
        /** @format int32 */
        capacityId: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<BusinessEntityDeliveryCapacityThresholdViewObjectClientResponse, any>({
        path: `/api/BusinessEntity/DeleteCapacityThreshold`,
        method: "DELETE",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BusinessEntity
     * @name BusinessEntityGetDeliveryDeviationTypesList
     * @request GET:/api/BusinessEntity/GetDeliveryDeviationTypes
     * @secure
     */
    businessEntityGetDeliveryDeviationTypesList: (params: RequestParams = {}) =>
      this.request<DeliveryDeviationTypeViewModelClientResponse, any>({
        path: `/api/BusinessEntity/GetDeliveryDeviationTypes`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BusinessEntity
     * @name BusinessEntityAddOrUpdateDeliveryDeviationTypeCreate
     * @request POST:/api/BusinessEntity/AddOrUpdateDeliveryDeviationType
     * @secure
     */
    businessEntityAddOrUpdateDeliveryDeviationTypeCreate: (
      data: AddOrUpdateDeliveryDeviationTypeRequest,
      params: RequestParams = {},
    ) =>
      this.request<DeliveryDeviationTypeViewObjectClientResponse, any>({
        path: `/api/BusinessEntity/AddOrUpdateDeliveryDeviationType`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BusinessEntity
     * @name BusinessEntityDeleteDeliveryDeviationTypeDelete
     * @request DELETE:/api/BusinessEntity/DeleteDeliveryDeviationType
     * @secure
     */
    businessEntityDeleteDeliveryDeviationTypeDelete: (
      query: {
        /** @format uuid */
        deliveryDeviationId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<DeliveryDeviationTypeViewObjectClientResponse, any>({
        path: `/api/BusinessEntity/DeleteDeliveryDeviationType`,
        method: "DELETE",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BusinessEntity
     * @name BusinessEntityGetDriverLogsCreate
     * @request POST:/api/BusinessEntity/GetDriverLogs
     * @secure
     */
    businessEntityGetDriverLogsCreate: (data: GetDriverLogsRequest, params: RequestParams = {}) =>
      this.request<BusinessEntityDriverLogsViewModelClientResponse, any>({
        path: `/api/BusinessEntity/GetDriverLogs`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BusinessEntity
     * @name BusinessEntityGetLocationTrackingList
     * @request GET:/api/BusinessEntity/GetLocationTracking
     * @secure
     */
    businessEntityGetLocationTrackingList: (
      query: {
        /** @format uuid */
        driverLogId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<BusinessEntityUserLocationTrackingLogViewModelClientResponse, any>({
        path: `/api/BusinessEntity/GetLocationTracking`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BusinessEntity
     * @name BusinessEntityGetServerContextList
     * @request GET:/api/BusinessEntity/GetServerContext
     * @secure
     */
    businessEntityGetServerContextList: (params: RequestParams = {}) =>
      this.request<BusinessServerContextViewObjectClientResponse, any>({
        path: `/api/BusinessEntity/GetServerContext`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Communication
     * @name CommunicationSendSmsCreate
     * @request POST:/api/Communication/SendSms
     * @secure
     */
    communicationSendSmsCreate: (data: SendSmsRequest, params: RequestParams = {}) =>
      this.request<BooleanClientResponse, any>({
        path: `/api/Communication/SendSms`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Communication
     * @name CommunicationSendWhatsAppMessageCreate
     * @request POST:/api/Communication/SendWhatsAppMessage
     * @secure
     */
    communicationSendWhatsAppMessageCreate: (data: SendWhatsAppMessageRequest, params: RequestParams = {}) =>
      this.request<BooleanClientResponse, any>({
        path: `/api/Communication/SendWhatsAppMessage`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Communication
     * @name CommunicationSendSmsToUserCreate
     * @request POST:/api/Communication/SendSmsToUser
     * @secure
     */
    communicationSendSmsToUserCreate: (data: SendSmsToUserRequest, params: RequestParams = {}) =>
      this.request<UserSmsHistoryEntryViewObjectClientResponse, any>({
        path: `/api/Communication/SendSmsToUser`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Communication
     * @name CommunicationSendScheduleUpdatedSmsNotificationCreate
     * @request POST:/api/Communication/SendScheduleUpdatedSmsNotification
     * @secure
     */
    communicationSendScheduleUpdatedSmsNotificationCreate: (
      query: {
        /** @format uuid */
        receiverUserId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<UserSmsHistoryEntryViewObjectClientResponse, any>({
        path: `/api/Communication/SendScheduleUpdatedSmsNotification`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Communication
     * @name CommunicationIsWhatsAppInstanceAuthorizedList
     * @request GET:/api/Communication/IsWhatsAppInstanceAuthorized
     * @secure
     */
    communicationIsWhatsAppInstanceAuthorizedList: (params: RequestParams = {}) =>
      this.request<BooleanClientResponse, any>({
        path: `/api/Communication/IsWhatsAppInstanceAuthorized`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Communication
     * @name CommunicationGetWhatsAppAuthorizationCodeList
     * @request GET:/api/Communication/GetWhatsAppAuthorizationCode
     * @secure
     */
    communicationGetWhatsAppAuthorizationCodeList: (params: RequestParams = {}) =>
      this.request<StringClientResponse, any>({
        path: `/api/Communication/GetWhatsAppAuthorizationCode`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Communication
     * @name CommunicationOnWhatsAppMessageSentCreate
     * @request POST:/api/Communication/OnWhatsAppMessageSent
     * @secure
     */
    communicationOnWhatsAppMessageSentCreate: (data: OutgoingMessageReceivedWebhookData, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/Communication/OnWhatsAppMessageSent`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Communication
     * @name CommunicationCreateWhatsAppGroupChatCreate
     * @request POST:/api/Communication/CreateWhatsAppGroupChat
     * @secure
     */
    communicationCreateWhatsAppGroupChatCreate: (data: CreateChatRequest, params: RequestParams = {}) =>
      this.request<GroupChatViewObjectClientResponse, any>({
        path: `/api/Communication/CreateWhatsAppGroupChat`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Communication
     * @name CommunicationSendChatMessageCreate
     * @request POST:/api/Communication/SendChatMessage
     * @secure
     */
    communicationSendChatMessageCreate: (data: SendChatMessageRequest, params: RequestParams = {}) =>
      this.request<GroupChatMessageViewObjectClientResponse, any>({
        path: `/api/Communication/SendChatMessage`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Communication
     * @name CommunicationMarkChatAsReadByUserCreate
     * @request POST:/api/Communication/MarkChatAsReadByUser
     * @secure
     */
    communicationMarkChatAsReadByUserCreate: (data: MarkChatAsReadByUserRequest, params: RequestParams = {}) =>
      this.request<BooleanClientResponse, any>({
        path: `/api/Communication/MarkChatAsReadByUser`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Communication
     * @name CommunicationCreateChatCreate
     * @request POST:/api/Communication/CreateChat
     * @secure
     */
    communicationCreateChatCreate: (data: CreateChatRequest, params: RequestParams = {}) =>
      this.request<GroupChatViewObjectClientResponse, any>({
        path: `/api/Communication/CreateChat`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Communication
     * @name CommunicationDeleteChatCreate
     * @request POST:/api/Communication/DeleteChat
     * @secure
     */
    communicationDeleteChatCreate: (data: DeleteChatRequest, params: RequestParams = {}) =>
      this.request<GroupChatViewObjectClientResponse, any>({
        path: `/api/Communication/DeleteChat`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Communication
     * @name CommunicationRenameChatCreate
     * @request POST:/api/Communication/RenameChat
     * @secure
     */
    communicationRenameChatCreate: (data: RenameChatRequest, params: RequestParams = {}) =>
      this.request<GroupChatViewObjectClientResponse, any>({
        path: `/api/Communication/RenameChat`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Communication
     * @name CommunicationAddUsersToChatCreate
     * @request POST:/api/Communication/AddUsersToChat
     * @secure
     */
    communicationAddUsersToChatCreate: (data: AddUsersToChatRequest, params: RequestParams = {}) =>
      this.request<GroupChatViewObjectClientResponse, any>({
        path: `/api/Communication/AddUsersToChat`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Communication
     * @name CommunicationRemoveUsersFromChatCreate
     * @request POST:/api/Communication/RemoveUsersFromChat
     * @secure
     */
    communicationRemoveUsersFromChatCreate: (data: RemoveUserFromChatRequest, params: RequestParams = {}) =>
      this.request<GroupChatViewObjectClientResponse, any>({
        path: `/api/Communication/RemoveUsersFromChat`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Communication
     * @name CommunicationGetChatList
     * @request GET:/api/Communication/GetChat
     * @secure
     */
    communicationGetChatList: (
      query: {
        /** @format uuid */
        chatId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<GroupChatViewObjectClientResponse, any>({
        path: `/api/Communication/GetChat`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Communication
     * @name CommunicationGetChatsList
     * @request GET:/api/Communication/GetChats
     * @secure
     */
    communicationGetChatsList: (params: RequestParams = {}) =>
      this.request<GroupChatViewModelClientResponse, any>({
        path: `/api/Communication/GetChats`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Communication
     * @name CommunicationGetMessagesForChatList
     * @request GET:/api/Communication/GetMessagesForChat
     * @secure
     */
    communicationGetMessagesForChatList: (
      query: {
        /** @format uuid */
        chatId: string;
        /** @format int32 */
        skip: number;
        /** @format int32 */
        take: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<GroupChatMessagesViewModelClientResponse, any>({
        path: `/api/Communication/GetMessagesForChat`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name CustomerCustomerList
     * @request GET:/api/Customer/Customer
     * @secure
     */
    customerCustomerList: (
      query: {
        /** @format uuid */
        customerId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<CustomerViewModelClientResponse, any>({
        path: `/api/Customer/Customer`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name CustomerCustomersList
     * @request GET:/api/Customer/Customers
     * @secure
     */
    customerCustomersList: (params: RequestParams = {}) =>
      this.request<CustomersViewModelClientResponse, any>({
        path: `/api/Customer/Customers`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name CustomerCreateCustomerCreate
     * @request POST:/api/Customer/CreateCustomer
     * @secure
     */
    customerCreateCustomerCreate: (data: CreateCustomerRequest | UpdateCustomerRequest, params: RequestParams = {}) =>
      this.request<CustomerViewModelClientResponse, any>({
        path: `/api/Customer/CreateCustomer`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name CustomerUpdateCustomerCreate
     * @request POST:/api/Customer/UpdateCustomer
     * @secure
     */
    customerUpdateCustomerCreate: (data: UpdateCustomerRequest, params: RequestParams = {}) =>
      this.request<CustomerViewModelClientResponse, any>({
        path: `/api/Customer/UpdateCustomer`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name CustomerUpdateCustomerAliasesCreate
     * @request POST:/api/Customer/UpdateCustomerAliases
     * @secure
     */
    customerUpdateCustomerAliasesCreate: (data: UpdateCustomerAliasesRequest, params: RequestParams = {}) =>
      this.request<CustomerViewModelClientResponse, any>({
        path: `/api/Customer/UpdateCustomerAliases`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name CustomerAddOrUpdateCustomerShipmentTypeCategoryCreate
     * @request POST:/api/Customer/AddOrUpdateCustomerShipmentTypeCategory
     * @secure
     */
    customerAddOrUpdateCustomerShipmentTypeCategoryCreate: (
      data: UpdateCustomerShipmentTypeCategoryRequest,
      params: RequestParams = {},
    ) =>
      this.request<CustomerShipmentTypeCategoryViewObjectClientResponse, any>({
        path: `/api/Customer/AddOrUpdateCustomerShipmentTypeCategory`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name CustomerAddOrUpdateCustomerShipmentTypeCreate
     * @request POST:/api/Customer/AddOrUpdateCustomerShipmentType
     * @secure
     */
    customerAddOrUpdateCustomerShipmentTypeCreate: (
      data: UpdateCustomerShipmentTypeRequest,
      params: RequestParams = {},
    ) =>
      this.request<CustomerShipmentTypeViewObjectClientResponse, any>({
        path: `/api/Customer/AddOrUpdateCustomerShipmentType`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name CustomerUpdateCustomerShipmentTypeAliasesCreate
     * @request POST:/api/Customer/UpdateCustomerShipmentTypeAliases
     * @secure
     */
    customerUpdateCustomerShipmentTypeAliasesCreate: (
      data: UpdateCustomerShipmentTypeAliasesRequest,
      params: RequestParams = {},
    ) =>
      this.request<CustomerShipmentTypeViewObjectClientResponse, any>({
        path: `/api/Customer/UpdateCustomerShipmentTypeAliases`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name CustomerGetActiveCustomerDeliveryPriceConfigurationList
     * @request GET:/api/Customer/GetActiveCustomerDeliveryPriceConfiguration
     * @secure
     */
    customerGetActiveCustomerDeliveryPriceConfigurationList: (
      query: {
        /** @format uuid */
        customerId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<CustomerDeliveryPriceConfigurationViewObjectClientResponse, any>({
        path: `/api/Customer/GetActiveCustomerDeliveryPriceConfiguration`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name CustomerGetCustomerDeliveryPriceConfigurationList
     * @request GET:/api/Customer/GetCustomerDeliveryPriceConfiguration
     * @secure
     */
    customerGetCustomerDeliveryPriceConfigurationList: (
      query: {
        /** @format uuid */
        customerId: string;
        /** @format uuid */
        deliveryPriceConfigurationId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<CustomerDeliveryPriceConfigurationViewObjectClientResponse, any>({
        path: `/api/Customer/GetCustomerDeliveryPriceConfiguration`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name CustomerGetCustomerDeliveryPriceConfigurationsList
     * @request GET:/api/Customer/GetCustomerDeliveryPriceConfigurations
     * @secure
     */
    customerGetCustomerDeliveryPriceConfigurationsList: (
      query: {
        /** @format uuid */
        customerId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<CustomerDeliveryPriceConfigurationViewModelClientResponse, any>({
        path: `/api/Customer/GetCustomerDeliveryPriceConfigurations`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name CustomerAddOrUpdateCustomerDeliveryPriceConfigurationCreate
     * @request POST:/api/Customer/AddOrUpdateCustomerDeliveryPriceConfiguration
     * @secure
     */
    customerAddOrUpdateCustomerDeliveryPriceConfigurationCreate: (
      data: AddOrUpdateCustomerDeliveryPriceConfigurationRequest,
      params: RequestParams = {},
    ) =>
      this.request<CustomerDeliveryPriceConfigurationViewObjectClientResponse, any>({
        path: `/api/Customer/AddOrUpdateCustomerDeliveryPriceConfiguration`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name CustomerDeleteCustomerDeliveryPriceConfigurationCreate
     * @request POST:/api/Customer/DeleteCustomerDeliveryPriceConfiguration
     * @secure
     */
    customerDeleteCustomerDeliveryPriceConfigurationCreate: (
      query: {
        /** @format uuid */
        customerId: string;
        /** @format uuid */
        deliveryPriceConfigurationId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<CustomerDeliveryPriceConfigurationViewObjectClientResponse, any>({
        path: `/api/Customer/DeleteCustomerDeliveryPriceConfiguration`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name CustomerResetCustomerCreate
     * @request POST:/api/Customer/ResetCustomer
     * @secure
     */
    customerResetCustomerCreate: (
      query: {
        /** @format uuid */
        customerId: string;
        /** @format uuid */
        copyDataFromCustomerId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<CustomerViewModelClientResponse, any>({
        path: `/api/Customer/ResetCustomer`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name CustomerAddOrUpdateDeliveryLocationCreate
     * @request POST:/api/Customer/AddOrUpdateDeliveryLocation
     * @secure
     */
    customerAddOrUpdateDeliveryLocationCreate: (data: AddOrUpdateDeliveryLocationRequest, params: RequestParams = {}) =>
      this.request<CustomerAddressViewObjectClientResponse, any>({
        path: `/api/Customer/AddOrUpdateDeliveryLocation`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name CustomerDeleteDeliveryLocationDelete
     * @request DELETE:/api/Customer/DeleteDeliveryLocation
     * @secure
     */
    customerDeleteDeliveryLocationDelete: (
      query: {
        /** @format uuid */
        customerId: string;
        /** @format int32 */
        addressId: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<BooleanClientResponse, any>({
        path: `/api/Customer/DeleteDeliveryLocation`,
        method: "DELETE",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name CustomerChangeShipmentTypeImageUpdate
     * @request PUT:/api/Customer/ChangeShipmentTypeImage
     * @secure
     */
    customerChangeShipmentTypeImageUpdate: (
      query: {
        /** @format uuid */
        shipmentTypeId: string;
        /** @format uuid */
        customerId: string;
      },
      data: {
        /** @format binary */
        formFile: File;
      },
      params: RequestParams = {},
    ) =>
      this.request<CustomerViewModelClientResponse, any>({
        path: `/api/Customer/ChangeShipmentTypeImage`,
        method: "PUT",
        query: query,
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name CustomerGetUserList
     * @request GET:/api/Customer/GetUser
     * @secure
     */
    customerGetUserList: (
      query: {
        /** @format uuid */
        customerId: string;
        /** @format uuid */
        userId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<CustomerUserViewModelClientResponse, any>({
        path: `/api/Customer/GetUser`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name CustomerGetUsersList
     * @request GET:/api/Customer/GetUsers
     * @secure
     */
    customerGetUsersList: (
      query: {
        /** @format uuid */
        customerId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<CustomerUsersViewModelClientResponse, any>({
        path: `/api/Customer/GetUsers`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name CustomerGetUserCountList
     * @request GET:/api/Customer/GetUserCount
     * @secure
     */
    customerGetUserCountList: (
      query: {
        /** @format uuid */
        customerId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<UInt32ClientResponse, any>({
        path: `/api/Customer/GetUserCount`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name CustomerGetUsersCountCreate
     * @request POST:/api/Customer/GetUsersCount
     * @secure
     */
    customerGetUsersCountCreate: (params: RequestParams = {}) =>
      this.request<CustomerUserCountResponseClientResponse, any>({
        path: `/api/Customer/GetUsersCount`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name CustomerGetDeliveryLocationsList
     * @request GET:/api/Customer/GetDeliveryLocations
     * @secure
     */
    customerGetDeliveryLocationsList: (
      query: {
        /** @format uuid */
        customerId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<CustomerAddressesViewModelClientResponse, any>({
        path: `/api/Customer/GetDeliveryLocations`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name CustomerGetAddressCountList
     * @request GET:/api/Customer/GetAddressCount
     * @secure
     */
    customerGetAddressCountList: (
      query: {
        /** @format uuid */
        customerId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<UInt32ClientResponse, any>({
        path: `/api/Customer/GetAddressCount`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name CustomerGetAddressCountCreate
     * @request POST:/api/Customer/GetAddressCount
     * @secure
     */
    customerGetAddressCountCreate: (params: RequestParams = {}) =>
      this.request<CustomerUserCountResponseClientResponse, any>({
        path: `/api/Customer/GetAddressCount`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name CustomerGetOrderCountCreate
     * @request POST:/api/Customer/GetOrderCount
     * @secure
     */
    customerGetOrderCountCreate: (params: RequestParams = {}) =>
      this.request<CustomerOrderCountResponseClientResponse, any>({
        path: `/api/Customer/GetOrderCount`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name CustomerCreateUserCreate
     * @request POST:/api/Customer/CreateUser
     * @secure
     */
    customerCreateUserCreate: (
      data: CreateCustomerUserRequest | UpdateCustomerUserRequest,
      params: RequestParams = {},
    ) =>
      this.request<CustomerUserViewModelClientResponse, any>({
        path: `/api/Customer/CreateUser`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name CustomerUpdateUserCreate
     * @request POST:/api/Customer/UpdateUser
     * @secure
     */
    customerUpdateUserCreate: (data: UpdateCustomerUserRequest, params: RequestParams = {}) =>
      this.request<CustomerUserViewModelClientResponse, any>({
        path: `/api/Customer/UpdateUser`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name CustomerResetPasswordForUserCreate
     * @request POST:/api/Customer/ResetPasswordForUser
     * @secure
     */
    customerResetPasswordForUserCreate: (
      query: {
        /** @format uuid */
        customerId: string;
        /** @format uuid */
        userId: string;
        password?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<CustomerUserViewModelClientResponse, any>({
        path: `/api/Customer/ResetPasswordForUser`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name CustomerDeleteUserCreate
     * @request POST:/api/Customer/DeleteUser
     * @secure
     */
    customerDeleteUserCreate: (
      query: {
        /** @format uuid */
        customerId: string;
        /** @format uuid */
        userId: string;
        hardDelete?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<CustomerUserViewModelClientResponse, any>({
        path: `/api/Customer/DeleteUser`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name CustomerRestoreUserCreate
     * @request POST:/api/Customer/RestoreUser
     * @secure
     */
    customerRestoreUserCreate: (
      query: {
        /** @format uuid */
        customerId: string;
        /** @format uuid */
        userId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<CustomerUserViewModelClientResponse, any>({
        path: `/api/Customer/RestoreUser`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name CustomerResendWelcomeMailCreate
     * @request POST:/api/Customer/ResendWelcomeMail
     * @secure
     */
    customerResendWelcomeMailCreate: (data: ResendCustomerUserWelcomeMailRequest, params: RequestParams = {}) =>
      this.request<CustomerUserViewModelClientResponse, any>({
        path: `/api/Customer/ResendWelcomeMail`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name CustomerDeleteCustomerCreate
     * @request POST:/api/Customer/DeleteCustomer
     * @secure
     */
    customerDeleteCustomerCreate: (
      query: {
        /** @format uuid */
        customerId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<CustomerViewModelClientResponse, any>({
        path: `/api/Customer/DeleteCustomer`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name CustomerGetUserLoginHistoryCreate
     * @request POST:/api/Customer/GetUserLoginHistory
     * @secure
     */
    customerGetUserLoginHistoryCreate: (
      query: {
        /** @format uuid */
        userId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<CustomerUserLoginHistoryViewModelClientResponse, any>({
        path: `/api/Customer/GetUserLoginHistory`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name CustomerBatchUpdateDeliveryFeesCreate
     * @request POST:/api/Customer/BatchUpdateDeliveryFees
     * @secure
     */
    customerBatchUpdateDeliveryFeesCreate: (data: BatchUpdateDeliveryFeeRequest, params: RequestParams = {}) =>
      this.request<CustomersViewModelClientResponse, any>({
        path: `/api/Customer/BatchUpdateDeliveryFees`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name CustomerCopyCustomerDeliveryPriceConfigurationCreate
     * @request POST:/api/Customer/CopyCustomerDeliveryPriceConfiguration
     * @secure
     */
    customerCopyCustomerDeliveryPriceConfigurationCreate: (
      data: CopyCustomerDeliveryPriceConfigurationRequest,
      params: RequestParams = {},
    ) =>
      this.request<CustomerDeliveryPriceConfigurationViewObjectClientResponse, any>({
        path: `/api/Customer/CopyCustomerDeliveryPriceConfiguration`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name CustomerGetNotesList
     * @request GET:/api/Customer/GetNotes
     * @secure
     */
    customerGetNotesList: (
      query: {
        /** @format uuid */
        customerId: string;
        /** @format int32 */
        skip: number;
        /** @format int32 */
        take: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<CustomerNoteViewModelClientResponse, any>({
        path: `/api/Customer/GetNotes`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name CustomerAddOrUpdateNoteCreate
     * @request POST:/api/Customer/AddOrUpdateNote
     * @secure
     */
    customerAddOrUpdateNoteCreate: (data: AddOrUpdateCustomerNoteRequest, params: RequestParams = {}) =>
      this.request<CustomerNoteViewObjectClientResponse, any>({
        path: `/api/Customer/AddOrUpdateNote`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name CustomerDeleteNoteDelete
     * @request DELETE:/api/Customer/DeleteNote
     * @secure
     */
    customerDeleteNoteDelete: (
      query: {
        /** @format uuid */
        customerId: string;
        /** @format int32 */
        noteId: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<CustomerNoteViewObjectClientResponse, any>({
        path: `/api/Customer/DeleteNote`,
        method: "DELETE",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name CustomerUpdateDeliveryFeesCreate
     * @request POST:/api/Customer/UpdateDeliveryFees
     * @secure
     */
    customerUpdateDeliveryFeesCreate: (data: AddOrUpdateDeliveryFeesRequest, params: RequestParams = {}) =>
      this.request<CustomerDeliveryFeeViewObjectArrayClientResponse, any>({
        path: `/api/Customer/UpdateDeliveryFees`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name CustomerGetAllDeliveryFeesList
     * @request GET:/api/Customer/GetAllDeliveryFees
     * @secure
     */
    customerGetAllDeliveryFeesList: (params: RequestParams = {}) =>
      this.request<CustomerDeliveryFeeViewObjectArrayClientResponse, any>({
        path: `/api/Customer/GetAllDeliveryFees`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name CustomerGetRecipientsForDurationList
     * @request GET:/api/Customer/GetRecipientsForDuration
     * @secure
     */
    customerGetRecipientsForDurationList: (
      query: {
        /** @format uuid */
        customerId: string;
        /** @format date-time */
        startDate: string;
        /** @format date-time */
        endDate: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<StringListClientResponse, any>({
        path: `/api/Customer/GetRecipientsForDuration`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name CustomerGetCustomerShipmentTypesList
     * @request GET:/api/Customer/GetCustomerShipmentTypes
     * @secure
     */
    customerGetCustomerShipmentTypesList: (
      query: {
        /** @format uuid */
        customerId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<CustomerShipmentTypeViewObjectArrayClientResponse, any>({
        path: `/api/Customer/GetCustomerShipmentTypes`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name CustomerGetCustomerShipmentTypesCreate
     * @request POST:/api/Customer/GetCustomerShipmentTypes
     * @secure
     */
    customerGetCustomerShipmentTypesCreate: (data: CustomerShipmentTypesRequest, params: RequestParams = {}) =>
      this.request<CustomerShipmentTypesViewModelClientResponse, any>({
        path: `/api/Customer/GetCustomerShipmentTypes`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name CustomerGetDeliveryFeesList
     * @request GET:/api/Customer/GetDeliveryFees
     * @secure
     */
    customerGetDeliveryFeesList: (
      query: {
        /** @format uuid */
        customerId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<CustomerDeliveryFeeViewObjectArrayClientResponse, any>({
        path: `/api/Customer/GetDeliveryFees`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name CustomerGetDeliveryFeesForCustomersCreate
     * @request POST:/api/Customer/GetDeliveryFeesForCustomers
     * @secure
     */
    customerGetDeliveryFeesForCustomersCreate: (data: CustomerDeliveryFeesRequest, params: RequestParams = {}) =>
      this.request<CustomerDeliveryFeeViewObjectArrayClientResponse, any>({
        path: `/api/Customer/GetDeliveryFeesForCustomers`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name CustomerGetCustomerShipmentTypeCategoriesCreate
     * @request POST:/api/Customer/GetCustomerShipmentTypeCategories
     * @secure
     */
    customerGetCustomerShipmentTypeCategoriesCreate: (
      data: CustomerShipmentTypeCategoriesRequest,
      params: RequestParams = {},
    ) =>
      this.request<CustomerShipmentTypeCategoriesViewModelClientResponse, any>({
        path: `/api/Customer/GetCustomerShipmentTypeCategories`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name CustomerGetCustomerShipmentTypeCategoriesList
     * @request GET:/api/Customer/GetCustomerShipmentTypeCategories
     * @secure
     */
    customerGetCustomerShipmentTypeCategoriesList: (
      query: {
        /** @format uuid */
        customerId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<CustomerShipmentTypeCategoryViewObjectArrayClientResponse, any>({
        path: `/api/Customer/GetCustomerShipmentTypeCategories`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name CustomerGetCustomerDeliveryInformationTemplatesList
     * @request GET:/api/Customer/GetCustomerDeliveryInformationTemplates
     * @secure
     */
    customerGetCustomerDeliveryInformationTemplatesList: (
      query: {
        /** @format uuid */
        customerId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<CustomerDeliveryInformationTemplateViewObjectArrayClientResponse, any>({
        path: `/api/Customer/GetCustomerDeliveryInformationTemplates`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name CustomerAddOrUpdateCustomerDeliveryInformationTemplateCreate
     * @request POST:/api/Customer/AddOrUpdateCustomerDeliveryInformationTemplate
     * @secure
     */
    customerAddOrUpdateCustomerDeliveryInformationTemplateCreate: (
      data: AddOrUpdateCustomerDeliveryInformationTemplateRequest,
      params: RequestParams = {},
    ) =>
      this.request<CustomerDeliveryInformationTemplateViewObjectClientResponse, any>({
        path: `/api/Customer/AddOrUpdateCustomerDeliveryInformationTemplate`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name CustomerDeleteCustomerDeliveryInformationTemplateDelete
     * @request DELETE:/api/Customer/DeleteCustomerDeliveryInformationTemplate
     * @secure
     */
    customerDeleteCustomerDeliveryInformationTemplateDelete: (
      query: {
        /** @format uuid */
        customerId: string;
        /** @format uuid */
        templateId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<CustomerDeliveryInformationTemplateViewObjectClientResponse, any>({
        path: `/api/Customer/DeleteCustomerDeliveryInformationTemplate`,
        method: "DELETE",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dashboard
     * @name DashboardGetDashboardViewsList
     * @request GET:/api/Dashboard/GetDashboardViews
     * @secure
     */
    dashboardGetDashboardViewsList: (params: RequestParams = {}) =>
      this.request<DashboardViewViewModelClientResponse, any>({
        path: `/api/Dashboard/GetDashboardViews`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dashboard
     * @name DashboardGetActiveDashboardViewsList
     * @request GET:/api/Dashboard/GetActiveDashboardViews
     * @secure
     */
    dashboardGetActiveDashboardViewsList: (
      query?: {
        /** @format date-time */
        startDate?: string;
        /** @format date-time */
        endDate?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<DashboardViewViewModelClientResponse, any>({
        path: `/api/Dashboard/GetActiveDashboardViews`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dashboard
     * @name DashboardGetDashboardViewList
     * @request GET:/api/Dashboard/GetDashboardView
     * @secure
     */
    dashboardGetDashboardViewList: (
      query: {
        /** @format uuid */
        viewId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<DashboardViewViewObjectClientResponse, any>({
        path: `/api/Dashboard/GetDashboardView`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dashboard
     * @name DashboardAddOrUpdateDashboardViewCreate
     * @request POST:/api/Dashboard/AddOrUpdateDashboardView
     * @secure
     */
    dashboardAddOrUpdateDashboardViewCreate: (data: AddOrUpdateDashboardViewRequest, params: RequestParams = {}) =>
      this.request<DashboardViewViewObjectClientResponse, any>({
        path: `/api/Dashboard/AddOrUpdateDashboardView`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dashboard
     * @name DashboardDeleteDashboardViewDelete
     * @request DELETE:/api/Dashboard/DeleteDashboardView
     * @secure
     */
    dashboardDeleteDashboardViewDelete: (
      query: {
        /** @format uuid */
        id: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<DashboardViewViewObjectClientResponse, any>({
        path: `/api/Dashboard/DeleteDashboardView`,
        method: "DELETE",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dashboard
     * @name DashboardGetDashboardViewEntryMessagesList
     * @request GET:/api/Dashboard/GetDashboardViewEntryMessages
     * @secure
     */
    dashboardGetDashboardViewEntryMessagesList: (
      query: {
        /** @format uuid */
        dashboardViewId: string;
        /** @format date-time */
        startDate: string;
        /** @format date-time */
        endDate: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<DashboardViewEntryMessageViewObjectArrayClientResponse, any>({
        path: `/api/Dashboard/GetDashboardViewEntryMessages`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dashboard
     * @name DashboardAddOrUpdateDashboardViewEntryMessageCreate
     * @request POST:/api/Dashboard/AddOrUpdateDashboardViewEntryMessage
     * @secure
     */
    dashboardAddOrUpdateDashboardViewEntryMessageCreate: (
      data: AddOrUpdateDashboardViewEntryMessageRequest,
      params: RequestParams = {},
    ) =>
      this.request<DashboardViewEntryMessageViewObjectArrayClientResponse, any>({
        path: `/api/Dashboard/AddOrUpdateDashboardViewEntryMessage`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dashboard
     * @name DashboardUpdateDashboardViewEntryMessageOrderCreate
     * @request POST:/api/Dashboard/UpdateDashboardViewEntryMessageOrder
     * @secure
     */
    dashboardUpdateDashboardViewEntryMessageOrderCreate: (
      query: {
        /** @format uuid */
        dashboardViewEntryId: string;
        /** @format int32 */
        messageId: number;
        /** @format int32 */
        order: number;
        /** @format date-time */
        date: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<DashboardViewEntryMessageViewObjectArrayClientResponse, any>({
        path: `/api/Dashboard/UpdateDashboardViewEntryMessageOrder`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dashboard
     * @name DashboardDeleteDashboardViewEntryMessageDelete
     * @request DELETE:/api/Dashboard/DeleteDashboardViewEntryMessage
     * @secure
     */
    dashboardDeleteDashboardViewEntryMessageDelete: (
      query: {
        /** @format int32 */
        id: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<DashboardViewEntryMessageViewObjectClientResponse, any>({
        path: `/api/Dashboard/DeleteDashboardViewEntryMessage`,
        method: "DELETE",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dashboard
     * @name DashboardGetDashboardViewEntryTaskGridDividersList
     * @request GET:/api/Dashboard/GetDashboardViewEntryTaskGridDividers
     * @secure
     */
    dashboardGetDashboardViewEntryTaskGridDividersList: (
      query: {
        /** @format uuid */
        dashboardViewId: string;
        /** @format date-time */
        startDate: string;
        /** @format date-time */
        endDate: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<DashboardViewEntryTaskGridDividerViewObjectArrayClientResponse, any>({
        path: `/api/Dashboard/GetDashboardViewEntryTaskGridDividers`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dashboard
     * @name DashboardAddOrUpdateDashboardViewEntryTaskGridDividersCreate
     * @request POST:/api/Dashboard/AddOrUpdateDashboardViewEntryTaskGridDividers
     * @secure
     */
    dashboardAddOrUpdateDashboardViewEntryTaskGridDividersCreate: (
      data: AddOrUpdateDashboardViewEntryTaskGridDividerRequest,
      params: RequestParams = {},
    ) =>
      this.request<DashboardViewEntryTaskGridDividerViewObjectClientResponse, any>({
        path: `/api/Dashboard/AddOrUpdateDashboardViewEntryTaskGridDividers`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dashboard
     * @name DashboardDeleteDashboardViewEntryTaskGridDividerDelete
     * @request DELETE:/api/Dashboard/DeleteDashboardViewEntryTaskGridDivider
     * @secure
     */
    dashboardDeleteDashboardViewEntryTaskGridDividerDelete: (
      query: {
        /** @format int32 */
        id: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<DashboardViewEntryTaskGridDividerViewObjectClientResponse, any>({
        path: `/api/Dashboard/DeleteDashboardViewEntryTaskGridDivider`,
        method: "DELETE",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dashboard
     * @name DashboardToggleDashboardViewEntryCheckboxCreate
     * @request POST:/api/Dashboard/ToggleDashboardViewEntryCheckbox
     * @secure
     */
    dashboardToggleDashboardViewEntryCheckboxCreate: (
      data: ToggleDashboardViewEntryCheckboxRequest,
      params: RequestParams = {},
    ) =>
      this.request<DashboardViewEntryCheckboxCheckedEntryViewObjectClientResponse, any>({
        path: `/api/Dashboard/ToggleDashboardViewEntryCheckbox`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dashboard
     * @name DashboardGetDashboardViewEntryCheckboxCheckedEntriesList
     * @request GET:/api/Dashboard/GetDashboardViewEntryCheckboxCheckedEntries
     * @secure
     */
    dashboardGetDashboardViewEntryCheckboxCheckedEntriesList: (
      query: {
        /** @format uuid */
        dashboardViewId: string;
        /** @format date-time */
        startDate: string;
        /** @format date-time */
        endDate: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<DashboardViewEntryCheckboxCheckedEntryViewObjectArrayClientResponse, any>({
        path: `/api/Dashboard/GetDashboardViewEntryCheckboxCheckedEntries`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeliveryDictionary
     * @name DeliveryDictionaryGetSupportedLanguagesList
     * @request GET:/api/DeliveryDictionary/GetSupportedLanguages
     * @secure
     */
    deliveryDictionaryGetSupportedLanguagesList: (params: RequestParams = {}) =>
      this.request<DictionaryLanguagesViewModelClientResponse, any>({
        path: `/api/DeliveryDictionary/GetSupportedLanguages`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeliveryDictionary
     * @name DeliveryDictionaryGetDictionaryLabelsList
     * @request GET:/api/DeliveryDictionary/GetDictionaryLabels
     * @secure
     */
    deliveryDictionaryGetDictionaryLabelsList: (params: RequestParams = {}) =>
      this.request<DictionaryLabelsByLanguageViewModelClientResponse, any>({
        path: `/api/DeliveryDictionary/GetDictionaryLabels`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeliveryDictionary
     * @name DeliveryDictionaryGetDictionaryLabelsForLanguageList
     * @request GET:/api/DeliveryDictionary/GetDictionaryLabelsForLanguage
     * @secure
     */
    deliveryDictionaryGetDictionaryLabelsForLanguageList: (
      query: {
        languageCode: string;
        area?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<DictionaryLabelsViewModelClientResponse, any>({
        path: `/api/DeliveryDictionary/GetDictionaryLabelsForLanguage`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeliveryDictionary
     * @name DeliveryDictionaryGetDictionaryLabelsForAreaList
     * @request GET:/api/DeliveryDictionary/GetDictionaryLabelsForArea
     * @secure
     */
    deliveryDictionaryGetDictionaryLabelsForAreaList: (
      query?: {
        area?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<DictionaryLabelsViewModelClientResponse, any>({
        path: `/api/DeliveryDictionary/GetDictionaryLabelsForArea`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeliveryDictionary
     * @name DeliveryDictionaryAddOrUpdateDictionaryLabelCreate
     * @request POST:/api/DeliveryDictionary/AddOrUpdateDictionaryLabel
     * @secure
     */
    deliveryDictionaryAddOrUpdateDictionaryLabelCreate: (
      data: UpdateDictionaryLabelRequest,
      params: RequestParams = {},
    ) =>
      this.request<DictionaryLabelByLanguageViewModelClientResponse, any>({
        path: `/api/DeliveryDictionary/AddOrUpdateDictionaryLabel`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeliveryDictionary
     * @name DeliveryDictionaryAddOrUpdateDictionaryLanguageCreate
     * @request POST:/api/DeliveryDictionary/AddOrUpdateDictionaryLanguage
     * @secure
     */
    deliveryDictionaryAddOrUpdateDictionaryLanguageCreate: (
      data: AddOrUpdateDictionaryLanguageRequest,
      params: RequestParams = {},
    ) =>
      this.request<DictionaryLanguageViewModelClientResponse, any>({
        path: `/api/DeliveryDictionary/AddOrUpdateDictionaryLanguage`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dictionary
     * @name DictionaryCacheBustList
     * @request GET:/api/Dictionary/CacheBust
     * @secure
     */
    dictionaryCacheBustList: (params: RequestParams = {}) =>
      this.request<BooleanClientResponse, any>({
        path: `/api/Dictionary/CacheBust`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dictionary
     * @name DictionaryCacheBustCreate
     * @request POST:/api/Dictionary/CacheBust
     * @secure
     */
    dictionaryCacheBustCreate: (params: RequestParams = {}) =>
      this.request<BooleanClientResponse, any>({
        path: `/api/Dictionary/CacheBust`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dictionary
     * @name DictionaryGetSupportedLanguagesList
     * @request GET:/api/Dictionary/GetSupportedLanguages
     * @secure
     */
    dictionaryGetSupportedLanguagesList: (params: RequestParams = {}) =>
      this.request<DictionaryLanguagesViewModelClientResponse, any>({
        path: `/api/Dictionary/GetSupportedLanguages`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dictionary
     * @name DictionaryGetDictionaryLabelsForAreaList
     * @request GET:/api/Dictionary/GetDictionaryLabelsForArea
     * @secure
     */
    dictionaryGetDictionaryLabelsForAreaList: (
      query?: {
        area?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<DictionaryLabelsViewModelClientResponse, any>({
        path: `/api/Dictionary/GetDictionaryLabelsForArea`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dictionary
     * @name DictionaryAddOrUpdateDictionaryLabelCreate
     * @request POST:/api/Dictionary/AddOrUpdateDictionaryLabel
     * @secure
     */
    dictionaryAddOrUpdateDictionaryLabelCreate: (data: UpdateDictionaryLabelRequest, params: RequestParams = {}) =>
      this.request<DictionaryLabelByLanguageViewModelClientResponse, any>({
        path: `/api/Dictionary/AddOrUpdateDictionaryLabel`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dictionary
     * @name DictionaryAddOrUpdateDictionaryLanguageCreate
     * @request POST:/api/Dictionary/AddOrUpdateDictionaryLanguage
     * @secure
     */
    dictionaryAddOrUpdateDictionaryLanguageCreate: (
      data: AddOrUpdateDictionaryLanguageRequest,
      params: RequestParams = {},
    ) =>
      this.request<DictionaryLanguageViewModelClientResponse, any>({
        path: `/api/Dictionary/AddOrUpdateDictionaryLanguage`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dictionary
     * @name DictionaryMarkLabelAsMissingCreate
     * @request POST:/api/Dictionary/MarkLabelAsMissing
     * @secure
     */
    dictionaryMarkLabelAsMissingCreate: (data: MarkLabelAsMissingRequest, params: RequestParams = {}) =>
      this.request<BooleanClientResponse, any>({
        path: `/api/Dictionary/MarkLabelAsMissing`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Economic
     * @name EconomicGetAppInstallationUrlList
     * @request GET:/api/Economic/GetAppInstallationUrl
     * @secure
     */
    economicGetAppInstallationUrlList: (params: RequestParams = {}) =>
      this.request<StringClientResponse, any>({
        path: `/api/Economic/GetAppInstallationUrl`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Economic
     * @name EconomicGetEconomicAccessList
     * @request GET:/api/Economic/GetEconomicAccess
     * @secure
     */
    economicGetEconomicAccessList: (params: RequestParams = {}) =>
      this.request<BusinessEntityEconomicAccessViewModelClientResponse, any>({
        path: `/api/Economic/GetEconomicAccess`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Economic
     * @name EconomicGetEconomicAccessDetailsList
     * @request GET:/api/Economic/GetEconomicAccessDetails
     * @secure
     */
    economicGetEconomicAccessDetailsList: (
      query: {
        /** @format uuid */
        economicAccessId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<BusinessEntityEconomicAccessDetailsViewObjectClientResponse, any>({
        path: `/api/Economic/GetEconomicAccessDetails`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Economic
     * @name EconomicRemoveEconomicAccessDelete
     * @request DELETE:/api/Economic/RemoveEconomicAccess
     * @secure
     */
    economicRemoveEconomicAccessDelete: (
      query: {
        /** @format uuid */
        economicAccessId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<BusinessEntityEconomicAccessViewObjectClientResponse, any>({
        path: `/api/Economic/RemoveEconomicAccess`,
        method: "DELETE",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Economic
     * @name EconomicEnableEconomicAccessCreate
     * @request POST:/api/Economic/EnableEconomicAccess
     * @secure
     */
    economicEnableEconomicAccessCreate: (
      query: {
        /** @format uuid */
        economicAccessId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<BusinessEntityEconomicAccessViewObjectClientResponse, any>({
        path: `/api/Economic/EnableEconomicAccess`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Economic
     * @name EconomicDisableEconomicAccessCreate
     * @request POST:/api/Economic/DisableEconomicAccess
     * @secure
     */
    economicDisableEconomicAccessCreate: (
      query: {
        /** @format uuid */
        economicAccessId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<BusinessEntityEconomicAccessViewObjectClientResponse, any>({
        path: `/api/Economic/DisableEconomicAccess`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Economic
     * @name EconomicGetCustomerList
     * @request GET:/api/Economic/GetCustomer
     * @secure
     */
    economicGetCustomerList: (
      query: {
        /** @format uuid */
        economicAccessId: string;
        /** @format uuid */
        customerId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<EconomicCustomerViewObjectClientResponse, any>({
        path: `/api/Economic/GetCustomer`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Economic
     * @name EconomicSearchCustomerList
     * @request GET:/api/Economic/SearchCustomer
     * @secure
     */
    economicSearchCustomerList: (
      query: {
        /** @format uuid */
        economicAccessId: string;
        /** @format uuid */
        customerId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<EconomicCustomerViewObjectClientResponse, any>({
        path: `/api/Economic/SearchCustomer`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Economic
     * @name EconomicSyncCustomerToEconomicCreate
     * @request POST:/api/Economic/SyncCustomerToEconomic
     * @secure
     */
    economicSyncCustomerToEconomicCreate: (
      query: {
        /** @format uuid */
        economicAccessId: string;
        /** @format uuid */
        customerId: string;
        /** @format int32 */
        customerGroupNumber: number;
        /** @format int32 */
        paymentTermsNumber: number;
        /** @format int32 */
        layoutNumber: number;
        /** @format int32 */
        vatZoneNumber: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<EconomicCustomerViewObjectClientResponse, any>({
        path: `/api/Economic/SyncCustomerToEconomic`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Economic
     * @name EconomicSyncCustomerFromEconomicCreate
     * @request POST:/api/Economic/SyncCustomerFromEconomic
     * @secure
     */
    economicSyncCustomerFromEconomicCreate: (
      query: {
        /** @format uuid */
        economicAccessId: string;
        /** @format uuid */
        customerId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<EconomicCustomerViewObjectClientResponse, any>({
        path: `/api/Economic/SyncCustomerFromEconomic`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Economic
     * @name EconomicGetProductsList
     * @request GET:/api/Economic/GetProducts
     * @secure
     */
    economicGetProductsList: (
      query: {
        /** @format uuid */
        economicAccessId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<EconomicProductViewObjectArrayClientResponse, any>({
        path: `/api/Economic/GetProducts`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Economic
     * @name EconomicGetProductGroupsList
     * @request GET:/api/Economic/GetProductGroups
     * @secure
     */
    economicGetProductGroupsList: (
      query: {
        /** @format uuid */
        economicAccessId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<EconomicProductGroupViewObjectArrayClientResponse, any>({
        path: `/api/Economic/GetProductGroups`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Economic
     * @name EconomicGetCustomerGroupsList
     * @request GET:/api/Economic/GetCustomerGroups
     * @secure
     */
    economicGetCustomerGroupsList: (
      query: {
        /** @format uuid */
        economicAccessId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<EconomicCustomerGroupViewObjectArrayClientResponse, any>({
        path: `/api/Economic/GetCustomerGroups`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Economic
     * @name EconomicGetUnitsList
     * @request GET:/api/Economic/GetUnits
     * @secure
     */
    economicGetUnitsList: (
      query: {
        /** @format uuid */
        economicAccessId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<EconomicUnitViewObjectArrayClientResponse, any>({
        path: `/api/Economic/GetUnits`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Economic
     * @name EconomicGetPaymentTermsList
     * @request GET:/api/Economic/GetPaymentTerms
     * @secure
     */
    economicGetPaymentTermsList: (
      query: {
        /** @format uuid */
        economicAccessId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<EconomicPaymentTermViewObjectArrayClientResponse, any>({
        path: `/api/Economic/GetPaymentTerms`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Economic
     * @name EconomicGetPaymentTypesList
     * @request GET:/api/Economic/GetPaymentTypes
     * @secure
     */
    economicGetPaymentTypesList: (
      query: {
        /** @format uuid */
        economicAccessId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<EconomicPaymentTypeViewObjectArrayClientResponse, any>({
        path: `/api/Economic/GetPaymentTypes`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Economic
     * @name EconomicGetLayoutsList
     * @request GET:/api/Economic/GetLayouts
     * @secure
     */
    economicGetLayoutsList: (
      query: {
        /** @format uuid */
        economicAccessId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<EconomicLayoutNumberViewObjectArrayClientResponse, any>({
        path: `/api/Economic/GetLayouts`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Economic
     * @name EconomicGetVatZonesList
     * @request GET:/api/Economic/GetVatZones
     * @secure
     */
    economicGetVatZonesList: (
      query: {
        /** @format uuid */
        economicAccessId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<EconomicVatZoneViewObjectArrayClientResponse, any>({
        path: `/api/Economic/GetVatZones`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Economic
     * @name EconomicCreateProductCreate
     * @request POST:/api/Economic/CreateProduct
     * @secure
     */
    economicCreateProductCreate: (
      query: {
        /** @format uuid */
        economicAccessId: string;
        economicProductId: string;
        economicProductName: string;
        /** @format int32 */
        economicProductGroupId: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<EconomicProductViewObjectClientResponse, any>({
        path: `/api/Economic/CreateProduct`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Economic
     * @name EconomicCreateUnitCreate
     * @request POST:/api/Economic/CreateUnit
     * @secure
     */
    economicCreateUnitCreate: (
      query: {
        /** @format uuid */
        economicAccessId: string;
        economicUnitName: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<EconomicUnitViewObjectClientResponse, any>({
        path: `/api/Economic/CreateUnit`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Economic
     * @name EconomicUpdateEconomicDataForShipmentTypeCreate
     * @request POST:/api/Economic/UpdateEconomicDataForShipmentType
     * @secure
     */
    economicUpdateEconomicDataForShipmentTypeCreate: (
      query: {
        /** @format uuid */
        economicAccessId: string;
        /** @format uuid */
        customerId: string;
        /** @format uuid */
        shipmentTypeId: string;
        economicProductId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<CustomerShipmentTypeViewObjectClientResponse, any>({
        path: `/api/Economic/UpdateEconomicDataForShipmentType`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Economic
     * @name EconomicUpdateEconomicDataForShipmentTypeCategoryCreate
     * @request POST:/api/Economic/UpdateEconomicDataForShipmentTypeCategory
     * @secure
     */
    economicUpdateEconomicDataForShipmentTypeCategoryCreate: (
      query: {
        /** @format uuid */
        economicAccessId: string;
        /** @format uuid */
        customerId: string;
        /** @format uuid */
        shipmentTypeCategoryId: string;
        /** @format int32 */
        economicUnitId: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<CustomerShipmentTypeCategoryViewObjectClientResponse, any>({
        path: `/api/Economic/UpdateEconomicDataForShipmentTypeCategory`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Economic
     * @name EconomicUpdateEconomicDataForCustomerDeliveryFeeCreate
     * @request POST:/api/Economic/UpdateEconomicDataForCustomerDeliveryFee
     * @secure
     */
    economicUpdateEconomicDataForCustomerDeliveryFeeCreate: (
      query: {
        /** @format uuid */
        economicAccessId: string;
        /** @format uuid */
        customerId: string;
        /** @format uuid */
        deliveryFeeId: string;
        economicProductId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<CustomerDeliveryFeeViewObjectClientResponse, any>({
        path: `/api/Economic/UpdateEconomicDataForCustomerDeliveryFee`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Economic
     * @name EconomicCreateInvoiceCreate
     * @request POST:/api/Economic/CreateInvoice
     * @secure
     */
    economicCreateInvoiceCreate: (data: EconomicCreateInvoiceRequest, params: RequestParams = {}) =>
      this.request<InvoiceViewObjectClientResponse, any>({
        path: `/api/Economic/CreateInvoice`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags GeoCoding
     * @name GeoCodingReverseList
     * @request GET:/api/GeoCoding/Reverse
     * @secure
     */
    geoCodingReverseList: (
      query: {
        /** @format double */
        latitude: number;
        /** @format double */
        longitude: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<GeoLocationAddressClientResponse, any>({
        path: `/api/GeoCoding/Reverse`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags GeoCoding
     * @name GeoCodingForwardList
     * @request GET:/api/GeoCoding/Forward
     * @secure
     */
    geoCodingForwardList: (
      query: {
        street: string;
        /** @format int32 */
        postalCode: number;
        city: string;
        country: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<GeoLocationAddressClientResponse, any>({
        path: `/api/GeoCoding/Forward`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags GPS
     * @name GpsReverseList
     * @request GET:/api/GPS/Reverse
     * @secure
     */
    gpsReverseList: (
      query: {
        /** @format double */
        latitude: number;
        /** @format double */
        longitude: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<GeoLocationAddressClientResponse, any>({
        path: `/api/GPS/Reverse`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags GPS
     * @name GpsForwardList
     * @request GET:/api/GPS/Forward
     * @secure
     */
    gpsForwardList: (
      query: {
        street: string;
        /** @format int32 */
        postalCode: number;
        city: string;
        country: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<GeoLocationAddressClientResponse, any>({
        path: `/api/GPS/Forward`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags GPS
     * @name GpsGetDirectionsCreate
     * @request POST:/api/GPS/GetDirections
     * @secure
     */
    gpsGetDirectionsCreate: (data: DirectionsRequest, params: RequestParams = {}) =>
      this.request<GPSDirectionsClientResponse, any>({
        path: `/api/GPS/GetDirections`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags GPS
     * @name GpsGetOptimizedDirectionsCreate
     * @request POST:/api/GPS/GetOptimizedDirections
     * @secure
     */
    gpsGetOptimizedDirectionsCreate: (data: DirectionsRequest, params: RequestParams = {}) =>
      this.request<GPSDirectionsClientResponse, any>({
        path: `/api/GPS/GetOptimizedDirections`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Image
     * @name ImageUploadAndReplaceImageUpdate
     * @request PUT:/api/Image/UploadAndReplaceImage
     * @secure
     */
    imageUploadAndReplaceImageUpdate: (
      query: {
        currentImageUrl?: string;
        /** @format uuid */
        identifier: string;
      },
      data: {
        /** @format binary */
        formFile: File;
      },
      params: RequestParams = {},
    ) =>
      this.request<ImageUploadResponseClientResponse, any>({
        path: `/api/Image/UploadAndReplaceImage`,
        method: "PUT",
        query: query,
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Image
     * @name ImageDeleteImageDelete
     * @request DELETE:/api/Image/DeleteImage
     * @secure
     */
    imageDeleteImageDelete: (
      query: {
        imageUrl: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<BooleanClientResponse, any>({
        path: `/api/Image/DeleteImage`,
        method: "DELETE",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Image
     * @name ImageDeleteImagesDelete
     * @request DELETE:/api/Image/DeleteImages
     * @secure
     */
    imageDeleteImagesDelete: (data: string[], params: RequestParams = {}) =>
      this.request<BooleanArrayClientResponse, any>({
        path: `/api/Image/DeleteImages`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Invoice
     * @name InvoiceInvoiceTaskCreate
     * @request POST:/api/Invoice/InvoiceTask
     * @secure
     */
    invoiceInvoiceTaskCreate: (
      query: {
        /** @format uuid */
        taskId: string;
        force?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<InvoiceViewModelClientResponse, any>({
        path: `/api/Invoice/InvoiceTask`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Invoice
     * @name InvoiceInvoiceTasksCreate
     * @request POST:/api/Invoice/InvoiceTasks
     * @secure
     */
    invoiceInvoiceTasksCreate: (data: TasksByIdRequest, params: RequestParams = {}) =>
      this.request<InvoicesViewModelClientResponse, any>({
        path: `/api/Invoice/InvoiceTasks`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Invoice
     * @name InvoiceAddOrUpdateInvoiceCreate
     * @request POST:/api/Invoice/AddOrUpdateInvoice
     * @secure
     */
    invoiceAddOrUpdateInvoiceCreate: (data: AddOrUpdateInvoiceRequest, params: RequestParams = {}) =>
      this.request<InvoiceViewModelClientResponse, any>({
        path: `/api/Invoice/AddOrUpdateInvoice`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Invoice
     * @name InvoiceMarkInvoiceAsPaidCreate
     * @request POST:/api/Invoice/MarkInvoiceAsPaid
     * @secure
     */
    invoiceMarkInvoiceAsPaidCreate: (
      query: {
        /** @format uuid */
        invoiceId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<InvoiceViewModelClientResponse, any>({
        path: `/api/Invoice/MarkInvoiceAsPaid`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Invoice
     * @name InvoiceAddTasksToInvoiceCreate
     * @request POST:/api/Invoice/AddTasksToInvoice
     * @secure
     */
    invoiceAddTasksToInvoiceCreate: (data: AddTasksToInvoiceRequest, params: RequestParams = {}) =>
      this.request<InvoicesViewModelClientResponse, any>({
        path: `/api/Invoice/AddTasksToInvoice`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Invoice
     * @name InvoiceDeleteInvoiceCreate
     * @request POST:/api/Invoice/DeleteInvoice
     * @secure
     */
    invoiceDeleteInvoiceCreate: (
      query: {
        /** @format uuid */
        invoiceId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<InvoiceViewModelClientResponse, any>({
        path: `/api/Invoice/DeleteInvoice`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Invoice
     * @name InvoiceRemoveTasksFromInvoiceCreate
     * @request POST:/api/Invoice/RemoveTasksFromInvoice
     * @secure
     */
    invoiceRemoveTasksFromInvoiceCreate: (data: TasksByIdRequest, params: RequestParams = {}) =>
      this.request<InvoicesViewModelClientResponse, any>({
        path: `/api/Invoice/RemoveTasksFromInvoice`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Invoice
     * @name InvoiceSearchInvoicesCreate
     * @request POST:/api/Invoice/SearchInvoices
     * @secure
     */
    invoiceSearchInvoicesCreate: (data: SearchInvoicesRequest, params: RequestParams = {}) =>
      this.request<PagedInvoicesViewModelClientResponse, any>({
        path: `/api/Invoice/SearchInvoices`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Invoice
     * @name InvoiceGetInvoiceCountForIntervalList
     * @request GET:/api/Invoice/GetInvoiceCountForInterval
     * @secure
     */
    invoiceGetInvoiceCountForIntervalList: (
      query: {
        /** @format date-time */
        start: string;
        /** @format date-time */
        end: string;
        /** @format uuid */
        userId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<InvoiceStatisticsDateEntryViewObjectArrayClientResponse, any>({
        path: `/api/Invoice/GetInvoiceCountForInterval`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags IpInfo
     * @name IpInfoGetInfoList
     * @request GET:/api/IpInfo/GetInfo
     * @secure
     */
    ipInfoGetInfoList: (
      query: {
        ip: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<IpInformationViewObjectClientResponse, any>({
        path: `/api/IpInfo/GetInfo`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Logs
     * @name LogsGetDeliveryTaskLogsCreate
     * @request POST:/api/Logs/GetDeliveryTaskLogs
     * @secure
     */
    logsGetDeliveryTaskLogsCreate: (data: LogsRequest | GlobalLogsRequest, params: RequestParams = {}) =>
      this.request<DeliveryTaskActionLogViewModelClientResponse, any>({
        path: `/api/Logs/GetDeliveryTaskLogs`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Logs
     * @name LogsGetBusinessEntityLogsCreate
     * @request POST:/api/Logs/GetBusinessEntityLogs
     * @secure
     */
    logsGetBusinessEntityLogsCreate: (data: LogsRequest | GlobalLogsRequest, params: RequestParams = {}) =>
      this.request<BusinessEntityActionLogViewModelClientResponse, any>({
        path: `/api/Logs/GetBusinessEntityLogs`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Logs
     * @name LogsGetCustomerLogsCreate
     * @request POST:/api/Logs/GetCustomerLogs
     * @secure
     */
    logsGetCustomerLogsCreate: (data: LogsRequest | GlobalLogsRequest, params: RequestParams = {}) =>
      this.request<CustomerActionLogViewModelClientResponse, any>({
        path: `/api/Logs/GetCustomerLogs`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Logs
     * @name LogsGetResourceLogsCreate
     * @request POST:/api/Logs/GetResourceLogs
     * @secure
     */
    logsGetResourceLogsCreate: (data: LogsRequest | GlobalLogsRequest, params: RequestParams = {}) =>
      this.request<ResourceActionLogViewModelClientResponse, any>({
        path: `/api/Logs/GetResourceLogs`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Logs
     * @name LogsGetResourceCategoryLogsCreate
     * @request POST:/api/Logs/GetResourceCategoryLogs
     * @secure
     */
    logsGetResourceCategoryLogsCreate: (data: LogsRequest | GlobalLogsRequest, params: RequestParams = {}) =>
      this.request<ResourceCategoryActionLogViewModelClientResponse, any>({
        path: `/api/Logs/GetResourceCategoryLogs`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Logs
     * @name LogsGetBusinessEntityUserLogsCreate
     * @request POST:/api/Logs/GetBusinessEntityUserLogs
     * @secure
     */
    logsGetBusinessEntityUserLogsCreate: (data: LogsRequest | GlobalLogsRequest, params: RequestParams = {}) =>
      this.request<BusinessEntityUserActionLogViewModelClientResponse, any>({
        path: `/api/Logs/GetBusinessEntityUserLogs`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Logs
     * @name LogsGetCustomerUserLogsCreate
     * @request POST:/api/Logs/GetCustomerUserLogs
     * @secure
     */
    logsGetCustomerUserLogsCreate: (data: LogsRequest | GlobalLogsRequest, params: RequestParams = {}) =>
      this.request<CustomerUserActionLogViewModelClientResponse, any>({
        path: `/api/Logs/GetCustomerUserLogs`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Logs
     * @name LogsGetStorageLogsCreate
     * @request POST:/api/Logs/GetStorageLogs
     * @secure
     */
    logsGetStorageLogsCreate: (data: LogsRequest | GlobalLogsRequest, params: RequestParams = {}) =>
      this.request<StorageActionLogViewModelClientResponse, any>({
        path: `/api/Logs/GetStorageLogs`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Logs
     * @name LogsGetCustomerDeliveryPriceConfigurationLogsCreate
     * @request POST:/api/Logs/GetCustomerDeliveryPriceConfigurationLogs
     * @secure
     */
    logsGetCustomerDeliveryPriceConfigurationLogsCreate: (
      data: LogsRequest | GlobalLogsRequest,
      params: RequestParams = {},
    ) =>
      this.request<CustomerDeliveryPriceConfigurationActionLogViewModelClientResponse, any>({
        path: `/api/Logs/GetCustomerDeliveryPriceConfigurationLogs`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Logs
     * @name LogsGetCustomerDeliveryFeeLogsCreate
     * @request POST:/api/Logs/GetCustomerDeliveryFeeLogs
     * @secure
     */
    logsGetCustomerDeliveryFeeLogsCreate: (data: LogsRequest | GlobalLogsRequest, params: RequestParams = {}) =>
      this.request<CustomerDeliveryFeeActionLogViewModelClientResponse, any>({
        path: `/api/Logs/GetCustomerDeliveryFeeLogs`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Logs
     * @name LogsGetGlobalLogsCreate
     * @request POST:/api/Logs/GetGlobalLogs
     * @secure
     */
    logsGetGlobalLogsCreate: (data: GlobalLogsRequest, params: RequestParams = {}) =>
      this.request<GlobalActionLogViewModelClientResponse, any>({
        path: `/api/Logs/GetGlobalLogs`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Mail
     * @name MailGetFailedMailsList
     * @request GET:/api/Mail/GetFailedMails
     * @secure
     */
    mailGetFailedMailsList: (params: RequestParams = {}) =>
      this.request<MailFailedRecordArrayClientResponse, any>({
        path: `/api/Mail/GetFailedMails`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Mail
     * @name MailMarkFailedMailAsHandledCreate
     * @request POST:/api/Mail/MarkFailedMailAsHandled
     * @secure
     */
    mailMarkFailedMailAsHandledCreate: (
      query: {
        /** @format int32 */
        mailId: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<MailFailedRecordClientResponse, any>({
        path: `/api/Mail/MarkFailedMailAsHandled`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Mail
     * @name MailMarkFailedMailsAsHandledCreate
     * @request POST:/api/Mail/MarkFailedMailsAsHandled
     * @secure
     */
    mailMarkFailedMailsAsHandledCreate: (params: RequestParams = {}) =>
      this.request<MailFailedRecordArrayClientResponse, any>({
        path: `/api/Mail/MarkFailedMailsAsHandled`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Mail
     * @name MailResendFailedMailCreate
     * @request POST:/api/Mail/ResendFailedMail
     * @secure
     */
    mailResendFailedMailCreate: (
      query: {
        /** @format int32 */
        mailId: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<MailFailedRecordClientResponse, any>({
        path: `/api/Mail/ResendFailedMail`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Mail
     * @name MailResendFailedMailsCreate
     * @request POST:/api/Mail/ResendFailedMails
     * @secure
     */
    mailResendFailedMailsCreate: (params: RequestParams = {}) =>
      this.request<MailFailedRecordArrayClientResponse, any>({
        path: `/api/Mail/ResendFailedMails`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Notifications
     * @name NotificationsGetNotificationsList
     * @request GET:/api/Notifications/GetNotifications
     * @secure
     */
    notificationsGetNotificationsList: (params: RequestParams = {}) =>
      this.request<NotificationViewModelClientResponse, any>({
        path: `/api/Notifications/GetNotifications`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Notifications
     * @name NotificationsAddOrUpdateNotificationCreate
     * @request POST:/api/Notifications/AddOrUpdateNotification
     * @secure
     */
    notificationsAddOrUpdateNotificationCreate: (data: AddOrUpdateNotificationRequest, params: RequestParams = {}) =>
      this.request<NotificationViewObjectClientResponse, any>({
        path: `/api/Notifications/AddOrUpdateNotification`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Notifications
     * @name NotificationsAddOrUpdateNotificationOrdersCreate
     * @request POST:/api/Notifications/AddOrUpdateNotificationOrders
     * @secure
     */
    notificationsAddOrUpdateNotificationOrdersCreate: (data: string[], params: RequestParams = {}) =>
      this.request<NotificationViewModelClientResponse, any>({
        path: `/api/Notifications/AddOrUpdateNotificationOrders`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Notifications
     * @name NotificationsDeleteNotificationDelete
     * @request DELETE:/api/Notifications/DeleteNotification
     * @secure
     */
    notificationsDeleteNotificationDelete: (
      query: {
        /** @format uuid */
        notificationId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<NotificationViewObjectClientResponse, any>({
        path: `/api/Notifications/DeleteNotification`,
        method: "DELETE",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Notifications
     * @name NotificationsMarkNotificationAsSeenCreate
     * @request POST:/api/Notifications/MarkNotificationAsSeen
     * @secure
     */
    notificationsMarkNotificationAsSeenCreate: (
      query: {
        /** @format uuid */
        notificationId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<BooleanClientResponse, any>({
        path: `/api/Notifications/MarkNotificationAsSeen`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Notifications
     * @name NotificationsRePublishNotificationCreate
     * @request POST:/api/Notifications/RePublishNotification
     * @secure
     */
    notificationsRePublishNotificationCreate: (
      query: {
        /** @format uuid */
        notificationId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<BooleanClientResponse, any>({
        path: `/api/Notifications/RePublishNotification`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Notifications
     * @name NotificationsGetActiveNotificationsList
     * @request GET:/api/Notifications/GetActiveNotifications
     * @secure
     */
    notificationsGetActiveNotificationsList: (
      query: {
        target: NotificationTarget;
      },
      params: RequestParams = {},
    ) =>
      this.request<NotificationViewModelClientResponse, any>({
        path: `/api/Notifications/GetActiveNotifications`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Project
     * @name ProjectGetProjectList
     * @request GET:/api/Project/GetProject
     * @secure
     */
    projectGetProjectList: (
      query: {
        /** @format uuid */
        projectId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ProjectViewObjectClientResponse, any>({
        path: `/api/Project/GetProject`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Project
     * @name ProjectGetActiveProjectsList
     * @request GET:/api/Project/GetActiveProjects
     * @secure
     */
    projectGetActiveProjectsList: (params: RequestParams = {}) =>
      this.request<ProjectsViewModelClientResponse, any>({
        path: `/api/Project/GetActiveProjects`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Project
     * @name ProjectGetActiveProjectsForCustomerList
     * @request GET:/api/Project/GetActiveProjectsForCustomer
     * @secure
     */
    projectGetActiveProjectsForCustomerList: (
      query: {
        /** @format uuid */
        customerId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ProjectsViewModelClientResponse, any>({
        path: `/api/Project/GetActiveProjectsForCustomer`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Project
     * @name ProjectGetProjectsList
     * @request GET:/api/Project/GetProjects
     * @secure
     */
    projectGetProjectsList: (
      query: {
        /** @format date-time */
        from: string;
        /** @format date-time */
        to: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ProjectsViewModelClientResponse, any>({
        path: `/api/Project/GetProjects`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Project
     * @name ProjectSearchProjectsCreate
     * @request POST:/api/Project/SearchProjects
     * @secure
     */
    projectSearchProjectsCreate: (data: SearchProjectsRequest, params: RequestParams = {}) =>
      this.request<PagedProjectsViewModelClientResponse, any>({
        path: `/api/Project/SearchProjects`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Project
     * @name ProjectAddOrUpdateProjectCreate
     * @request POST:/api/Project/AddOrUpdateProject
     * @secure
     */
    projectAddOrUpdateProjectCreate: (data: AddOrUpdateProjectRequest, params: RequestParams = {}) =>
      this.request<ProjectViewObjectClientResponse, any>({
        path: `/api/Project/AddOrUpdateProject`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Project
     * @name ProjectGetTasksForProjectList
     * @request GET:/api/Project/GetTasksForProject
     * @secure
     */
    projectGetTasksForProjectList: (
      query: {
        /** @format uuid */
        projectId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<TasksViewModelClientResponse, any>({
        path: `/api/Project/GetTasksForProject`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Resource
     * @name ResourceGetResourcesList
     * @request GET:/api/Resource/GetResources
     * @secure
     */
    resourceGetResourcesList: (params: RequestParams = {}) =>
      this.request<ResourcesViewModelClientResponse, any>({
        path: `/api/Resource/GetResources`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Resource
     * @name ResourceCreateResourceCreate
     * @request POST:/api/Resource/CreateResource
     * @secure
     */
    resourceCreateResourceCreate: (data: CreateResourceRequest | UpdateResourceRequest, params: RequestParams = {}) =>
      this.request<ResourceViewModelClientResponse, any>({
        path: `/api/Resource/CreateResource`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Resource
     * @name ResourceUpdateResourceCreate
     * @request POST:/api/Resource/UpdateResource
     * @secure
     */
    resourceUpdateResourceCreate: (data: UpdateResourceRequest, params: RequestParams = {}) =>
      this.request<ResourceViewModelClientResponse, any>({
        path: `/api/Resource/UpdateResource`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Resource
     * @name ResourceRemoveResourceDelete
     * @request DELETE:/api/Resource/RemoveResource
     * @secure
     */
    resourceRemoveResourceDelete: (
      query: {
        /** @format uuid */
        resourceId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ResourceViewModelClientResponse, any>({
        path: `/api/Resource/RemoveResource`,
        method: "DELETE",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Resource
     * @name ResourceRestoreResourceList
     * @request GET:/api/Resource/RestoreResource
     * @secure
     */
    resourceRestoreResourceList: (
      query: {
        /** @format uuid */
        resourceId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ResourceViewModelClientResponse, any>({
        path: `/api/Resource/RestoreResource`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Resource
     * @name ResourceGetResourceCategoriesList
     * @request GET:/api/Resource/GetResourceCategories
     * @secure
     */
    resourceGetResourceCategoriesList: (params: RequestParams = {}) =>
      this.request<ResourceCategoriesViewModelClientResponse, any>({
        path: `/api/Resource/GetResourceCategories`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Resource
     * @name ResourceCreateResourceCategoryCreate
     * @request POST:/api/Resource/CreateResourceCategory
     * @secure
     */
    resourceCreateResourceCategoryCreate: (
      data: CreateResourceCategoryRequest | UpdateResourceCategoryRequest,
      params: RequestParams = {},
    ) =>
      this.request<ResourceCategoryViewModelClientResponse, any>({
        path: `/api/Resource/CreateResourceCategory`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Resource
     * @name ResourceUpdateResourceCategoryCreate
     * @request POST:/api/Resource/UpdateResourceCategory
     * @secure
     */
    resourceUpdateResourceCategoryCreate: (data: UpdateResourceCategoryRequest, params: RequestParams = {}) =>
      this.request<ResourceCategoryViewModelClientResponse, any>({
        path: `/api/Resource/UpdateResourceCategory`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Resource
     * @name ResourceRemoveResourceCategoryDelete
     * @request DELETE:/api/Resource/RemoveResourceCategory
     * @secure
     */
    resourceRemoveResourceCategoryDelete: (
      query: {
        /** @format uuid */
        categoryId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ResourceCategoryViewModelClientResponse, any>({
        path: `/api/Resource/RemoveResourceCategory`,
        method: "DELETE",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Resource
     * @name ResourceRestoreResourceCategoryList
     * @request GET:/api/Resource/RestoreResourceCategory
     * @secure
     */
    resourceRestoreResourceCategoryList: (
      query: {
        /** @format uuid */
        categoryId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ResourceCategoryViewModelClientResponse, any>({
        path: `/api/Resource/RestoreResourceCategory`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Resource
     * @name ResourceGetDriverLogsForResourceList
     * @request GET:/api/Resource/GetDriverLogsForResource
     * @secure
     */
    resourceGetDriverLogsForResourceList: (
      query: {
        /** @format uuid */
        resourceId: string;
        /** @format date-time */
        fromDate: string;
        /** @format date-time */
        toDate: string;
        /** @format int32 */
        skip: number;
        /** @format int32 */
        take: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<BusinessEntityResourceDriverLogsViewModelClientResponse, any>({
        path: `/api/Resource/GetDriverLogsForResource`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Resource
     * @name ResourceGetCurrentDriverLogForResourceList
     * @request GET:/api/Resource/GetCurrentDriverLogForResource
     * @secure
     */
    resourceGetCurrentDriverLogForResourceList: (
      query: {
        /** @format uuid */
        resourceId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<BusinessEntityUserDriverLogViewObjectNullableClientResponse, any>({
        path: `/api/Resource/GetCurrentDriverLogForResource`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Schedule
     * @name ScheduleGetScheduleList
     * @request GET:/api/Schedule/GetSchedule
     * @secure
     */
    scheduleGetScheduleList: (
      query: {
        /** @format uuid */
        scheduleId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ScheduleEntryViewObjectClientResponse, any>({
        path: `/api/Schedule/GetSchedule`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Schedule
     * @name ScheduleGetSchedulesCreate
     * @request POST:/api/Schedule/GetSchedules
     * @secure
     */
    scheduleGetSchedulesCreate: (data: SchedulesRequest, params: RequestParams = {}) =>
      this.request<SchedulesEntryViewModelClientResponse, any>({
        path: `/api/Schedule/GetSchedules`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Schedule
     * @name ScheduleGetSchedulesForTypeCreate
     * @request POST:/api/Schedule/GetSchedulesForType
     * @secure
     */
    scheduleGetSchedulesForTypeCreate: (data: SchedulesForTypeRequest, params: RequestParams = {}) =>
      this.request<SchedulesEntryViewModelClientResponse, any>({
        path: `/api/Schedule/GetSchedulesForType`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Schedule
     * @name ScheduleAddOrUpdateScheduleCreate
     * @request POST:/api/Schedule/AddOrUpdateSchedule
     * @secure
     */
    scheduleAddOrUpdateScheduleCreate: (data: AddOrUpdateScheduleEntryRequest, params: RequestParams = {}) =>
      this.request<ScheduleEntryViewObjectClientResponse, any>({
        path: `/api/Schedule/AddOrUpdateSchedule`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Schedule
     * @name ScheduleAddOrUpdateScheduleUserInformationCreate
     * @request POST:/api/Schedule/AddOrUpdateScheduleUserInformation
     * @secure
     */
    scheduleAddOrUpdateScheduleUserInformationCreate: (
      data: AddOrUpdateScheduleUserInformationRequest,
      params: RequestParams = {},
    ) =>
      this.request<ScheduleEntryViewObjectClientResponse, any>({
        path: `/api/Schedule/AddOrUpdateScheduleUserInformation`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Schedule
     * @name ScheduleAddOrUpdateScheduleInformationCreate
     * @request POST:/api/Schedule/AddOrUpdateScheduleInformation
     * @secure
     */
    scheduleAddOrUpdateScheduleInformationCreate: (
      data: AddOrUpdateScheduleInformationRequest,
      params: RequestParams = {},
    ) =>
      this.request<ScheduleEntryViewObjectClientResponse, any>({
        path: `/api/Schedule/AddOrUpdateScheduleInformation`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Schedule
     * @name ScheduleAddOrUpdateScheduleResourceInformationCreate
     * @request POST:/api/Schedule/AddOrUpdateScheduleResourceInformation
     * @secure
     */
    scheduleAddOrUpdateScheduleResourceInformationCreate: (
      data: AddOrUpdateScheduleResourceInformationRequest,
      params: RequestParams = {},
    ) =>
      this.request<ScheduleEntryViewObjectClientResponse, any>({
        path: `/api/Schedule/AddOrUpdateScheduleResourceInformation`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Schedule
     * @name ScheduleAddOrUpdateScheduleInformationsCreate
     * @request POST:/api/Schedule/AddOrUpdateScheduleInformations
     * @secure
     */
    scheduleAddOrUpdateScheduleInformationsCreate: (
      data: AddOrUpdateScheduleInformationsRequest,
      params: RequestParams = {},
    ) =>
      this.request<ScheduleEntryViewObjectClientResponse, any>({
        path: `/api/Schedule/AddOrUpdateScheduleInformations`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Schedule
     * @name ScheduleAddOrUpdateScheduleDashboardViewEntryCreate
     * @request POST:/api/Schedule/AddOrUpdateScheduleDashboardViewEntry
     * @secure
     */
    scheduleAddOrUpdateScheduleDashboardViewEntryCreate: (
      data: AddOrUpdateScheduleDashboardViewEntryRequest,
      params: RequestParams = {},
    ) =>
      this.request<ScheduleEntryViewObjectClientResponse, any>({
        path: `/api/Schedule/AddOrUpdateScheduleDashboardViewEntry`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Schedule
     * @name ScheduleCreateScheduleIntervalCreate
     * @request POST:/api/Schedule/CreateScheduleInterval
     * @secure
     */
    scheduleCreateScheduleIntervalCreate: (data: CreateScheduleEntryIntervalRequest, params: RequestParams = {}) =>
      this.request<SchedulesEntryViewModelClientResponse, any>({
        path: `/api/Schedule/CreateScheduleInterval`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Schedule
     * @name ScheduleRemoveScheduleEntryDelete
     * @request DELETE:/api/Schedule/RemoveScheduleEntry
     * @secure
     */
    scheduleRemoveScheduleEntryDelete: (
      query: {
        /** @format uuid */
        scheduleEntryId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ScheduleEntryViewObjectClientResponse, any>({
        path: `/api/Schedule/RemoveScheduleEntry`,
        method: "DELETE",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Schedule
     * @name ScheduleRemoveScheduleEntriesDelete
     * @request DELETE:/api/Schedule/RemoveScheduleEntries
     * @secure
     */
    scheduleRemoveScheduleEntriesDelete: (data: string[], params: RequestParams = {}) =>
      this.request<SchedulesEntryViewModelClientResponse, any>({
        path: `/api/Schedule/RemoveScheduleEntries`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Security
     * @name SecurityGetEndpointAccessesList
     * @request GET:/api/Security/GetEndpointAccesses
     * @secure
     */
    securityGetEndpointAccessesList: (params: RequestParams = {}) =>
      this.request<EndpointAccessViewObjectArrayClientResponse, any>({
        path: `/api/Security/GetEndpointAccesses`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Security
     * @name SecurityUpdateEndpointAccessCreate
     * @request POST:/api/Security/UpdateEndpointAccess
     * @secure
     */
    securityUpdateEndpointAccessCreate: (data: UpdateEndpointAccessRequest, params: RequestParams = {}) =>
      this.request<EndpointAccessViewObjectClientResponse, any>({
        path: `/api/Security/UpdateEndpointAccess`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Shipment
     * @name ShipmentCreateCreate
     * @request POST:/api/Shipment/Create
     * @secure
     */
    shipmentCreateCreate: (data: ShipmentRequest, params: RequestParams = {}) =>
      this.request<ShipmentResponseClientResponse, any>({
        path: `/api/Shipment/Create`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Shipment
     * @name ShipmentUpdateCreate
     * @request POST:/api/Shipment/Update
     * @secure
     */
    shipmentUpdateCreate: (data: ShipmentRequest, params: RequestParams = {}) =>
      this.request<ShipmentResponseClientResponse, any>({
        path: `/api/Shipment/Update`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Shipment
     * @name ShipmentOrderHistoryList
     * @request GET:/api/Shipment/OrderHistory
     * @secure
     */
    shipmentOrderHistoryList: (
      query?: {
        /** @format uuid */
        customerId?: string;
        /** @format uuid */
        orderId?: string;
        /** @format int32 */
        skip?: number;
        /** @format int32 */
        take?: number;
        /** @format date-time */
        startDate?: string;
        /** @format date-time */
        endDate?: string;
        /** @maxLength 200 */
        term?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<OrderHistoryViewModelClientResponse, any>({
        path: `/api/Shipment/OrderHistory`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Shipment
     * @name ShipmentSearchPostalCodeList
     * @request GET:/api/Shipment/SearchPostalCode
     * @secure
     */
    shipmentSearchPostalCodeList: (
      query?: {
        query?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<PostalCodeSearchResponseClientResponse, any>({
        path: `/api/Shipment/SearchPostalCode`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Shipment
     * @name ShipmentSearchAddressesList
     * @request GET:/api/Shipment/SearchAddresses
     * @secure
     */
    shipmentSearchAddressesList: (
      query?: {
        query?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<SearchAddressesResponseClientResponse, any>({
        path: `/api/Shipment/SearchAddresses`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Shipment
     * @name ShipmentDownloadShipmentNoticePdfCreate
     * @request POST:/api/Shipment/DownloadShipmentNoticePdf
     * @secure
     */
    shipmentDownloadShipmentNoticePdfCreate: (
      query: {
        /** @format uuid */
        taskId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/Shipment/DownloadShipmentNoticePdf`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ShippingDictionary
     * @name ShippingDictionaryGetSupportedLanguagesList
     * @request GET:/api/ShippingDictionary/GetSupportedLanguages
     * @secure
     */
    shippingDictionaryGetSupportedLanguagesList: (params: RequestParams = {}) =>
      this.request<DictionaryLanguagesViewModelClientResponse, any>({
        path: `/api/ShippingDictionary/GetSupportedLanguages`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ShippingDictionary
     * @name ShippingDictionaryGetDictionaryLabelsList
     * @request GET:/api/ShippingDictionary/GetDictionaryLabels
     * @secure
     */
    shippingDictionaryGetDictionaryLabelsList: (params: RequestParams = {}) =>
      this.request<DictionaryLabelsByLanguageViewModelClientResponse, any>({
        path: `/api/ShippingDictionary/GetDictionaryLabels`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ShippingDictionary
     * @name ShippingDictionaryGetDictionaryLabelsForLanguageList
     * @request GET:/api/ShippingDictionary/GetDictionaryLabelsForLanguage
     * @secure
     */
    shippingDictionaryGetDictionaryLabelsForLanguageList: (
      query: {
        languageCode: string;
        area?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<DictionaryLabelsViewModelClientResponse, any>({
        path: `/api/ShippingDictionary/GetDictionaryLabelsForLanguage`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ShippingDictionary
     * @name ShippingDictionaryGetDictionaryLabelsForAreaList
     * @request GET:/api/ShippingDictionary/GetDictionaryLabelsForArea
     * @secure
     */
    shippingDictionaryGetDictionaryLabelsForAreaList: (
      query?: {
        area?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<DictionaryLabelsViewModelClientResponse, any>({
        path: `/api/ShippingDictionary/GetDictionaryLabelsForArea`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ShippingDictionary
     * @name ShippingDictionaryAddOrUpdateDictionaryLabelCreate
     * @request POST:/api/ShippingDictionary/AddOrUpdateDictionaryLabel
     * @secure
     */
    shippingDictionaryAddOrUpdateDictionaryLabelCreate: (
      data: UpdateDictionaryLabelRequest,
      params: RequestParams = {},
    ) =>
      this.request<DictionaryLabelByLanguageViewModelClientResponse, any>({
        path: `/api/ShippingDictionary/AddOrUpdateDictionaryLabel`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ShippingDictionary
     * @name ShippingDictionaryAddOrUpdateDictionaryLanguageCreate
     * @request POST:/api/ShippingDictionary/AddOrUpdateDictionaryLanguage
     * @secure
     */
    shippingDictionaryAddOrUpdateDictionaryLanguageCreate: (
      data: AddOrUpdateDictionaryLanguageRequest,
      params: RequestParams = {},
    ) =>
      this.request<DictionaryLanguageViewModelClientResponse, any>({
        path: `/api/ShippingDictionary/AddOrUpdateDictionaryLanguage`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Statistics
     * @name StatisticsGetTaskStatisticsForIntervalCreate
     * @request POST:/api/Statistics/GetTaskStatisticsForInterval
     * @secure
     */
    statisticsGetTaskStatisticsForIntervalCreate: (
      query: {
        /** @format date-time */
        start: string;
        /** @format date-time */
        end: string;
      },
      data: string[],
      params: RequestParams = {},
    ) =>
      this.request<TaskStatisticsDateSeriesEntryViewModelClientResponse, any>({
        path: `/api/Statistics/GetTaskStatisticsForInterval`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Statistics
     * @name StatisticsGetTaskCountPerDateForOriginCreate
     * @request POST:/api/Statistics/GetTaskCountPerDateForOrigin
     * @secure
     */
    statisticsGetTaskCountPerDateForOriginCreate: (
      query: {
        /** @format date-time */
        start: string;
        /** @format date-time */
        end: string;
      },
      data: string[],
      params: RequestParams = {},
    ) =>
      this.request<TaskStatisticsByOriginViewModelClientResponse, any>({
        path: `/api/Statistics/GetTaskCountPerDateForOrigin`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Statistics
     * @name StatisticsGetTaskCountByDeliveryTypeCreate
     * @request POST:/api/Statistics/GetTaskCountByDeliveryType
     * @secure
     */
    statisticsGetTaskCountByDeliveryTypeCreate: (
      query: {
        /** @format date-time */
        start: string;
        /** @format date-time */
        end: string;
      },
      data: string[],
      params: RequestParams = {},
    ) =>
      this.request<TaskStatisticsByDeliveryTypeEntryViewModelClientResponse, any>({
        path: `/api/Statistics/GetTaskCountByDeliveryType`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Statistics
     * @name StatisticsGetTaskCountByDeliveryTypeByDateCreate
     * @request POST:/api/Statistics/GetTaskCountByDeliveryTypeByDate
     * @secure
     */
    statisticsGetTaskCountByDeliveryTypeByDateCreate: (
      query: {
        /** @format date-time */
        start: string;
        /** @format date-time */
        end: string;
      },
      data: string[],
      params: RequestParams = {},
    ) =>
      this.request<TaskStatisticsByDeliveryTypeEntryByDateViewModelClientResponse, any>({
        path: `/api/Statistics/GetTaskCountByDeliveryTypeByDate`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Statistics
     * @name StatisticsGetTaskCountByShipmentTypeCreate
     * @request POST:/api/Statistics/GetTaskCountByShipmentType
     * @secure
     */
    statisticsGetTaskCountByShipmentTypeCreate: (
      query: {
        /** @format date-time */
        start: string;
        /** @format date-time */
        end: string;
      },
      data: string[],
      params: RequestParams = {},
    ) =>
      this.request<TaskStatisticsShipmentTypeEntryViewModelClientResponse, any>({
        path: `/api/Statistics/GetTaskCountByShipmentType`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Statistics
     * @name StatisticsGetTaskCountByShipmentTypeByDateCreate
     * @request POST:/api/Statistics/GetTaskCountByShipmentTypeByDate
     * @secure
     */
    statisticsGetTaskCountByShipmentTypeByDateCreate: (
      query: {
        /** @format date-time */
        start: string;
        /** @format date-time */
        end: string;
      },
      data: string[],
      params: RequestParams = {},
    ) =>
      this.request<TaskStatisticsShipmentTypeEntryByDateViewModelClientResponse, any>({
        path: `/api/Statistics/GetTaskCountByShipmentTypeByDate`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Statistics
     * @name StatisticsGetDateRangeForTasksCreate
     * @request POST:/api/Statistics/GetDateRangeForTasks
     * @secure
     */
    statisticsGetDateRangeForTasksCreate: (data: string[], params: RequestParams = {}) =>
      this.request<TaskStatisticsDateRangeViewModelClientResponse, any>({
        path: `/api/Statistics/GetDateRangeForTasks`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Statistics
     * @name StatisticsGetDateRangeForInvoicesCreate
     * @request POST:/api/Statistics/GetDateRangeForInvoices
     * @secure
     */
    statisticsGetDateRangeForInvoicesCreate: (data: string[], params: RequestParams = {}) =>
      this.request<TaskStatisticsDateRangeViewModelClientResponse, any>({
        path: `/api/Statistics/GetDateRangeForInvoices`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Statistics
     * @name StatisticsGetTaskStatisticsList
     * @request GET:/api/Statistics/GetTaskStatistics
     * @secure
     */
    statisticsGetTaskStatisticsList: (
      query?: {
        /** @format date-time */
        startDate?: string;
        /** @format date-time */
        endDate?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<MainTaskStatisticsClientResponse, any>({
        path: `/api/Statistics/GetTaskStatistics`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Statistics
     * @name StatisticsGetCustomerOnlineActivityStatisticsCreate
     * @request POST:/api/Statistics/GetCustomerOnlineActivityStatistics
     * @secure
     */
    statisticsGetCustomerOnlineActivityStatisticsCreate: (
      query: {
        /** @format date-time */
        start: string;
        /** @format date-time */
        end: string;
      },
      data: string[],
      params: RequestParams = {},
    ) =>
      this.request<CustomerUserLoginDateSeriesEntryViewModelClientResponse, any>({
        path: `/api/Statistics/GetCustomerOnlineActivityStatistics`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Statistics
     * @name StatisticsGetCustomerMaxOnlineActivityStatisticsCreate
     * @request POST:/api/Statistics/GetCustomerMaxOnlineActivityStatistics
     * @secure
     */
    statisticsGetCustomerMaxOnlineActivityStatisticsCreate: (
      query: {
        /** @format date-time */
        start: string;
        /** @format date-time */
        end: string;
      },
      data: string[],
      params: RequestParams = {},
    ) =>
      this.request<CustomerUserLoginDateSeriesEntryViewModelClientResponse, any>({
        path: `/api/Statistics/GetCustomerMaxOnlineActivityStatistics`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Statistics
     * @name StatisticsGetSmsReceivedCountPerDateCreate
     * @request POST:/api/Statistics/GetSmsReceivedCountPerDate
     * @secure
     */
    statisticsGetSmsReceivedCountPerDateCreate: (
      query: {
        /** @format date-time */
        start: string;
        /** @format date-time */
        end: string;
      },
      data: string[],
      params: RequestParams = {},
    ) =>
      this.request<SmsStatisticsDateSeriesEntryViewModelClientResponse, any>({
        path: `/api/Statistics/GetSmsReceivedCountPerDate`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Statistics
     * @name StatisticsGetSmsSentCountPerDateCreate
     * @request POST:/api/Statistics/GetSmsSentCountPerDate
     * @secure
     */
    statisticsGetSmsSentCountPerDateCreate: (
      query: {
        /** @format date-time */
        start: string;
        /** @format date-time */
        end: string;
      },
      data: string[],
      params: RequestParams = {},
    ) =>
      this.request<SmsStatisticsDateSeriesEntryViewModelClientResponse, any>({
        path: `/api/Statistics/GetSmsSentCountPerDate`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Statistics
     * @name StatisticsGetCustomerRevenueStatisticsCreate
     * @request POST:/api/Statistics/GetCustomerRevenueStatistics
     * @secure
     */
    statisticsGetCustomerRevenueStatisticsCreate: (
      query: {
        /** @format date-time */
        start: string;
        /** @format date-time */
        end: string;
      },
      data: string[],
      params: RequestParams = {},
    ) =>
      this.request<CustomerRevenueStatisticsViewModelClientResponse, any>({
        path: `/api/Statistics/GetCustomerRevenueStatistics`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Statistics
     * @name StatisticsGetCustomerRevenueStatisticsPerDateCreate
     * @request POST:/api/Statistics/GetCustomerRevenueStatisticsPerDate
     * @secure
     */
    statisticsGetCustomerRevenueStatisticsPerDateCreate: (
      query: {
        /** @format date-time */
        start: string;
        /** @format date-time */
        end: string;
      },
      data: string[],
      params: RequestParams = {},
    ) =>
      this.request<CustomerRevenueDateSeriesEntryViewModelClientResponse, any>({
        path: `/api/Statistics/GetCustomerRevenueStatisticsPerDate`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Statistics
     * @name StatisticsGetCustomerKmStatisticsCreate
     * @request POST:/api/Statistics/GetCustomerKMStatistics
     * @secure
     */
    statisticsGetCustomerKmStatisticsCreate: (
      query: {
        /** @format date-time */
        start: string;
        /** @format date-time */
        end: string;
      },
      data: string[],
      params: RequestParams = {},
    ) =>
      this.request<CustomerKMStatisticsViewModelClientResponse, any>({
        path: `/api/Statistics/GetCustomerKMStatistics`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Statistics
     * @name StatisticsGetCustomerKmStatisticsPerDateCreate
     * @request POST:/api/Statistics/GetCustomerKMStatisticsPerDate
     * @secure
     */
    statisticsGetCustomerKmStatisticsPerDateCreate: (
      query: {
        /** @format date-time */
        start: string;
        /** @format date-time */
        end: string;
      },
      data: string[],
      params: RequestParams = {},
    ) =>
      this.request<CustomerKMDateSeriesEntryViewModelClientResponse, any>({
        path: `/api/Statistics/GetCustomerKMStatisticsPerDate`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Statistics
     * @name StatisticsGetCustomerCo2StatisticsCreate
     * @request POST:/api/Statistics/GetCustomerCo2Statistics
     * @secure
     */
    statisticsGetCustomerCo2StatisticsCreate: (data: CustomerCo2StatisticsRequest, params: RequestParams = {}) =>
      this.request<CustomerCo2StatisticsViewModelClientResponse, any>({
        path: `/api/Statistics/GetCustomerCo2Statistics`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Statistics
     * @name StatisticsGetCustomerCo2StatisticsPerDateCreate
     * @request POST:/api/Statistics/GetCustomerCo2StatisticsPerDate
     * @secure
     */
    statisticsGetCustomerCo2StatisticsPerDateCreate: (data: CustomerCo2StatisticsRequest, params: RequestParams = {}) =>
      this.request<CustomerCo2DateSeriesEntryViewModelClientResponse, any>({
        path: `/api/Statistics/GetCustomerCo2StatisticsPerDate`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Statistics
     * @name StatisticsGetAggregatedCustomerCo2StatisticsCreate
     * @request POST:/api/Statistics/GetAggregatedCustomerCo2Statistics
     * @secure
     */
    statisticsGetAggregatedCustomerCo2StatisticsCreate: (
      data: CustomerCo2StatisticsRequest,
      params: RequestParams = {},
    ) =>
      this.request<CustomerCo2AggregatedStatisticsViewModelClientResponse, any>({
        path: `/api/Statistics/GetAggregatedCustomerCo2Statistics`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Statistics
     * @name StatisticsGetResourceKmStatisticsCreate
     * @request POST:/api/Statistics/GetResourceKMStatistics
     * @secure
     */
    statisticsGetResourceKmStatisticsCreate: (
      query: {
        /** @format date-time */
        start: string;
        /** @format date-time */
        end: string;
      },
      data: string[],
      params: RequestParams = {},
    ) =>
      this.request<ResourceKMStatisticsViewModelClientResponse, any>({
        path: `/api/Statistics/GetResourceKMStatistics`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Statistics
     * @name StatisticsGetResourceKmStatisticsPerDateCreate
     * @request POST:/api/Statistics/GetResourceKMStatisticsPerDate
     * @secure
     */
    statisticsGetResourceKmStatisticsPerDateCreate: (
      query: {
        /** @format date-time */
        start: string;
        /** @format date-time */
        end: string;
      },
      data: string[],
      params: RequestParams = {},
    ) =>
      this.request<ResourceKMDateSeriesEntryViewModelClientResponse, any>({
        path: `/api/Statistics/GetResourceKMStatisticsPerDate`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Storages
     * @name StoragesGetStoragesCreate
     * @request POST:/api/Storages/GetStorages
     * @secure
     */
    storagesGetStoragesCreate: (params: RequestParams = {}) =>
      this.request<BusinessEntityStoragesViewModelClientResponse, any>({
        path: `/api/Storages/GetStorages`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Storages
     * @name StoragesCreateStorageCreate
     * @request POST:/api/Storages/CreateStorage
     * @secure
     */
    storagesCreateStorageCreate: (data: CreateStorageRequest | UpdateStorageRequest, params: RequestParams = {}) =>
      this.request<BusinessEntityStorageViewModelClientResponse, any>({
        path: `/api/Storages/CreateStorage`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Storages
     * @name StoragesUpdateStorageCreate
     * @request POST:/api/Storages/UpdateStorage
     * @secure
     */
    storagesUpdateStorageCreate: (data: UpdateStorageRequest, params: RequestParams = {}) =>
      this.request<BusinessEntityStorageViewModelClientResponse, any>({
        path: `/api/Storages/UpdateStorage`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Storages
     * @name StoragesRemoveStorageCreate
     * @request POST:/api/Storages/RemoveStorage
     * @secure
     */
    storagesRemoveStorageCreate: (
      query: {
        /** @format uuid */
        storageId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<BusinessEntityStorageViewModelClientResponse, any>({
        path: `/api/Storages/RemoveStorage`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Task
     * @name TaskGetTasksForDurationList
     * @request GET:/api/Task/GetTasksForDuration
     * @secure
     */
    taskGetTasksForDurationList: (
      query: {
        /** @format date-time */
        startDate: string;
        /** @format date-time */
        endDate: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<TasksViewModelClientResponse, any>({
        path: `/api/Task/GetTasksForDuration`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Task
     * @name TaskGetUnscheduledTasksForDurationList
     * @request GET:/api/Task/GetUnscheduledTasksForDuration
     * @secure
     */
    taskGetUnscheduledTasksForDurationList: (
      query: {
        /** @format date-time */
        startDate: string;
        /** @format date-time */
        endDate: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<TasksViewModelClientResponse, any>({
        path: `/api/Task/GetUnscheduledTasksForDuration`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Task
     * @name TaskGetTasksForScheduleList
     * @request GET:/api/Task/GetTasksForSchedule
     * @secure
     */
    taskGetTasksForScheduleList: (
      query: {
        /** @format uuid */
        scheduleEntryId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<TasksViewModelClientResponse, any>({
        path: `/api/Task/GetTasksForSchedule`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Task
     * @name TaskGetTasksForSchedulesCreate
     * @request POST:/api/Task/GetTasksForSchedules
     * @secure
     */
    taskGetTasksForSchedulesCreate: (data: TasksForSchedulesRequest, params: RequestParams = {}) =>
      this.request<TasksViewModelClientResponse, any>({
        path: `/api/Task/GetTasksForSchedules`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Task
     * @name TaskSearchTasksCreate
     * @request POST:/api/Task/SearchTasks
     * @secure
     */
    taskSearchTasksCreate: (data: SearchTaskRequest | ExportTasksToCSVRequest, params: RequestParams = {}) =>
      this.request<PagedTasksViewModelClientResponse, any>({
        path: `/api/Task/SearchTasks`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Task
     * @name TaskGetTaskByIdList
     * @request GET:/api/Task/GetTaskById
     * @secure
     */
    taskGetTaskByIdList: (
      query: {
        /** @format uuid */
        id: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<TaskViewModelClientResponse, any>({
        path: `/api/Task/GetTaskById`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Task
     * @name TaskGetTasksByIdCreate
     * @request POST:/api/Task/GetTasksById
     * @secure
     */
    taskGetTasksByIdCreate: (data: TasksByIdRequest, params: RequestParams = {}) =>
      this.request<TasksViewModelClientResponse, any>({
        path: `/api/Task/GetTasksById`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Task
     * @name TaskGetUnassignedTasksCountList
     * @request GET:/api/Task/GetUnassignedTasksCount
     * @secure
     */
    taskGetUnassignedTasksCountList: (params: RequestParams = {}) =>
      this.request<Int32ClientResponse, any>({
        path: `/api/Task/GetUnassignedTasksCount`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Task
     * @name TaskGetUnassignedTasksCreate
     * @request POST:/api/Task/GetUnassignedTasks
     * @secure
     */
    taskGetUnassignedTasksCreate: (data: UnassignedTasksRequest, params: RequestParams = {}) =>
      this.request<TasksViewModelClientResponse, any>({
        path: `/api/Task/GetUnassignedTasks`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Task
     * @name TaskCreateTaskCreate
     * @request POST:/api/Task/CreateTask
     * @secure
     */
    taskCreateTaskCreate: (data: CreateTaskRequest, params: RequestParams = {}) =>
      this.request<TaskViewModelClientResponse, any>({
        path: `/api/Task/CreateTask`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Task
     * @name TaskCreateTasksCreate
     * @request POST:/api/Task/CreateTasks
     * @secure
     */
    taskCreateTasksCreate: (data: CreateTasksRequest, params: RequestParams = {}) =>
      this.request<TasksViewModelClientResponse, any>({
        path: `/api/Task/CreateTasks`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Task
     * @name TaskUpdateTaskCreate
     * @request POST:/api/Task/UpdateTask
     * @secure
     */
    taskUpdateTaskCreate: (data: UpdateTaskRequest, params: RequestParams = {}) =>
      this.request<TaskViewModelClientResponse, any>({
        path: `/api/Task/UpdateTask`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Task
     * @name TaskUpdateTaskStepCreate
     * @request POST:/api/Task/UpdateTaskStep
     * @secure
     */
    taskUpdateTaskStepCreate: (data: UpdateTaskStepRequest, params: RequestParams = {}) =>
      this.request<TaskViewModelClientResponse, any>({
        path: `/api/Task/UpdateTaskStep`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Task
     * @name TaskUpdateTaskDeliveryInformationCreate
     * @request POST:/api/Task/UpdateTaskDeliveryInformation
     * @secure
     */
    taskUpdateTaskDeliveryInformationCreate: (data: UpdateTaskDeliveryInformationRequest, params: RequestParams = {}) =>
      this.request<TaskViewModelClientResponse, any>({
        path: `/api/Task/UpdateTaskDeliveryInformation`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Task
     * @name TaskDeleteTaskCreate
     * @request POST:/api/Task/DeleteTask
     * @secure
     */
    taskDeleteTaskCreate: (
      query: {
        /** @format uuid */
        taskId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<TaskViewModelClientResponse, any>({
        path: `/api/Task/DeleteTask`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Task
     * @name TaskCompleteTaskCreate
     * @request POST:/api/Task/CompleteTask
     * @secure
     */
    taskCompleteTaskCreate: (
      query: {
        /** @format uuid */
        taskId: string;
        force?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<TaskViewModelClientResponse, any>({
        path: `/api/Task/CompleteTask`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Task
     * @name TaskCompleteTasksCreate
     * @request POST:/api/Task/CompleteTasks
     * @secure
     */
    taskCompleteTasksCreate: (data: TasksByIdRequest, params: RequestParams = {}) =>
      this.request<TasksViewModelClientResponse, any>({
        path: `/api/Task/CompleteTasks`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Task
     * @name TaskCompleteTaskStepCreate
     * @request POST:/api/Task/CompleteTaskStep
     * @secure
     */
    taskCompleteTaskStepCreate: (
      query: {
        /** @format uuid */
        taskId: string;
        stepType: DeliveryStepType;
      },
      params: RequestParams = {},
    ) =>
      this.request<TaskViewModelClientResponse, any>({
        path: `/api/Task/CompleteTaskStep`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Task
     * @name TaskAssignTaskToCreate
     * @request POST:/api/Task/AssignTaskTo
     * @secure
     */
    taskAssignTaskToCreate: (data: AssignDeliveryTaskRequest, params: RequestParams = {}) =>
      this.request<TaskViewModelClientResponse, any>({
        path: `/api/Task/AssignTaskTo`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Task
     * @name TaskAssignTaskPickupToCreate
     * @request POST:/api/Task/AssignTaskPickupTo
     * @secure
     */
    taskAssignTaskPickupToCreate: (data: AssignDeliveryStepRequest, params: RequestParams = {}) =>
      this.request<TaskViewModelClientResponse, any>({
        path: `/api/Task/AssignTaskPickupTo`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Task
     * @name TaskAssignTaskDeliveryToCreate
     * @request POST:/api/Task/AssignTaskDeliveryTo
     * @secure
     */
    taskAssignTaskDeliveryToCreate: (data: AssignDeliveryStepRequest, params: RequestParams = {}) =>
      this.request<TaskViewModelClientResponse, any>({
        path: `/api/Task/AssignTaskDeliveryTo`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Task
     * @name TaskOrderAssignedTaskCreate
     * @request POST:/api/Task/OrderAssignedTask
     * @secure
     */
    taskOrderAssignedTaskCreate: (data: OrderAssignedTaskRequest, params: RequestParams = {}) =>
      this.request<DeliveryTaskStepOrderArrayClientResponse, any>({
        path: `/api/Task/OrderAssignedTask`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Task
     * @name TaskOrderAssignedTaskStepsCreate
     * @request POST:/api/Task/OrderAssignedTaskSteps
     * @secure
     */
    taskOrderAssignedTaskStepsCreate: (data: OrderAssignedTaskStepsRequest, params: RequestParams = {}) =>
      this.request<DeliveryTaskStepOrderArrayClientResponse, any>({
        path: `/api/Task/OrderAssignedTaskSteps`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Task
     * @name TaskScheduleOrderCreate
     * @request POST:/api/Task/ScheduleOrder
     * @secure
     */
    taskScheduleOrderCreate: (data: ScheduleOrderRequest, params: RequestParams = {}) =>
      this.request<DeliveryTaskStepOrderArrayClientResponse, any>({
        path: `/api/Task/ScheduleOrder`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Task
     * @name TaskCanScheduleBeOptimizedList
     * @request GET:/api/Task/CanScheduleBeOptimized
     * @secure
     */
    taskCanScheduleBeOptimizedList: (
      query: {
        /** @format uuid */
        scheduleEntryId: string;
        /** @format date-time */
        date: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<BooleanClientResponse, any>({
        path: `/api/Task/CanScheduleBeOptimized`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Task
     * @name TaskCanSchedulesBeOptimizedCreate
     * @request POST:/api/Task/CanSchedulesBeOptimized
     * @secure
     */
    taskCanSchedulesBeOptimizedCreate: (data: SchedulesCanBeOptimizedRequest, params: RequestParams = {}) =>
      this.request<GuidBooleanDictionaryClientResponse, any>({
        path: `/api/Task/CanSchedulesBeOptimized`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Task
     * @name TaskMarkTaskStepsAsNotifiedCreate
     * @request POST:/api/Task/MarkTaskStepsAsNotified
     * @secure
     */
    taskMarkTaskStepsAsNotifiedCreate: (data: MarkTaskStepsAsNotifiedRequest, params: RequestParams = {}) =>
      this.request<TasksViewModelClientResponse, any>({
        path: `/api/Task/MarkTaskStepsAsNotified`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Task
     * @name TaskUpdateDriverVisibilityCreate
     * @request POST:/api/Task/UpdateDriverVisibility
     * @secure
     */
    taskUpdateDriverVisibilityCreate: (data: DeliveryTaskUpdateDriverVisibilityRequest, params: RequestParams = {}) =>
      this.request<TasksViewModelClientResponse, any>({
        path: `/api/Task/UpdateDriverVisibility`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Task
     * @name TaskToggleStoragePlacementForPickupCreate
     * @request POST:/api/Task/ToggleStoragePlacementForPickup
     * @secure
     */
    taskToggleStoragePlacementForPickupCreate: (
      query: {
        /** @format uuid */
        taskId: string;
        location: StorageLocation;
        /** @format uuid */
        selectedStorageId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<TaskViewModelClientResponse, any>({
        path: `/api/Task/ToggleStoragePlacementForPickup`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Task
     * @name TaskReturnTaskToInProgressCreate
     * @request POST:/api/Task/ReturnTaskToInProgress
     * @secure
     */
    taskReturnTaskToInProgressCreate: (data: TaskProgressRequest, params: RequestParams = {}) =>
      this.request<TaskViewModelClientResponse, any>({
        path: `/api/Task/ReturnTaskToInProgress`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Task
     * @name TaskPlaceDeliveryInStorageCreate
     * @request POST:/api/Task/PlaceDeliveryInStorage
     * @secure
     */
    taskPlaceDeliveryInStorageCreate: (
      query: {
        /** @format uuid */
        taskId: string;
        /** @format uuid */
        storageId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<TaskViewModelClientResponse, any>({
        path: `/api/Task/PlaceDeliveryInStorage`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Task
     * @name TaskGetTasksInStorageList
     * @request GET:/api/Task/GetTasksInStorage
     * @secure
     */
    taskGetTasksInStorageList: (params: RequestParams = {}) =>
      this.request<TasksViewModelClientResponse, any>({
        path: `/api/Task/GetTasksInStorage`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Task
     * @name TaskGetTasksInStorageCountList
     * @request GET:/api/Task/GetTasksInStorageCount
     * @secure
     */
    taskGetTasksInStorageCountList: (params: RequestParams = {}) =>
      this.request<Int32ClientResponse, any>({
        path: `/api/Task/GetTasksInStorageCount`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Task
     * @name TaskDownloadShipmentNoticePdfCreate
     * @request POST:/api/Task/DownloadShipmentNoticePdf
     * @secure
     */
    taskDownloadShipmentNoticePdfCreate: (
      query: {
        /** @format uuid */
        taskId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/Task/DownloadShipmentNoticePdf`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Task
     * @name TaskDownloadTaskScheduleOverviewPdfCreate
     * @request POST:/api/Task/DownloadTaskScheduleOverviewPdf
     * @secure
     */
    taskDownloadTaskScheduleOverviewPdfCreate: (
      query: {
        /** @format uuid */
        userId: string;
        /** @format date-time */
        scheduleFrom: string;
        /** @format date-time */
        scheduleTo: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/Task/DownloadTaskScheduleOverviewPdf`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Task
     * @name TaskSendShipmentNoticePdfViaWhatsAppForTasksCreate
     * @request POST:/api/Task/SendShipmentNoticePdfViaWhatsAppForTasks
     * @secure
     */
    taskSendShipmentNoticePdfViaWhatsAppForTasksCreate: (
      data: SendTaskScheduleOverviewPdfViaWhatsAppForTasksRequest,
      params: RequestParams = {},
    ) =>
      this.request<BooleanClientResponse, any>({
        path: `/api/Task/SendShipmentNoticePdfViaWhatsAppForTasks`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Task
     * @name TaskExportTasksToCsvCreate
     * @request POST:/api/Task/ExportTasksToCSV
     * @secure
     */
    taskExportTasksToCsvCreate: (data: ExportTasksToCSVRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/Task/ExportTasksToCSV`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Task
     * @name TaskGetTaskCountForIntervalList
     * @request GET:/api/Task/GetTaskCountForInterval
     * @secure
     */
    taskGetTaskCountForIntervalList: (
      query: {
        /** @format date-time */
        start: string;
        /** @format date-time */
        end: string;
        /** @format uuid */
        userId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<TaskStatisticsDateEntryViewObjectArrayClientResponse, any>({
        path: `/api/Task/GetTaskCountForInterval`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Task
     * @name TaskGetActiveTaskScheduleList
     * @request GET:/api/Task/GetActiveTaskSchedule
     * @secure
     */
    taskGetActiveTaskScheduleList: (
      query: {
        /** @format uuid */
        userId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<TasksViewModelClientResponse, any>({
        path: `/api/Task/GetActiveTaskSchedule`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Task
     * @name TaskGetActiveScheduledTaskList
     * @request GET:/api/Task/GetActiveScheduledTask
     * @secure
     */
    taskGetActiveScheduledTaskList: (
      query: {
        /** @format uuid */
        userId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ActiveTaskViewModelClientResponse, any>({
        path: `/api/Task/GetActiveScheduledTask`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Task
     * @name TaskGetActiveScheduledTasksCreate
     * @request POST:/api/Task/GetActiveScheduledTasks
     * @secure
     */
    taskGetActiveScheduledTasksCreate: (data: ActiveScheduledTasksRequest, params: RequestParams = {}) =>
      this.request<ActiveTasksViewModelClientResponse, any>({
        path: `/api/Task/GetActiveScheduledTasks`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Task
     * @name TaskGetTasksForDriverLogsCreate
     * @request POST:/api/Task/GetTasksForDriverLogs
     * @secure
     */
    taskGetTasksForDriverLogsCreate: (data: TasksForDriverLogRequest, params: RequestParams = {}) =>
      this.request<TasksViewModelClientResponse, any>({
        path: `/api/Task/GetTasksForDriverLogs`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TaskScheduler
     * @name TaskSchedulerGetScheduledTaskList
     * @request GET:/api/TaskScheduler/GetScheduledTask
     * @secure
     */
    taskSchedulerGetScheduledTaskList: (
      query: {
        /** @format uuid */
        scheduledTaskId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ScheduledTaskViewObjectClientResponse, any>({
        path: `/api/TaskScheduler/GetScheduledTask`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TaskScheduler
     * @name TaskSchedulerGetScheduledTasksCreate
     * @request POST:/api/TaskScheduler/GetScheduledTasks
     * @secure
     */
    taskSchedulerGetScheduledTasksCreate: (data: ScheduledTasksRequest, params: RequestParams = {}) =>
      this.request<ScheduledTaskViewModelClientResponse, any>({
        path: `/api/TaskScheduler/GetScheduledTasks`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TaskScheduler
     * @name TaskSchedulerAddOrUpdateScheduledTaskCreate
     * @request POST:/api/TaskScheduler/AddOrUpdateScheduledTask
     * @secure
     */
    taskSchedulerAddOrUpdateScheduledTaskCreate: (data: AddOrUpdateScheduledTaskRequest, params: RequestParams = {}) =>
      this.request<ScheduledTaskViewObjectClientResponse, any>({
        path: `/api/TaskScheduler/AddOrUpdateScheduledTask`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TaskScheduler
     * @name TaskSchedulerRecreateAllFutureScheduledTasksCreate
     * @request POST:/api/TaskScheduler/RecreateAllFutureScheduledTasks
     * @secure
     */
    taskSchedulerRecreateAllFutureScheduledTasksCreate: (params: RequestParams = {}) =>
      this.request<BooleanClientResponse, any>({
        path: `/api/TaskScheduler/RecreateAllFutureScheduledTasks`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TaskScheduler
     * @name TaskSchedulerRecreateFutureScheduledTasksCreate
     * @request POST:/api/TaskScheduler/RecreateFutureScheduledTasks
     * @secure
     */
    taskSchedulerRecreateFutureScheduledTasksCreate: (
      query: {
        /** @format uuid */
        id: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ScheduledTaskViewObjectClientResponse, any>({
        path: `/api/TaskScheduler/RecreateFutureScheduledTasks`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TaskScheduler
     * @name TaskSchedulerForceRunScheduledTaskCreate
     * @request POST:/api/TaskScheduler/ForceRunScheduledTask
     * @secure
     */
    taskSchedulerForceRunScheduledTaskCreate: (
      query: {
        /** @format uuid */
        id: string;
        deleteFutureScheduledTasks: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<ScheduledTaskViewObjectClientResponse, any>({
        path: `/api/TaskScheduler/ForceRunScheduledTask`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TaskScheduler
     * @name TaskSchedulerDeleteScheduledTaskDelete
     * @request DELETE:/api/TaskScheduler/DeleteScheduledTask
     * @secure
     */
    taskSchedulerDeleteScheduledTaskDelete: (
      query: {
        /** @format uuid */
        id: string;
        deleteFutureScheduledTasks: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<ScheduledTaskViewObjectClientResponse, any>({
        path: `/api/TaskScheduler/DeleteScheduledTask`,
        method: "DELETE",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TaskScheduler
     * @name TaskSchedulerGetScheduledTaskRunsList
     * @request GET:/api/TaskScheduler/GetScheduledTaskRuns
     * @secure
     */
    taskSchedulerGetScheduledTaskRunsList: (
      query: {
        /** @format uuid */
        scheduledTaskId?: string;
        /** @format int32 */
        take: number;
        /** @format int32 */
        skip: number;
        /** @format date-time */
        from?: string;
        /** @format date-time */
        to?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ScheduledTaskRunViewModelClientResponse, any>({
        path: `/api/TaskScheduler/GetScheduledTaskRuns`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersGetUserList
     * @request GET:/api/Users/GetUser
     * @secure
     */
    usersGetUserList: (
      query: {
        /** @format uuid */
        userId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<BusinessEntityUserViewModelClientResponse, any>({
        path: `/api/Users/GetUser`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersGetUsersCreate
     * @request POST:/api/Users/GetUsers
     * @secure
     */
    usersGetUsersCreate: (data: UsersRequest, params: RequestParams = {}) =>
      this.request<BusinessEntityUsersViewModelClientResponse, any>({
        path: `/api/Users/GetUsers`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersGetDriverConnectionsList
     * @request GET:/api/Users/GetDriverConnections
     * @secure
     */
    usersGetDriverConnectionsList: (
      query: {
        /** @format uuid */
        userId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<BusinessEntityDeliveryHubUserViewObjectNullableClientResponse, any>({
        path: `/api/Users/GetDriverConnections`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersGetActiveDriverOverviewList
     * @request GET:/api/Users/GetActiveDriverOverview
     * @secure
     */
    usersGetActiveDriverOverviewList: (params: RequestParams = {}) =>
      this.request<ActiveDriversOverviewViewModelClientResponse, any>({
        path: `/api/Users/GetActiveDriverOverview`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersCreateUserCreate
     * @request POST:/api/Users/CreateUser
     * @secure
     */
    usersCreateUserCreate: (data: CreateUserRequest | UpdateUserRequest, params: RequestParams = {}) =>
      this.request<BusinessEntityUserViewModelClientResponse, any>({
        path: `/api/Users/CreateUser`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersUpdateUserCreate
     * @request POST:/api/Users/UpdateUser
     * @secure
     */
    usersUpdateUserCreate: (data: UpdateUserRequest, params: RequestParams = {}) =>
      this.request<BusinessEntityUserViewModelClientResponse, any>({
        path: `/api/Users/UpdateUser`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersUpdateSelfCreate
     * @request POST:/api/Users/UpdateSelf
     * @secure
     */
    usersUpdateSelfCreate: (data: UpdateUserRequest, params: RequestParams = {}) =>
      this.request<BusinessEntityUserViewModelClientResponse, any>({
        path: `/api/Users/UpdateSelf`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersChangeProfileImageUpdate
     * @request PUT:/api/Users/ChangeProfileImage
     * @secure
     */
    usersChangeProfileImageUpdate: (
      data: {
        /** @format binary */
        formFile: File;
      },
      params: RequestParams = {},
    ) =>
      this.request<BusinessEntityUserViewModelClientResponse, any>({
        path: `/api/Users/ChangeProfileImage`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersChangeUserProfileImageUpdate
     * @request PUT:/api/Users/ChangeUserProfileImage
     * @secure
     */
    usersChangeUserProfileImageUpdate: (
      query: {
        /** @format uuid */
        userId: string;
      },
      data: {
        /** @format binary */
        formFile: File;
      },
      params: RequestParams = {},
    ) =>
      this.request<BusinessEntityUserViewModelClientResponse, any>({
        path: `/api/Users/ChangeUserProfileImage`,
        method: "PUT",
        query: query,
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersDeleteUserCreate
     * @request POST:/api/Users/DeleteUser
     * @secure
     */
    usersDeleteUserCreate: (
      query: {
        /** @format uuid */
        userId: string;
        hardDelete?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<BusinessEntityUserViewModelClientResponse, any>({
        path: `/api/Users/DeleteUser`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersRestoreUserCreate
     * @request POST:/api/Users/RestoreUser
     * @secure
     */
    usersRestoreUserCreate: (
      query: {
        /** @format uuid */
        userId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<BusinessEntityUserViewModelClientResponse, any>({
        path: `/api/Users/RestoreUser`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersBlockUserCreate
     * @request POST:/api/Users/BlockUser
     * @secure
     */
    usersBlockUserCreate: (
      query: {
        /** @format uuid */
        userId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<BusinessEntityUserViewModelClientResponse, any>({
        path: `/api/Users/BlockUser`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersUnblockUserCreate
     * @request POST:/api/Users/UnblockUser
     * @secure
     */
    usersUnblockUserCreate: (
      query: {
        /** @format uuid */
        userId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<BusinessEntityUserViewModelClientResponse, any>({
        path: `/api/Users/UnblockUser`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersUpdateAllowableIpsForUserCreate
     * @request POST:/api/Users/UpdateAllowableIpsForUser
     * @secure
     */
    usersUpdateAllowableIpsForUserCreate: (data: AddOrUpdateAllowableIpsRequest, params: RequestParams = {}) =>
      this.request<BusinessEntityUserViewModelClientResponse, any>({
        path: `/api/Users/UpdateAllowableIpsForUser`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersResetPasswordForUserCreate
     * @request POST:/api/Users/ResetPasswordForUser
     * @secure
     */
    usersResetPasswordForUserCreate: (
      query: {
        /** @format uuid */
        userId: string;
        password?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<BusinessEntityUserViewModelClientResponse, any>({
        path: `/api/Users/ResetPasswordForUser`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersResendWelcomeMailCreate
     * @request POST:/api/Users/ResendWelcomeMail
     * @secure
     */
    usersResendWelcomeMailCreate: (
      query: {
        /** @format uuid */
        userId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<BusinessEntityUserViewModelClientResponse, any>({
        path: `/api/Users/ResendWelcomeMail`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersGetUserLoginHistoryCreate
     * @request POST:/api/Users/GetUserLoginHistory
     * @secure
     */
    usersGetUserLoginHistoryCreate: (
      query: {
        /** @format uuid */
        userId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<BusinessEntityUserLoginHistoryViewModelClientResponse, any>({
        path: `/api/Users/GetUserLoginHistory`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersGetUserInformationMessageLogsList
     * @request GET:/api/Users/GetUserInformationMessageLogs
     * @secure
     */
    usersGetUserInformationMessageLogsList: (
      query: {
        /** @format date-time */
        from: string;
        /** @format date-time */
        to: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<UserInformationMessageLogViewModelClientResponse, any>({
        path: `/api/Users/GetUserInformationMessageLogs`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersAddOrUpdateUserInformationMessageLogCreate
     * @request POST:/api/Users/AddOrUpdateUserInformationMessageLog
     * @secure
     */
    usersAddOrUpdateUserInformationMessageLogCreate: (
      data: AddOrUpdateUserInformationMessageLogRequest,
      params: RequestParams = {},
    ) =>
      this.request<UserInformationMessageLogViewObjectClientResponse, any>({
        path: `/api/Users/AddOrUpdateUserInformationMessageLog`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersDeleteUserInformationMessageLogDelete
     * @request DELETE:/api/Users/DeleteUserInformationMessageLog
     * @secure
     */
    usersDeleteUserInformationMessageLogDelete: (
      query?: {
        /** @format int32 */
        logId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<UserInformationMessageLogViewObjectClientResponse, any>({
        path: `/api/Users/DeleteUserInformationMessageLog`,
        method: "DELETE",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersGetSmsHistoryList
     * @request GET:/api/Users/GetSmsHistory
     * @secure
     */
    usersGetSmsHistoryList: (
      query: {
        /** @format uuid */
        userId: string;
        /** @format date-time */
        from?: string;
        /** @format date-time */
        to?: string;
        /** @format int32 */
        take: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<UserSmsHistoryEntryViewObjectArrayClientResponse, any>({
        path: `/api/Users/GetSmsHistory`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersGetDriverLogsCreate
     * @request POST:/api/Users/GetDriverLogs
     * @secure
     */
    usersGetDriverLogsCreate: (data: DriverLogsRequest, params: RequestParams = {}) =>
      this.request<BusinessEntityUserDriverLogsViewModelClientResponse, any>({
        path: `/api/Users/GetDriverLogs`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersGetDriverLogsForResourceList
     * @request GET:/api/Users/GetDriverLogsForResource
     * @secure
     */
    usersGetDriverLogsForResourceList: (
      query: {
        /** @format uuid */
        userId: string;
        /** @format date-time */
        fromDate: string;
        /** @format date-time */
        toDate: string;
        /** @format int32 */
        skip: number;
        /** @format int32 */
        take: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<BusinessEntityUserDriverLogsViewModelClientResponse, any>({
        path: `/api/Users/GetDriverLogsForResource`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
}
